import React from 'react';

import { ContentfulContext } from '../classes/context'
import RegistrationIntroduction from './RegistrationIntroduction';

export default function Convention(props) {

  return (

    < main id="main-content" >
      <section className="usa-section" style={{ paddingTop: 10 }}>
        <div className="grid-container margin-y-0">
          <h2 className="margin-y-0">Convention 2024</h2>
          <RegistrationIntroduction registrationStatus={props.registrationStatus.data} />

        </div>
      </section>
    </main >
  )

}

Convention.contextType = ContentfulContext
