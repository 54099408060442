import { API } from 'aws-amplify'
import { useLocation } from "react-router-dom"

import React from 'react';
import Item from './Item'
import Panel from './Panel'
import Table from './Table'
import TableHeadings from './TableHeadings'
import TableBody from './TableBody'
import Column from './fields/Column'

// Must match the list in useCollection
const requestTypeMapping = {
  a: 'membership',
  b: 'company:official',
  c: 'chapter',
  d: 'chapter:official',
  e: 'awardType',
  f: 'form',
  g: 'receipt',
  h: 'unassigned',
  i: 'company',
  j: 'awardType',
  k: 'receiptLineItem',
  l: 'unassigned',
  m: 'member',
  n: 'membershipType',
  o: 'unassigned',
  p: 'award',
  q: 'eeg',
  r: 'registration',
  s: 'coupon',
  t: 'coupon:registration',
  u: 'user',
  v: 'registrationType',
  w: 'unassigned',
  x: 'unassigned',
  y: 'convention',
  z: 'picklist'
}

export default function Collection(props) {

  const { collection, form, setStatus } = props
  const location = useLocation()

  if (!collection) { return null }
  const segments = collection.segments

  if (!segments || segments.length === 0) { return null }

  if (segments[0].itemCount === 0) {
    return (<div>There is no matching item</div>)
  }

  const primaryForm = itemForm(segments[0].items[0], form)

  if (!primaryForm) {
    return <div>
      <div className="usa-alert usa-alert--error" style={{ marginTop: 100 }}>
        <div className="usa-alert__body" style={{ width: 1000 }}>
          <h4 className="usa-alert__heading">Something Unexpected Happened</h4>
          <p className="usa-alert__text" name="no-form">There is no primary form defined for {segments[0].items[0].pk.slice(0, 1)} and {segments[0].items[0].sk.slice(0, 1)}.</p>
        </div>
      </div>

    </div>
  }

  async function deleteItem(source, pk) {
    API.del('list', source, { body: { pk: pk, sk: pk } })
      .then(() => {
        setStatus('RELOAD') // reload the data
      })
      .catch(error => {
        console.log(error)
      })
  }

  function isAdmin() {
    return location.pathname.includes('admin/')
  }


  return (
    <div>
      <PrimaryItems
        items={segments[0].items}
        primaryForm={primaryForm}
        {...props}
      />

      {primaryForm.childViews.map((view, i) => {
        let secondaryForm = props.form.items.filter(f => f.pk === view.pk && f.sk === view.sk)[0]

        let secondaryItem = false
        for (let segment of segments.slice(1)) {
          if (segment.itemCount > 0 && segment.items.length > 0 && (segment.items[0].gsi3_sk === view.itemType || segment.items[0].gsi1_pk === view.itemType)) {
            secondaryItem = segment
          }
        }
        if (!secondaryItem) { return null }

        if (view.title === 'Company POC') { secondaryItem = segments[2] }
        if (view.title === 'Memberships') { secondaryItem = segments[3] }
        let viewId = isAdmin() ? (view.adminViewId ?? view.viewId) : view.viewId
        let secondaryView = secondaryForm.views.filter(f => f.id === viewId)[0]

        let sortedItems = secondaryItem
        if (secondaryView.sortBy) {
          let col = secondaryForm.fields.findIndex(field => field.fieldId === secondaryView.sortBy)
          let order = secondaryView.sortByOrder === 'desc' ? -1 : 1
          sortedItems.items = secondaryItem.items.sort((a, b) => {
            if (a[secondaryForm.fields[col].key] > b[secondaryForm.fields[col].key]) { return (Number(order)) }
            if (a[secondaryForm.fields[col].key] < b[secondaryForm.fields[col].key]) { return (-1 * order) }
            return 0
          })
        }

        return (
          <Panel key={i} title={view.title}>
            <Table>
              <TableHeadings>
                <tr>
                  {secondaryView.allowDelete && <td style={{ width: 50 }}></td>}
                  {secondaryView.fieldIds.map((fieldId, i) => {
                    let col = secondaryForm.fields.findIndex(field => field.fieldId === fieldId)
                    return <td key={i}>{secondaryForm.fields[col].label}</td>
                  }
                  )}
                </tr>
              </TableHeadings>
              <TableBody>
                {sortedItems.items.map((item, j) =>
                  <tr key={j}>
                    {secondaryView.allowDelete && <td style={{ backgroundColor: 'inherit', borderWidth: 1, borderColor: 'white', width: 50 }}><i className="far fa-trash usa-button--unstyled" onClick={() => deleteItem(secondaryView.source, item.pk)}></i></td>}
                    {secondaryView.fieldIds.map((fieldId, i) => {
                      let col = secondaryForm.fields.findIndex(field => field.fieldId === fieldId)
                      return <Column key={i} item={item} saveSuccessful={collection.saveSuccessful} column={secondaryForm.fields[col]} />
                    }
                    )}
                  </tr>
                )}
              </TableBody>
            </Table>
          </Panel>
        )
      })}
    </div>
  )
}

function itemForm(item, form) {

  const location = useLocation()

  let itemType = requestTypeMapping[item.pk.slice(0, 1)]
  if (item.pk.indexOf('@') !== -1) { itemType = location.pathname.split('/')[1] === 'admin' ? 'user' : 'profile' }
  if (item.pk.slice(0, 1) === 'r' && location.pathname.includes('banquet-seating')) {
    itemType = 'banquet-seating'
  }

  if (item.pk.slice(0, 1) === 'r' && location.pathname.includes('badge')) {
    itemType = 'badge'
  }


  let subItemType = requestTypeMapping[item.sk.slice(0, 1)]
  if (item.pk.indexOf('@') !== -1) { subItemType = location.pathname.split('/')[1] === 'admin' ? 'user' : 'profile' }

  const locationPath = location.pathname.split('/')[2]
  console.log('targetForm values', itemType, subItemType, locationPath)
  let targetForm = form.items.filter(f =>
    f.pkType === itemType && f.skType === subItemType &&
    f.route.split('/')[1] === locationPath
  )[0]

  return targetForm
}

function PrimaryItems(props) {

  // We are using the form parameter to mean something different when we call
  // Item, so remove the prop definition before calling

  // eslint-disable-next-line
  const { form, ...noFormProps } = props

  return (
    <>
      {props.collection.segments[0].items.map((singleItem, i) => {

        return (
          <Item
            key={i}
            index={i}
            isList={false}
            collection={props.collection}
            status={props.status}
            previousStatus={props.previousStatus}
            setStatus={props.setStatus}
            item={singleItem}
            form={itemForm(singleItem, props.form)}
            primaryForm={props.primaryForm}
            registrationAction={props.registrationAction}
            refreshMembershipStatus={props.refreshMembershipStatus}
            alignMembershipEndDates={props.alignMembershipEndDates}
            proposedMembershipStatus={props.proposedMembershipStatus}
            saveProposedMembershipStatus={props.saveProposedMembershipStatus}
            saveSuccessful={props.saveSuccessful}
            user={props.user}
            {...noFormProps}
          />
        )
      })}
    </>
  )
}
