import React from 'react';
import { createRoot } from 'react-dom/client';
//import "./css/bootstrap.min.css";
//import "./css/theme_1578076528612.css";
import './index.css';
import { CookiesProvider } from "react-cookie"

import App from './App';
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<CookiesProvider><App /></CookiesProvider>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
