import React from 'react'

import Panel from "../Panel"

export default function SearchTerm(props) {

  return (
    <Panel title="Term" style={{ width: '95%' }}>
      <p>Searching for: {props.term}</p>
    </Panel>
  )
}
