import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from "react-router-dom"

export default function Sidebar(props) {

  const [groupAdmin, setGroupAdmin] = useState(false)
  const [admin, setAdmin] = useState(false)
  let location = useLocation()

  useEffect(() => {
    if (props.user && props.user.signInUserSession.accessToken.payload["cognito:groups"]) {
      setGroupAdmin(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('groupAdmin'))
      setAdmin(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('admins'))
    }
  }, [props.user])


  function isCurrent(route) {
    return ("/admin" + route === location.pathname) ? 'usa-current' : ''
  }

  return (
    <div style={{ marginTop: 20 }}>
      <nav>
        <ul className="usa-sidenav">
          <li key="999" className="usa-sidenav__item">
            General
            <div key="998" className="usa-sidenav__sublist">
              <NavLink
                className={isCurrent("")}
                to={"/account"}
              >
                Home
              </NavLink>
              <NavLink
                className={isCurrent("/account/profile")}
                to={"/account/profile"}
              >
                My Profile
              </NavLink>
              {groupAdmin &&
                <NavLink
                  className={isCurrent("/profile")}
                  to={"/account/groups"}
                >
                  Group Registrations
                </NavLink>
              }
              {admin &&
                <NavLink
                  className={isCurrent("/profile")}
                  to={"/admin"}
                >
                  Full Admin
                </NavLink>
              }
            </div>

          </li>
        </ul>
      </nav>
    </div>
  )
}
