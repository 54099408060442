import React, { useState, useEffect } from 'react'
import useQuery from '../useQuery'

export default function FieldString(props) {

  const query = useQuery()
  let [value, setValue] = useState(initialValue(props.initialValue))
  let [isValid, setValid] = useState(false)
  let [isGridEditing, setIsGridEditing] = useState(false)
  let [originalValue, setOriginalValue] = useState(props.initialValue)

  const { item, column,
    status,
    updateField, updatedAt,
    setAdminRequestedTable, setAssignedGroup, setAdminValue,
    isColumn,
    editStyle, viewStyle, helpStyle } = props

  function initialValue(initialValue) {
    if (props.column.key === 'firstName' || props.column.key === 'lastName') {
      if (!initialValue) {
        return query.get(props.column.key) ?? ''
      }
    }
    return initialValue
  }

  function isView() {
    return status === 'LOADED' || !column.userEditable || isColumn
  }

  function isHidden() {
    if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
    // visibleForRegistrationTypes is included in the field definition
    // so always keep it hidden until the registration type is selected.
    if (!item.registrationType.pk) { return true }

    if (column.key === 'role') {
      // Military Service Rank is only visible if the Service Status is not NONE
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
    }

    if (column.key === 'organization') {
      // organization is visible if the Service Status is not None
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        //
        return true
      }
      // If the service is Air Force and the MAJCOM is AMC don't show
      if (item.militaryServiceRank.name &&
        (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force'))) {
        if (item.majcom && item.majcom.pk === "z.3cccab1b-a844-4ce2-a5e3-801129abc531") {
          return true
        }
      }
    }

    if (column.hiddenForRegistrationTypes) {
      return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
    }

    return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
  }

  // Support for grid level editing
  function onStartEdit(event) {
    event.stopPropagation()
    setIsGridEditing(true)
  }
  function onEndEdit(event) {
    event.stopPropagation()
    setIsGridEditing(false)
    setValue(originalValue)
  }
  function onGridChange(event) {
    event.stopPropagation()
    setValue(event.target.value)
  }

  function ignoreEvent(event) {
    event.stopPropagation()
  }

  function handleKeyPress(event) {
    event.stopPropagation()
    if (event.key === 'Enter') {
      setIsGridEditing(false)
      if (column.key === 'requestedTable') {
        setAdminRequestedTable(item.pk, value)
      } else if (column.key === 'assignedGroup') {
        setAssignedGroup(item.pk, value)
      } else {
        setAdminValue(item.pk, column.key, value)
      }
      setOriginalValue(value)
    }
  }

  function handleKeyDown(event) {
    event.stopPropagation()
    if (event.key === 'Escape') {
      setIsGridEditing(false)
      setValue(originalValue)
    }
  }

  // Stores the value in the item and determines if the value is valid
  useEffect(() => {
    if (!isView()) {
      let itemPath = column.key.split('.')
      if (itemPath.length === 1) {
        item[column.key] = value
      } else {
        item[itemPath[0]][itemPath[1]] = value
      }
    }
    const isValidValue = value ?? ''
    if (column.fieldType === 'string' && column.maxLength) {
      setValid(!(column.required && isValidValue.length === 0 && isValidValue.length <= column.maxLength) || isHidden() || !column.userEditable)
    } else {
      setValid(!(column.required && isValidValue.length === 0) || isHidden() || !column.userEditable)
    }
  }, [value, updatedAt])

  // Passes the valid value to the parent
  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.value)
  }

  // This is specific to table assignments that happen in the context of the list and change values
  // which typically only happens in edit mode and through the controlled component.
  useEffect(() => {
    if (column.key === 'table') {
      if (value !== item[column.key]) {
        setValue(item[column.key])
      }
    }
  }, [item.table])


  if (isView()) {
    if (column.key === 'requestedTable' || column.key === 'assignedGroup' ||
      column.key === 'badgeBaseName' || column.key === 'badgeOrganization') {
      if (isGridEditing) {
        return (
          <td>
            <input
              className="usa-input"
              id={column.key}
              name="input-type-text"
              type="text"
              maxLength={column.maxLength}
              required={column.required}
              style={{ backgroundColor: '#eeeeee', width: '70%', marginRight: 10, display: "inline-block" }}
              value={value}
              onChange={onGridChange}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              onClick={ignoreEvent}
            />
            <i className="fas fa-times-circle" onClick={onEndEdit}></i>
          </td>

        )
      }
      return <td style={viewStyle}>{value} <i className="fal fa-pencil" onClick={onStartEdit}></i></td>
    }

    return <td style={viewStyle}>{value}</td>
  }

  if (isHidden()) { return null }

  let className = isValid ? 'usa-input' : 'usa-input usa-input--error'

  return (
    <div style={{ marginTop: 5 }}>
      <input
        className={className}
        id={column.key}
        name="input-type-text"
        type="text"
        maxLength={column.maxLength}
        required={column.required}
        style={editStyle}
        value={value}
        onChange={onChange}
      />
      {column.helpText &&
        <span style={helpStyle}>
          {column.helpText}
        </span>
      }
    </div>
  )
}
