import React from 'react'

export default function GridConfig(props) {

  const { gridDef, setColumns, setAvailableFilterColumns, setCsvColumns, fixedColumns } = props

  function toggleVisibility(column) {
    column.isVisible = !column.isVisible || column.alwaysVisible
    setColumns(fixedColumns.concat(gridDef.availableColumns.filter(column => column.isVisible)))
    setAvailableFilterColumns(gridDef.availableColumns.filter(column => column.mayFilter && column.isVisible))
  }

  function toggleCsv(column) {
    column.includeInCsv = !column.includeInCsv
    setCsvColumns(gridDef.availableColumns.filter(column => column.includeInCsv))
  }

  function toggleMayFilter(column) {
    column.mayFilter = !column.mayFilter
    setAvailableFilterColumns(gridDef.availableColumns.filter(column => column.mayFilter && column.isVisible))
  }

  return (
    <table className="usa-table usa-table--compact usa-table--borderless usa-table--striped" style={{width:'100%',   tableLayout: 'fixed'}}>

      <tr>
        <th style={{textAlign: 'center', width: 65, fontWeight:'bold'}}>Visible</th>
        <th style={{textAlign: 'center', width: 65, fontWeight:'bold'}}>Include in CSV</th>
        <th style={{textAlign: 'center', width: 65, fontWeight:'bold'}}>Allow Filter By</th>
        <th style={{width: 300, fontWeight:'bold'}}>Column</th>
      </tr>
      {gridDef.availableColumns.map(column => {
        return (
          <tr key={column.key}>
            <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={() => toggleVisibility(column)}>
              {column.isVisible ? <span style={{fontWeight: 800, color: '#b50908'}}>Yes</span> : <span style={{color: 'grey'}}>No</span>}
            </td>
            <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={() => toggleCsv(column)}>
              {column.includeInCsv ? <span style={{fontWeight: 800, color: '#b50908'}}>Yes</span> : <span style={{color: 'grey'}}>No</span>}
            </td>
            <td style={{textAlign: 'center', cursor: 'pointer'}} onClick={() => toggleMayFilter(column)}>
              {column.mayFilter ? <span style={{fontWeight: 800, color: '#b50908'}}>Yes</span> : <span style={{color: 'grey'}}>No</span>}
            </td>
            <td>{column.label}</td>
          </tr>
        )
      })}
    </table>
  )
}
