import React, {useState} from 'react'
//import { useLocation, matchPath } from "react-router-dom"

import Badge from './Badge'
import useQuery from '../admin/useQuery'

export default function BadgePage() {
 
  const query = useQuery()
  const [ids] = useState(JSON.parse(query.get("ids")))
 
  if (!ids) {return null}

  let page = []
  let pages = []

  const max = Math.min(40 *6, ids.length)
  



  for (let i = 0; i < max; i += 2) {

    page.push( 
      <div className={"badge-row"}>
        <Badge pk={ids[i]}  badgePosition={i % 6} badgePage={Math.floor(i / 6)}/>
        <Badge pk={ids[i+1]}  badgePosition={(i + 1) % 6} badgePage={Math.floor((i + 1) / 6)}/>
      </div>
    )

    if (i % 6 === 5 || i === max - 1) {
      pages.push(
        <div className={"new-page-" + Math.floor(i / 6)}>
          <Page page={page}/>
        </div>
      )
      page = []
    }
  }

  pages.push(page)
  return (pages)

}

function Page(props) {
  return (props.page)
}



// export default function BadgePage() {
 
//   const query = useQuery()
//   const [ids] = useState(JSON.parse(query.get("ids")))
 
//   if (!ids) {return null}

//   let page = []
//   let pages = []

//   const max = Math.min(40 *6, ids.length)
  
//   for (let i = 0; i < max; i += 1) {

//     page.push( 
//       <div className={"badge-" + (i % 6) }>
//         <Badge pk={ids[i]}  badgePosition={i % 6} badgePage={Math.floor(i / 6)}/>
//       </div>
//     )

//     if (i % 6 === 5 || i === max - 1) {
//       pages.push(
//         <div className={"new-page-" + Math.floor(i / 6)}>
//           <Page page={page}/>
//         </div>
//       )
//       page = []
//     }
//   }

//   pages.push(page)
//   return (pages)

// }

// function Page(props) {
//   return (props.page)
// }
