import React from 'react'
import {
  useParams,
  Redirect
} from "react-router-dom"

import Collection from '../admin/Collection'
import useQuery from '../admin/useQuery'

const introductionText = <div>
  <h3 style={{ marginTop: 0, borderBottomStyle: 'solid' }}>Membership Information</h3>
  <div className="grid-row margin-y-1">
    <div className="tablet:grid-col-10">
      <h4 style={{ marginTop: 0, marginBottom: -10 }}> Pricing</h4>
      <ul>
        <li>Life Membership: $500</li>
        <li>3 Year Membership: $110</li>
        <li>Annual Year Membership: $40</li>
        <li>Discounted Annual Membership (E1-E4s): $20</li>
      </ul>
      <p className="usa-prose">We will ask for proof of eligibility for a discounted E1-E4 membership.</p>
    </div>
    <div className="tablet:grid-col-2">
    </div>

  </div>
</div>



export default function MembershipCreate(props) {

  const query = useQuery()
  const { itemType } = useParams()
  let { item, membershipForm, user } = props

  let itemZero
  try {
    itemZero = item.collection.segments[0].items[0]
  }
  catch {
    itemZero = false
  }

  if (query.get("canceled") === "true") {
    return <Redirect to="/account" />
  }

  return (
    <>
      {itemType === 'membership' && itemZero.neverSaved && !membershipForm &&
        <div className="grid-container margin-y-0">
          <i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
        </div>
      }

      {itemType === 'membership' && itemZero.neverSaved && membershipForm &&
        <div className="grid-container margin-y-0">

          <Collection
            collection={item.collection}
            saveSuccessful={item.saveSuccessful}
            status={item.status}
            previousStatus={item.previousStatus}
            setStatus={item.setStatus}
            form={{ items: [membershipForm] }}
            introductionText={introductionText}
            user={user}
          />
        </div>
      }
    </>
  )
}
