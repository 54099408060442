import React, { useState, useEffect } from 'react'
import { PageHeader } from '../admin/Section'
import { Link } from "react-router-dom"

import useQuery from '../admin/useQuery'
import Panel from "../admin/Panel"
import Table from '../admin/Table'
import TableHeadings from '../admin/TableHeadings'
import TableBody from '../admin/TableBody'
import Column from '../admin/fields/Column'

export default function GroupRegistration(props) {

  const { profile, form } = props
  const [incompleteRegistrations, setIncompleteRegistrations] = useState([])
  const [incompleteRegistrationsPrice, setIncompleteRegistrationsPrice] = useState(0)
  const [registrationStatus, setRegistrationStatus] = useState({})

  useEffect(() => {
    if (props.registrationStatus && props.registrationStatus.data) {
      setRegistrationStatus(props.registrationStatus.data)
    }
  }, [props.registrationStatus])

  const query = useQuery()
  const segments = profile.collection.segments

  useEffect(() => {

    let parameters = []
    for (let segment of profile.collection.segments) {
      parameters = parameters.concat(segment.items.filter(item => item.gsi1_pk === 'registration:primary:incomplete'))
    }

    setIncompleteRegistrations(parameters.slice(0, 10).map(i => i.pk))
    setIncompleteRegistrationsPrice(parameters.slice(0, 10).reduce((previousValue, currentValue) => previousValue + currentValue.price, 0))

  }, [props.profile.collection.segments])

  let newRegistration = false
  if (query.get('newRegistration')) {
    newRegistration = query.get('newRegistration')
  }

  if (!profile) { return null } //  || !form
  let profileForm = form.items.filter(f => f.pkType === 'profile')
  if (profileForm.length === 0) { return null }

  return (
    <>
      <div style={{ width: '95%' }}>
        <PageHeader title="Group Registrations"
          description="" />
      </div>
      {registrationStatus.registrationActive &&
        <MyProfileControls parameters={incompleteRegistrations} price={incompleteRegistrationsPrice} />
      }

      {newRegistration &&
        <div className="usa-alert usa-alert--success" style={{ marginTop: 40 }}>
          <div className="usa-alert__body">
            <h4 className="usa-alert__heading">The New Unpaid Registration has been added </h4>
            <p className="usa-alert__text">
              An email will be send to the attendee when you pay for this registration. To pay use the <strong>Pay For Your Registrations</strong> button to pay for up to 10 registrations at a time.
            </p>
          </div>
        </div>
      }

      {profileForm[0].childViews.map((view, i) => {
        let secondaryForm = props.form.items.filter(f => f.pk === view.pk && f.sk === view.sk)[0]

        let secondaryItem = false
        for (let segment of segments.slice(1)) {
          if (segment.itemCount > 0 && (segment.items[0].gsi3_sk === view.itemType || segment.items[0].gsi1_pk === view.itemType)) {
            secondaryItem = segment
          }
        }
        if (!secondaryItem) { return null }

        let secondaryView = secondaryForm.views.filter(f => f.id === view.viewId)[0]

        return (
          <Panel key={i} title={view.title}>
            <Table>
              <TableHeadings>
                <tr>
                  {secondaryView.fieldIds.map((fieldId, i) => {
                    let col = secondaryForm.fields.findIndex(field => field.fieldId === fieldId)
                    return <td key={i}>{secondaryForm.fields[col].label}</td>
                  }
                  )}
                </tr>
              </TableHeadings>
              <TableBody>
                {secondaryItem.items.map((item, j) =>
                  <tr key={j}>
                    {secondaryView.fieldIds.map((fieldId, i) => {
                      let col = secondaryForm.fields.findIndex(field => field.fieldId === fieldId)
                      return <Column key={i} item={item} column={secondaryForm.fields[col]} />
                    }
                    )}
                  </tr>
                )}
              </TableBody>
            </Table>
          </Panel>
        )
      })}
    </>
  )
}

function MyProfileControls(props) {

  const { parameters, price } = props

  let className = parameters.length > 0 ? "usa-button" : "usa-button usa-button--disabled"

  return (
    <div>
      <div style={{ paddingBottom: 24 }}>
        <div style={{ float: 'left' }}>
          <div style={{ paddingBottom: 12 }}>
            <div style={{ float: 'left' }}>
              <span style={{ paddingRight: 10 }}>
                <Link className="usa-button" to="/account/registration/new?group=true&cancel=/account/groups">
                  Add a Registration
                </Link>
                <span style={{ paddingRight: 10 }}>
                  <Link className={className} to={"/account/receipt/new?registrations=" + JSON.stringify(parameters)} >
                    Pay for Your Registrations {price > 0 ? <span>(${price.toLocaleString()})</span> : <span></span>}
                  </Link>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
