import React from 'react'
import { Link } from "react-router-dom"

export default function Hero() {

  return (
    <section className="usa-hero" style={{ backgroundImage: 'url(/images/hero-audience.png)' }}>
      <div className="grid-container">
        <div className="usa-hero__callout">
          <h1 className="usa-hero__heading">
            <span className="usa-hero__heading--alt">Convention Library</span>
          </h1>
          <p>Convention 2024 is done, but you can access keynotes and other highlights from 2024 and prior conventions all year. </p>
          <Link
            to="/about-the-convention/media"
            title="Replay"
            aria-label="Review 2024"
            className="usa-button"
          >
            Access the Library
          </Link>
        </div>
      </div>
    </section>
  )
}
