const mission = [
  {
    label: 'About the A/TA',
    link: '/about',
    order: 10,
    children: [
      {
        label: 'Board of Officers',
        link: '/about/board-of-officers',
        order: 11
      },
      {
        label: 'Advisory Board',
        link: '/about/board-of-advisors',
        order: 12
      },
      {
        label: 'A/TA Liaisons',
        link: '/about/liaisons',
        order: 13
      },
      {
        label: 'A/TA Support Staff',
        link: '/about/support-staff',
        order: 14
      },
      {
        label: 'Convention and Symposium Contacts',
        link: '/about/convention-contacts',
        order: 15
      },
      {
        label: 'General Contact Information',
        link: '/contact',
        order: 16
      },
    ]
  },
  {
    label: 'Chairman\'s Message',
    link: '/chairmans-message',
    order: 20
  },
  {
    label: 'Hall of Fame',
    link: '/hall-of-fame',
    children: [
      {
        label: 'Hall of Fame Nomination and Selections Guidelines',
        link: '/hall-of-fame/nomination-guidelines',
        order: 21
      },
    ],
    order: 30
  },
  {
    label: 'Enlisted Education Grant',
    link: '/enlisted-education-grant',
    children: [
      {
        label: 'Enlisted Education Grant Guidelines',
        link: '/enlisted-education-grant/guidelines',
        order: 31
      },
      {
        label: 'Enlisted Education Grant Forms',
        link: '/enlisted-education-grant/forms',
        order: 32
      },
    ],
    order: 40
  },
  {
    label: 'Scholarships',
    link: '/scholarships',
    children: [],
    order: 40
  },
  {
    label: 'Awards Program',
    link: '/awards-program',
    children: [
      {
        label: 'Awardee Benefits',
        link: '/awards-program/awardee-benefits',
        order: 41
      },
      {
        label: 'Ronald R. Fogleman Award',
        link: '/awards-program/fogleman-award',
        order: 42
      },
    ],
    order: 50
  },
]

const industryPartners = [
  {
    label: 'Our Industry Partners',
    link: '/industry-partners',
    order: 10
  },
  {
    label: 'Industry Partners News',
    link: '/industry-partners-news',
    order: 20
  },
  {
    label: 'Industry Partner Benefits',
    link: '/industry-partner-benefits',
    order: 30,
    children: [
      {
        label: 'Fall Industry Panels',
        link: '/industry-partner-benefits/fall-industry-panels',
        order: 31
      },
    ]
  },
  {
    label: 'W-9 Request',
    link: '/w9-request',
    order: 40
  },
]

const members = [
  {
    label: 'Become a Member',
    link: '/become-a-member',
    order: 10
  },
  {
    label: 'Chapters',
    link: '/chapters',
    order: 20
  },
  {
    label: 'A/TQ Magazine',
    link: '/atq',
    order: 30
  },
  {
    label: 'Virtual Seminars',
    link: '/virtual-seminars',
    order: 40
  },
  {
    label: 'Board/Business Meeting',
    link: '/ata-business-meeting',
    order: 50
  },
  {
    label: 'Account',
    link: '/account',
    order: 60,
    auth: true
  },
]

// const virtual = [
//   { label: 'About the Convention',
//     link: '/about-the-convention2',
//     children: [
//       { label: 'Industry Interface Day',
//         link: '/virtual2020/iid',
//         order: 31 },
//       ],
//     order: 10 },
//   { label: 'Register',
//     link: '/register',
//     order: 20 },
//   { label: 'Welcome',
//     link: '/virtual2020/welcome',
//     auth: true,
//     order: 25 },
//   { label: 'Exhibit Hall',
//     link: '/exhibit-hall',
//     order: 26,
//     auth: false },
//   { label: 'Agenda',
//     link: '/virtual2020',
//     auth: false,
//     order: 30,
//     children: [
//       { label: 'On Demand Seminars',
//         link: '/virtual2020/ondemand',
//         order: 31 },
//     ]
//   },
//   { label: 'Awards',
//     link: '/virtual2020-awards',
//     auth: false,
//     order: 26,
//     children: [
//       { label: 'AMC Awards',
//         link: '/virtual2020-awards/amc-awards',
//         auth: true,
//         order: 31 },
//       { label: 'HOF Award',
//         link: '/virtual2020-awards/hof-award',
//         auth: true,
//         order: 31 },
//       { label: 'Tampa Bay Award',
//         link: '/virtual2020-awards/tampa-bay-award',
//         auth: true,
//         order: 32 },
//       { label: 'Abilene Award',
//         link: '/virtual2020-awards/abilene-award',
//         auth: true,
//         order: 32 },
//       ]
//   },
//   { label: 'Spark Tank',
//     link: '/virtual2020/spark-tank',
//     order: 24 },
//   // { label: 'Browse to Win',
//   //   link: '/virtual2020-browse-to-win',
//   //   auth: true,
//   //   order: 27,
//   //   children: [
//   //     { label: 'WINGO',
//   //       link: '/virtual2020-browse-to-win/wingo',
//   //       order: 31 },
//   //     { label: 'Crossword Puzzler',
//   //       link: '/virtual2020-browse-to-win/crossword-puzzler',
//   //       order: 31 },
//   //     ]
//   // },
//
//   { label: 'Sponsors',
//     link: '/virtual2020-sponsors',
//     order: 40 },
//
//   { label: 'Survey',
//     link: 'https://docs.google.com/forms/d/e/1FAIpQLSfIbS0I8i7YXNC6Z5k9IsZqZXgWEzkhu4aICYxYvPQocxTi6A/closedform',
//     order: 70,
//     auth: true },
//   { label: 'Make a Donation',
//     link: '/donate',
//     order: 80,
//     auth: false }
// ]

const exhibiting = [
  {
    label: 'Technology Expo',
    link: '/convention-exhibitors/exhibiting',
    order: 31
  },
  {
    label: 'Exhibit Space',
    link: '/convention-exhibitors/exhibit-space',
    order: 32
  },
  {
    label: 'Exhibitor Package',
    link: '/convention-exhibitors/exhibitor-package',
    order: 33
  },
]


const convention = [
  {
    label: 'About the Convention',
    link: '/about-the-convention',
    order: 10,
    children: [
      {
        label: 'Symposium ',
        link: '/about-the-convention/symposium',
        order: 11
      },
      {
        label: 'Fall Industry Panels ',
        link: '/about-the-convention/fall-industry-panels',
        order: 12
      },
      {
        label: 'Attention: Guests Welcome',
        link: '/about-the-convention/spouses-welcome',
        order: 13
      },
      {
        label: 'Sponsorship Opportunities ',
        link: '/about-the-convention/sponsorship',
        order: 14
      },
      {
        label: 'Session and Media Library',
        link: '/about-the-convention/media',
        order: 15
      },
      {
        label: 'Convention Calendar',
        link: '/about-the-convention/calendar',
        order: 16
      },
    ]
  },
  {
    label: 'Attending',
    link: '/convention-attendee',
    order: 20,
    children: [
      {
        label: 'Attendee Fees',
        link: '/convention-attendee/fees',
        order: 21
      },
      {
        label: 'Hall of Fame Banquet',
        link: '/convention-attendee/banquet',
        order: 22
      },
      {
        label: 'Heritage Room',
        link: '/convention-attendee/heritage-room',
        order: 23
      },
      {
        label: 'Dress Code',
        link: '/convention-attendee/dress-code',
        order: 24
      },
      {
        label: 'Cornhole Rules',
        link: '/convention-attendee/cornhole',
        order: 25
      },
    ]
  },
  {
    label: 'Tech Expo',
    link: '/tech-expo',
    order: 30,
    children: [
      {
        label: 'Exhibiting',
        link: '/convention-exhibitors/exhibiting',
        order: 31
      },
      {
        label: 'Exhibit Space',
        link: '/convention-exhibitors/exhibit-space',
        order: 32
      },
      {
        label: 'Exhibitor Package',
        link: '/convention-exhibitors/exhibitor-package',
        order: 33
      },
    ]
  }
]

const privacy = [
  {
    label: 'Privacy and Terms of Use',
    link: '/privacy-statement',
    order: 10
  }
]

// const hostname = window && window.location && window.location.hostname
// const conventionMenu = (
//   hostname === 'www.atalink.org' ||
//   hostname === 'atalink.org') ?
//   convention   :
//   virtual

export var navigation = [
  {
    parentLabel: 'Mission',
    items: mission,
    sequence: 'one',
    topNavigation: true
  },
  {
    parentLabel: 'Industry Partners',
    items: industryPartners,
    sequence: 'two',
    topNavigation: true
  },
  {
    parentLabel: 'Members',
    items: members,
    sequence: 'three',
    topNavigation: true
  },
  // { parentLabel: 'Convention',
  //   items: virtual,
  //   sequence: 'four',
  //   topNavigation: true },
  {
    parentLabel: 'Convention',
    items: convention,
    sequence: 'fourA',
    topNavigation: true
  },
  {
    parentLabel: 'Exhibiting',
    items: exhibiting,
    sequence: 'five',
    topNavigation: true
  },

  {
    parentLabel: 'Privacy and Terms of Use',
    items: privacy,
    sequence: 'six',
    topNavigation: false
  }
]
