import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify'

export default function FieldCoupon(props) {

  let [value, setValue] = useState(props.initialValue ?? '')
  let [isValid, setValid] = useState(false)
  let [validationMessage, setValidationMessage] = useState(false)
  let [isSearching, setIsSearching] = useState(false)
  const [service, setService] = useState(false)

  const { item, column,
    status,
    updateField,
    isColumn,
    editStyle, viewStyle, helpStyle } = props

  function isView() {
    return status === 'LOADED' || !column.userEditable || isColumn
  }

  function isHidden() {
    if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
    // visibleForRegistrationTypes is included in the field definition
    // so always keep it hidden until the registration type is selected.
    if (!item.registrationType.pk) { return true }

    if (column.hiddenForRegistrationTypes) {
      return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
    }

    return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
  }

  useEffect(() => {
    if (props.column.fieldType !== 'coupon') { return }

    if (isView()) { return }

    const isValidValue = value ?? ''
    if (isValidValue.length === 0) {
      setValidationMessage(false)
    }
  }, [value])

  useEffect(() => {
    item[column.key] = value

    const isValidValue = value ?? ''
    if (column.fieldType === 'coupon' && column.maxLength) {
      if (column.required && isValidValue.length === 0 && column.visibleForRegistrationTypes.includes(item.registrationType.pk)) {
        setValid(false)
      } else {
        if (isValidValue.length === 0) {
          setValid(true)
        }
        if (column.key === 'amcCode' && isValidValue === item.code.name) {
          setValid(true)
        }
        if (column.key === 'requestedCoupon' && isValidValue === item.coupon.name) {
          setValid(true)
        }
      }
    } else {
      setValid(!(column.required && isValidValue.length === 0))
    }
  }, [value, item.registrationType])

  // Passes the valid value to the parent
  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  useEffect(() => {
    Auth.currentSession()
      .then(() => { setService('coupon') })
      .catch(() => { setService('couponUnauth') })
  }, [props.itemType])

  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.value)
  }

  function searchForCoupon() {
    if (isSearching) { return }
    setIsSearching(true)

    API.get(service, '/coupon/' + value + '?amcCode=' + column.isAmcCode)
      .then((data) => {
        if (data.isValid) {
          if (column.isAmcCode) {
            item.code.pk = data.pk
            item.code.name = data.name
          } else {
            item.price = 0
            item.coupon.pk = data.pk
            item.coupon.name = data.name
          }
        }
        setValid(data.isValid)
        setValidationMessage(data.message)
        setIsSearching(false)
      })
      .catch(error => {
        console.log(error)
        if (column.isAmcCode) {
          item.code = {}
          setValidationMessage('The code is invalid')
        } else {
          item.price = item.registrationType.price
          item.coupon = {}
          setValidationMessage('The coupon is invalid')
        }
        setValid(false)
        setIsSearching(false)
      })
  }

  if (isView()) {
    return <td style={viewStyle}>{item[column.key]}</td>
  }
  if (isHidden()) { return null }
  let className = isValid ? 'usa-input' : 'usa-input usa-input--error'

  return (
    <div style={{ marginTop: 5, marginRight: 10 }}>
      <input
        className={className}
        id={column.key}
        name="input-type-text"
        type="text"
        maxLength={column.maxLength}
        required={column.required}
        style={editStyle}
        value={value}
        onChange={onChange}
      />
      <button
        className="usa-button"
        style={{ textDecoration: 'none', fontSize: 'small', marginTop: 4 }}
        disabled={isSearching || value.length < 4 || !item.registrationType.pk ||
          (column.key === 'amcCode' && value === item.code.name) ||
          (column.key === 'requestedCoupon' && value === item.coupon.name)
        }
        onClick={searchForCoupon}
      >
        {column.isAmcCode ? <>Validate Code</> : <>Validate Coupon</>}
      </button>  {isSearching ? <i className="fas fa-circle-notch fa-spin"></i> : <span></span>}

      {column.helpText &&
        <div style={helpStyle}>
          <span style={{ fontSize: 'larger' }} >{validationMessage ? validationMessage : column.helpText}</span>
        </div>
      }
    </div>
  )
}
