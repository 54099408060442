import React, { Component } from 'react';

import {PageHeader} from './sections/Section'

class Loading extends Component {

  render() {

    const size = this.props.size ? this.props.size : 'h2'

    return (
      <section
        className="usa-section"
        style={{ paddingTop: 15, paddingBottom: 10, borderTopWidth: 0 }}
       >
        <div className="grid-container margin-y-0">
          {size === 'h1' &&
            <PageHeader title={this.props.title} />
          }
          {size === 'h2' &&
            <h2 className="margin-y-0">{this.props.title}</h2>
          }
          <p className="usa-prose">{this.props.subtitle}</p>

          <div className="usa-prose margin-y-5">
            <span style={{ verticalAlign: "text-top" }}>
              <i className="fas fa-circle-notch fa-spin" />
            </span>
            <span style={{ paddingLeft: "8px", fontSize: "large" }}>Loading...</span>
          </div>
        </div>
      </section>
    )
  }
}

export default Loading
