import React, { useState, useEffect } from 'react'
import {
  useParams,
  Link
} from "react-router-dom"

import useList from '../admin/useList'
import useQuery from '../admin/useQuery'
import useCollection from '../admin/useCollection'
import BanquetChoices from '../convention2024/BanquetChoices'
import BadgeChoices from '../convention2024/BadgeChoices'
import Cancel from '../convention2024/Cancel'
import Hotel from '../convention2024/Hotel'
import RegistrationCreate from '../convention2024/RegistrationCreate'
import RegistrationReceipt from '../convention2024/RegistrationReceipt'
import Panel from '../admin/Panel'
import useRegistrationStatus from '../admin/useRegistrationStatus'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

var moment = require('moment')

export default function Registration(props) {


  const query = useQuery()
  const { itemType, id } = useParams()

  if (itemType !== 'registration') {
    if (!(itemType === 'receipt' && query.get("registrations"))) {
      return null
    }
  }
  const [registrationForm, setRegistrationForm] = useState(false)
  const formProps = { stopAfterPage: 999, pageSize: 1000, source: "/list/quickform", items: [] }
  const form = useList(formProps)
  const item = useCollection({ itemType: itemType, id: id ?? 'new' })
  const [itemZero, setItemZero] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState('')
  const registrationStatus = useRegistrationStatus({ source: '/registrationStatus' })

  if (form.items.length > 0 && (!registrationForm || (registrationForm && registrationForm.pkType !== itemType))) {
    setRegistrationForm(form.items.filter(i => i.pkType === itemType)[0])
  }

  let guestButtonClass = props.guestAllowed ? 'usa-button' : 'usa-button usa-button--disabled'

  // This is used to force editing after the user returns from Stripe.
  useEffect(() => {
    if (itemZero) {
      setConfirmationCode(itemZero.pk.slice(2, 10).toUpperCase())

      if (itemZero.neverSaved) {
        item.setStatus('EDITING')
      }
    }
  }, [itemZero])

  useEffect(() => {
    if (item.collection && item.collection.segments && item.collection.segmentCount > 0 && item.collection.segments[0].items) {
      setItemZero(item.collection.segments[0].items[0])
    }
  }, [item])

  if (!item.collection.segments || item.collection.segmentCount === 0) {
    return null
  }

  let style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }
  let sectionStyle = { fontWeight: 'bold', borderBottom: 'solid', borderWidth: 'thin', marginBottom: 10, marginTop: 20, width: '100%' }

  let offerHotel = true
  if (registrationStatus.data && registrationStatus.data.limitHotelRegistrationsTo && itemZero.registrationType) {
    offerHotel = (registrationStatus.data.limitHotelRegistrationsTo.length === 0 ||
      (registrationStatus.data.limitHotelRegistrationsTo.length > 0 && registrationStatus.data.limitHotelRegistrationsTo.includes(itemZero.registrationType.pk)))
  }

  return (
    <>
      <RegistrationCreate item={item} registrationForm={registrationForm} user={props.user} />
      <RegistrationReceipt item={item} registrationForm={registrationForm} />

      {itemType === 'registration' && registrationForm && item.status !== 'EDITING' &&
        <div className="grid-container margin-y-0">
          <>
            {form.items.length === 0 &&
              <tr ><td style={{ borderWidth: 0 }}><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i></td></tr>
            }

            {query.get("success") === "true" &&
              <div className="usa-alert usa-alert--success" style={{ marginTop: 20 }}>
                <div className="usa-alert__body">
                  <h4 className="usa-alert__heading">Congratulations. Your Convention Registration is Complete</h4>
                  <p className="usa-alert__text">
                    An email is on its way with your receipt. Your confirmation code is {confirmationCode}. Use this if you need to return later to manage your booking.
                    If you want to add Industry Day or a Guest you will need this code and are required to Sign In or Sign Up.
                  </p>
                  <p>Do not forget to add your hotel room below.</p>
                </div>
              </div>
            }

            {item && item.status !== 'SAVING' &&
              <Panel title="Registration Information">
                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Attendee</div></div>
                  <div className="tablet:grid-col-8"> {itemZero && itemZero.fullName}</div>
                </div>

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Primary Email</div></div>
                  <div className="tablet:grid-col-8"> {itemZero && itemZero.primaryEmail}</div>
                </div>

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Phone Number</div></div>
                  <div className="tablet:grid-col-8"> {itemZero &&
                    <PhoneInput value={itemZero.phoneNumber} readOnly={true} className="phone-number-local" />
                  }</div>
                </div>

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Registration Type</div></div>
                  <div className="tablet:grid-col-8"> {itemZero && itemZero.registrationType.name}</div>
                </div>

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Price</div></div>
                  <div className="tablet:grid-col-8"> ${itemZero && itemZero.price}</div>
                </div>

                {itemZero && itemZero.registrationType.name === 'Exhibitor' &&
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Exhibitor</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.exhibitor.name}</div>
                  </div>
                }

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Registered By</div></div>
                  <div className="tablet:grid-col-8"> {itemZero && itemZero.user.pk}</div>
                </div>

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Registered At</div></div>
                  <div className="tablet:grid-col-8"> {itemZero && moment(itemZero.createdAt).format('YYYY-MM-DD LT')}</div>
                </div>

                <div className="grid-row margin-y-1">
                  <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Status</div></div>
                  <div className="tablet:grid-col-8"> {itemZero && itemZero.statusName}</div>
                </div>

                {itemZero && (itemZero.militaryServiceRank.name || itemZero.militaryServiceStatus.name) &&
                  <div className="tablet:grid-col-12">
                    <div className="usa-prose" style={sectionStyle}> Military Service</div>
                  </div>
                }

                {itemZero && itemZero.militaryServiceRank.name &&
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Service and Rank</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.militaryServiceRank.name}</div>
                  </div>
                }
                {itemZero && itemZero.militaryServiceStatus.name &&
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Status</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.militaryServiceStatus.name}</div>
                  </div>
                }

                {itemZero && itemZero.base.name &&
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Base</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.base.name}</div>
                  </div>
                }
                {itemZero && itemZero.organization &&
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Organization</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.organization}</div>
                  </div>
                }
                {itemZero && itemZero.role &&
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Role</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.role}</div>
                  </div>
                }

                {itemZero && itemZero.gsi1_pk !== 'registration:primary:incomplete' &&
                  offerHotel && itemZero.registrationType.offerHotel &&
                  <>
                    <div className="tablet:grid-col-12">
                      <div className="usa-prose" style={sectionStyle}> Booking a Hotel</div>
                    </div>

                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Hotel Status</div></div>
                      <div className="tablet:grid-col-8"> {itemZero.hotelRoomStatusName ?? 'Not started'}</div>
                    </div>

                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-2"></div>
                      <div className="tablet:grid-col-9">
                        <Hotel item={item} />
                        <p>We cannot track all the changes that are made using the hotel website. Let us know if either of these situations apply to you.</p>
                        <button className="usa-button--unstyled" style={{ paddingLeft: 20, textDecoration: 'none' }} onClick={() => item.registrationAction(item.ACTION.HOTEL_DONT_NEED_ROOM)}>Room Not Needed</button>
                        <button className="usa-button--unstyled" style={{ paddingLeft: 20, textDecoration: 'none' }} onClick={() => item.registrationAction(item.ACTION.HOTEL_ALREADY_HAVE_ROOM)}>Room Already Booked</button>
                      </div>
                    </div>
                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-2"></div>
                      <div className="tablet:grid-col-10">
                        <div className="usa-alert usa-alert--info usa-alert--slim" >
                          <div className="usa-alert__body">
                            <p className="usa-alert__text" name="hotel popup">The hotel booking site will open in a new window. Please ensure your <strong>pop-up blocker settings</strong> allow the room booking site to pop-up as a new tab or window.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }

                {itemZero && itemZero.gsi1_pk !== 'registration:primary:incomplete' &&
                  offerHotel && !itemZero.registrationType.offerHotel &&
                  <>
                    {itemZero.registrationType.limited ?
                      <>
                        <div className="tablet:grid-col-12">
                          <div className="usa-prose" style={sectionStyle}> Booking a Hotel</div>
                        </div>
                        <div className="grid-row margin-y-1">
                          <div className="tablet:grid-col-12">
                            <p className="usa-prose">
                              A/TA does not offer special hotel rates for this registration type.
                              A full convention registration is required to qualify for discounted hotel room rates.
                              However, if there is a need for an additional room, please contact Patti Cost (<a href="mailto:programsvp@atalink.org">programsvp@atalink.org</a>).
                              For general help please contact <a href="mailto:ata@atalink.org">ata@atalink.org</a>.
                            </p>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className="tablet:grid-col-12">
                          <div className="usa-prose" style={sectionStyle}> Booking a Hotel</div>
                        </div>
                        <div className="grid-row margin-y-1">
                          <div className="tablet:grid-col-12">
                            <p className="usa-prose">
                              There are no additional corporate rate rooms available in our block, please contact Gaylord Texan for available room reservations.
                            </p>
                          </div>
                        </div>
                      </>
                    }
                  </>
                }



                {itemZero && itemZero.registrationType.banquetIncluded && itemZero.gsi1_pk === 'registration:primary:complete' &&
                  <>
                    <div className="tablet:grid-col-12">
                      <div className="usa-prose" style={sectionStyle}> Attending the Banquet</div>
                    </div>
                    <BanquetChoices item={item} />
                  </>
                }

                {itemZero && itemZero.gsi1_pk !== 'registration:primary:incomplete' &&
                  <>
                    <div className="tablet:grid-col-12">
                      <div className="usa-prose" style={sectionStyle}> Customize Your Badge</div>
                    </div>
                    <BadgeChoices item={item} />
                  </>
                }

                {itemZero && itemZero.gsi1_pk === 'registration:primary:complete' && !(itemZero.gsi2_pk && itemZero.gsi2_pk.slice(0, 1) === 'r') && itemZero.registrationType.guestsAllowed &&
                  <>
                    <div className="tablet:grid-col-12">
                      <div className="usa-prose" style={sectionStyle}> Register a Guest</div>
                    </div>
                    <p className="usa-prose" style={{ marginTop: 0 }}>Add a guest to your registration. Use <Link to="/account/profile">My Profile</Link> to review your current guest registrations.</p>
                    <p className="usa-prose" style={{ marginTop: 0 }}>If you need assistance with your guest registrations, please contact ata@atalink.org.</p>

                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-2"></div>
                      <div className="tablet:grid-col-8"> <a href={"/account/registration?parentRegistration=" + itemZero.pk} className={guestButtonClass}>Register a Guest</a></div>
                    </div>
                  </>
                }

                {itemZero && itemZero.gsi1_pk === 'registration:primary:incomplete' &&
                  <>
                    <div className="tablet:grid-col-12">
                      <div className="usa-prose" style={sectionStyle}> Pay for This Registration</div>
                    </div>
                    <p className="usa-prose">You can pay for just this registration. </p>
                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-2"></div>
                      <div className="tablet:grid-col-8"> <a href={"/account/receipt/new?registrations=" + JSON.stringify([itemZero.pk])} className="usa-button"> Pay</a></div>
                    </div>
                  </>
                }

                {itemZero &&
                  <>
                    <div className="tablet:grid-col-12">
                      <div className="usa-prose" style={sectionStyle}> Cancel your Registration</div>
                    </div>
                    <Cancel item={item} />
                  </>
                }

              </Panel>
            }

          </>
        </div >
      }
    </>
  )
}
