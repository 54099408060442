import React, { useState, useEffect } from 'react'
import { PageHeader } from './Section'
import { Link } from "react-router-dom"

import Panel from "./Panel"
import Field from "./fields/Field"
import Table from './Table'
import TableHeadings from './TableHeadings'
import TableBody from './TableBody'
import Column from './fields/Column'

export default function Home(props) {

  const { profile, form } = props
  let [registrationStatus, setRegistrationStatus] = useState({})

  useEffect(() => {
    if (props.registrationStatus && props.registrationStatus.data) {
      setRegistrationStatus(props.registrationStatus.data)
    }
  }, [props.registrationStatus])


  if (!profile || !form) { return null }
  let profileForm = form.items.filter(f => f.pkType === 'profile')
  if (profileForm.length === 0) { return null }
  const segments = profile.collection.segments

  return (
    <>
      <div style={{ width: '95%' }}>
        <PageHeader title="My Profile"
          description="" />
      </div>

      <MyProfileControls conventionActive={registrationStatus.registrationActive} />

      {segments.length > 0 && segments[0].items.length > 0 &&
        <ProfileItem item={segments[0].items[0]} form={profileForm[0]} />
      }

      {profileForm[0].childViews.map((view, i) => {
        let secondaryForm = props.form.items.filter(f => f.pk === view.pk && f.sk === view.sk)[0]

        let secondaryItem = false
        for (let segment of segments.slice(1)) {

          if (segment.items.length > 0 && segment.items[0].gsi3_sk && (segment.items[0].gsi3_sk === view.itemType || segment.items[0].gsi1_pk === view.itemType)) {
            secondaryItem = segment
          }
        }
        if (!secondaryItem) { return null }

        let secondaryView = secondaryForm.views.filter(f => f.id === view.viewId)[0]

        return (
          <Panel key={i} title={view.title}>
            <Table>
              <TableHeadings>
                <tr>
                  {secondaryView.fieldIds.map((fieldId, i) => {
                    let col = secondaryForm.fields.findIndex(field => field.fieldId === fieldId)
                    return <td key={i}>{secondaryForm.fields[col].label}</td>
                  }
                  )}
                </tr>
              </TableHeadings>
              <TableBody>
                {secondaryItem.items.map((item, j) =>
                  <tr key={j}>
                    {secondaryView.fieldIds.map((fieldId, i) => {
                      let col = secondaryForm.fields.findIndex(field => field.fieldId === fieldId)
                      return <Column key={i} item={item} column={secondaryForm.fields[col]} />
                    }
                    )}
                  </tr>
                )}
              </TableBody>
            </Table>
          </Panel>
        )
      })}
    </>
  )
}

function MyProfileControls(props) {

  const className = props.conventionActive ? "usa-button" : "usa-button usa-button--disabled"

  return (
    <div>
      <div style={{ paddingBottom: 24 }}>
        <div style={{ float: 'left' }}>
          <div style={{ paddingBottom: 12 }}>
            <div style={{ float: 'left' }}>
              <span style={{ paddingRight: 10 }}>
                <Link className={className} to="/admin/registration/new?cancel=/admin/profile">
                  Add Registration
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ProfileItem(props) {

  let { item, form } = props
  let { formTitle, fields } = form

  return (
    <Panel title={formTitle}>
      {fields.map((field, i) =>
        <div key={i} className="grid-row margin-y-1">
          {field.fieldType === 'section' ?
            <div className="tablet:grid-col-10">
              <div className="usa-prose" style={{ fontWeight: 'bold', borderBottom: 'solid', borderWidth: 'thin' }}> {field.label}</div>
            </div> :
            <>
              <div className="tablet:grid-col-2">
                <div className="usa-prose" style={{ color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: 2 }}> {field.label}</div>
              </div>
              <div className="tablet:grid-col-8">
                <Field
                  status="LOADED"
                  item={item}
                  column={field}
                  form={form}
                />
              </div>
            </>
          }
        </div>
      )}
    </Panel>
  )
}
