import React from 'react'
import Collection from '../admin/Collection'

export default function Home(props) {

  const {profile, form} = props

  if (!profile) {return null} //  || !form
  let profileForm = form.items.filter(f => f.pkType === 'profile')
  if (profileForm.length === 0) {return null}

  return (
    <>
      <Collection collection={profile.collection} status={profile.status} previousStatus={profile.previousStatus} setStatus={profile.setStatus} form={form} />
    </>
  )
}
