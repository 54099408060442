import React from 'react'

export default function BanquetChoices(props) {

  let { item, itemZero, sectionStyle } = props

  if (!item) { return null }

  const style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }

  return (
    <>

      {itemZero && (itemZero.militaryServiceRank.name || itemZero.militaryServiceStatus.name) &&
        <div className="tablet:grid-col-12">
          <div className="usa-prose" style={sectionStyle}> Military Service</div>
        </div>
      }

      {itemZero && itemZero.militaryServiceRank.name &&
        <div className="grid-row margin-y-1">
          <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Service and Rank</div></div>
          <div className="tablet:grid-col-8"> {itemZero && itemZero.militaryServiceRank.name}</div>
        </div>
      }
      {itemZero && itemZero.militaryServiceStatus.name &&
        <div className="grid-row margin-y-1">
          <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Status</div></div>
          <div className="tablet:grid-col-8"> {itemZero && itemZero.militaryServiceStatus.name}</div>
        </div>
      }

      {itemZero && itemZero.base.name &&
        <div className="grid-row margin-y-1">
          <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Base</div></div>
          <div className="tablet:grid-col-8"> {itemZero && itemZero.base.name}</div>
        </div>
      }
      {itemZero && itemZero.organization &&
        <div className="grid-row margin-y-1">
          <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Organization</div></div>
          <div className="tablet:grid-col-8"> {itemZero && itemZero.organization}</div>
        </div>
      }
      {itemZero && itemZero.role &&
        <div className="grid-row margin-y-1">
          <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Role</div></div>
          <div className="tablet:grid-col-8"> {itemZero && itemZero.role}</div>
        </div>
      }
    </>

  )
}
