import React, { useState, useEffect } from 'react'
import DatePicker from "react-date-picker"

var moment = require('moment')

export default function FieldDateTime(props) {

  let [value, setValue] = useState(props.initialValue === 'Invalid date' ? '' : props.initialValue)
  let [isValid, setValid] = useState(false)

  const { item, column,
    status,
    isColumn,
    updateField,
    editStyle, viewStyle, helpStyle } = props

  function isView() {
    return status === 'LOADED' || !column.userEditable || isColumn
  }

  useEffect(() => {
    item[column.key] = value
    if (!value && !column.required) {
      setValid(true)
    } else {
      setValid(moment(value).isValid())
    }
  }, [value])

  // Passes the valid value to the parent
  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  // sets the local value of the item
  function onChange(newValue) {
    setValue(newValue)
  }

  if (column.fieldType !== 'dateTime') { return null }

  if (isView()) {
    if (moment(value).isValid() && value !== null && typeof value !== 'undefined') {
      return <td style={viewStyle}>{moment(value).format(column.dateTimeFormat)}</td>
    }

    return <td style={viewStyle}></td>
  }

  let displayValue
  if (moment(value).isValid() && value !== null && typeof value !== 'undefined') {
    displayValue = new Date(moment(value))
  } else {
    displayValue = ''
  }
  let style = column.required && !isValid ? Object.assign({}, editStyle, { borderColor: '#b50908', borderWidth: 4, borderStyle: 'solid' }) : editStyle
  return (
    <span style={style}>
      <DatePicker value={displayValue} format="y-MM-d" onChange={onChange} />
      {column.helpText &&
        <div style={helpStyle}>
          {column.helpText}
        </div>
      }
    </span>
  )

}
