import React, { useEffect } from 'react'
import {
  useParams,
  useLocation,
  Redirect
} from "react-router-dom"

import Collection from '../admin/Collection'

export default function MembershipReceipt(props) {
  const hostname = window.location.hostname
  const { itemType } = useParams()
  const location = useLocation()
  const section = location.pathname.split('/')[1]

  let { item, membershipForm } = props

  useEffect(() => {
    console.log('useEffect', itemType, item.status, item.previousStatus)
    if (item.status === 'EDITING' && itemType === 'receipt') {
      item.setStatus('SAVING')
    }
  })

  if (itemType === 'receipt' && item.status === 'LOADED') {
    let itemZero = item.collection.segments[0].items[0]
    if (itemZero.gsi1_pk === 'receipt') {
      let redirectUrl
      if (hostname === 'www.atalink.org' ||
        hostname === 'atalink.org') {
        redirectUrl = 'https://p5ui7sdvl9.execute-api.us-east-1.amazonaws.com/master'
      } else if (hostname === 'v3.atalink.org') {
        redirectUrl = 'https://qbnorup128.execute-api.us-east-1.amazonaws.com/maina'
      } else {
        redirectUrl = 'https://4htbvql798.execute-api.us-east-1.amazonaws.com/dev'
      }

      let redirect = redirectUrl + "/checkout?receipt=" + itemZero.pk + "&membership=" + itemZero.membership.pk + '&section=' + section
      window.location.replace(redirect)
    }
  }

  return (
    <>
      {itemType === 'membership' && item.status === 'LOADED' && item.previousStatus === 'SAVING' &&
        <div>
          <i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
          <Redirect to={"/" + section + "/receipt/new?memberships=" + JSON.stringify([item.collection.segments[0].items[0].pk])} />
        </div>
      }

      <div className="grid-container margin-y-0">
        {itemType === 'receipt' && membershipForm &&
          <div><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
            <div style={{ display: 'none' }}>
              <Collection
                collection={item.collection}
                status={item.status}
                previousStatus={item.previousStatus}
                setStatus={item.setStatus}
                form={{ items: [membershipForm] }}
              />
            </div>
          </div>
        }
      </div>
    </>
  )
}
