import React from 'react';
import { matchPath, useLocation } from "react-router-dom";

export default function Header(props) {

  let location = useLocation();
  const match = matchPath(location.pathname, {
    path: "/preview/:batchId",
    exact: true,
    strict: false
  })

  if (match) { return null }

  return (
    <header className="usa-header usa-header--extended">
      <div className="usa-navbar" style={{ marginLeft: 0, marginRight: 0, height: 130 }}>
        <div className="usa-logo" id="extended-logo">
          <a href="/" title="Home" aria-label="Home">
            <img src="/images/ata-logo-top-nav.png" alt="A/TA Logo" width="50" style={{ display: 'inline-block', verticalAlign: 'text-bottom' }} />
            <em style={{ display: 'inline-block', paddingLeft: 10 }} className="usa-logo__text">
              <span className="full-heading" style={{ fontSize: '1.5rem' }}>Airlift/Tanker Association</span>
              <span className="small-heading" style={{ fontSize: '1.6rem' }}>The A/TA</span>
            </em>
          </a>
        </div>
        <button className="usa-menu-btn">Menu</button>
      </div>

      <TopMenu
        user={props.user}
        signOut={props.signOut}
      />
    </header>

  )
}

function TopMenu(props) {
  return (
    <nav
      aria-label="Primary navigation"
      className="usa-nav"
      style={{ width: 'inherit' }}
    >
      <div className="usa-nav__inner">
        <button className="usa-nav__close">
          <img src="/images/close.svg" alt="close" />
        </button>

        <SecondaryMenu
          user={props.user}
          signOut={props.signOut}
        />
      </div>
    </nav>
  )
}


function SecondaryMenu(props) {
  return (
    <div className="usa-nav__secondary">
      <ul className="usa-nav__secondary-links" style={{ marginBottom: 0 }}>

        {props.user &&
          <div className="usa-nav__secondary-item">
            <span className="usa-prose" style={{ color: 'grey', textDecoration: 'none' }}>{props.user.username}</span>
          </div>
        }

        {props.signOut &&
          <div className="usa-nav__secondary-item">
            <div className="usa-button usa-button--unstyled" onClick={() => props.signOut()}><i className="fal fa-sign-out-alt"></i></div>
          </div>
        }
      </ul>

      <form className="usa-search usa-search--small ">
        <div role="search">
          <label className="usa-sr-only" htmlFor="extended-search-field-small">
            Search small
          </label>
          <input
            className="usa-input"
            id="extended-search-field-small"
            type="search"
            name="target"
            placeholder="name or email"
          />
          <button className="usa-button" type="submit" formAction="/admin/search">
            <span className="usa-sr-only">Search</span>
          </button>
        </div>
      </form>
    </div>
  )
}
