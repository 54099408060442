import React from 'react'
//import { Hub } from 'aws-amplify'
import useList from './useList'
import useCollection from './useCollection'
import useSummary from './useSummary'
import useRegistrationStatus from './useRegistrationStatus'
import Sidebar from './Sidebar'
import Header from './Header'
import Collection from './Collection'
import Summary from './summary/Summary'
import Home from './Home'
import Search from './search/Search'
import AdminRegistration from "../convention2024/AdminRegistration"

import { Authenticator, View, Image, useTheme, ThemeProvider } from '@aws-amplify/ui-react'

import {
  useParams,
  Route,
  Switch
} from "react-router-dom";

import Grid from './Grid'

const style = {
  sidebar: {
    height: '100%',
    width: 210,
    position: 'absolute',
    left: 0,
    top: 80,
    paddingTop: 20,
    borderRightStyle: 'solid',
    borderRightColor: '#dcdee0',
    borderRightWidth: 1,
    paddingRight: 10,
    paddingLeft: 10
  },
  adminBody: {
    position: 'absolute',
    left: 200,
    top: 120,
    marginLeft: 20,
    marginRight: 20,
    display: 'inline-block'
  },
  header: {
    borderBottomStyle: 'solid',
    borderBottomColor: '#dcdee0',
    borderBottomWidth: 'thin',
  }
}

const viewProps = { stopAfterPage: 996, pageSize: 1000, source: "/list/view", items: [] }
const formProps = { stopAfterPage: 999, pageSize: 1000, source: "/list/form", items: [] }

export default function AdminRoutes() {

  const { itemType, id, subId } = useParams()
  const views = useList(viewProps)
  const form = useList(formProps)
  const entity = useCollection({ itemType, id, subId })
  const membershipCounts = useSummary({ source: '/summary/membershipCounts', items: [] })
  const registrationCounts = useSummary({ source: '/summary/registrationCounts', items: [] })
  const registrationStatus = useRegistrationStatus({ source: '/registrationStatus', items: [] })
  const profile = useCollection({ itemType: 'profile', id: 'me' })
  const { tokens } = useTheme();

  // Hub.listen('auth', (data) => { console.log(data); if (data.payload.event == 'signIn') { console.log('sign in event'); views.resetData() } });

  const theme = {
    name: 'A/TA Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.neutral['10'].value,
          },
          secondary: {
            value: '#eeeeee',
          },
        },
        font: {
          interactive: {
            value: '#b40808'
          },
        },
        brand: {
          primary: {
            '10': 'white',
            '80': '#b40808',
            '90': '#b40808',
            '100': '#b40808',
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: '#333333',
              },
            },
            _hover: {
              color: {
                value: tokens.colors.red['80'].value,
              },
            },
            _active: {
              color: {
                value: '#333333',
              },
            },
          },
        },
      },
    },
  };

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="A/TA Logo"
            src="https://atalink.org/images/ata-logo.png"
            width="25%"
          />
        </View>
      );
    },
  }

  return (
    <Switch>
      <ThemeProvider theme={theme}>
        {views.items.map((view, i) =>
          <Route key={i} exact path={"/admin" + view.route} >
            <Authenticator loginMechanisms={['email']} components={components}>
              {({ signOut, user }) => (
                <div>
                  <Header signOut={signOut} user={user} />
                  <div style={style.sidebar}>
                    <Sidebar views={views} user={user} />
                  </div>
                  <div style={style.adminBody}>
                    <Grid gridDef={view} registrationStatus={registrationStatus} />
                  </div>
                </div>
              )}
            </Authenticator>
          </Route>
        )}


        {form.items.map((view, i) =>
          <Route key={100 + i} exact path={"/admin" + view.route} >
            <Authenticator loginMechanisms={['email']} components={components}>
              {({ signOut, user }) => (
                <div>
                  <Header signOut={signOut} user={user} />
                  <div style={style.sidebar}>
                    <Sidebar views={views} user={user} />
                  </div>
                  <div style={style.adminBody}>
                    <Collection
                      collection={entity.collection}
                      refreshMembershipStatus={entity.refreshMembershipStatus}
                      alignMembershipEndDates={entity.alignMembershipEndDates}
                      proposedMembershipStatus={entity.proposedMembershipStatus}
                      saveProposedMembershipStatus={entity.saveProposedMembershipStatus}
                      registrationAction={entity.registrationAction}
                      ACTION={entity.ACTION}
                      status={entity.status}
                      previousStatus={entity.previousStatus}
                      setStatus={entity.setStatus}
                      form={form}
                      user={user}
                    />
                  </div>
                </div>
              )}
            </Authenticator>
          </Route>
        )}
        <Route key="996" exact path={"/admin/search"} >
          <Authenticator loginMechanisms={['email']} components={components}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar views={views} user={user} />
                </div>
                <div style={style.adminBody}>
                  <Search registrationStatus={registrationStatus} membershipCounts={membershipCounts} registrationCounts={registrationCounts} user={user} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>
        <Route key="997" exact path={"/admin"} >
          <Authenticator loginMechanisms={['email']} components={components}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar views={views} user={user} />
                </div>
                <div style={style.adminBody}>
                  <Summary registrationStatus={registrationStatus} membershipCounts={membershipCounts} registrationCounts={registrationCounts} user={user} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>

        <Route key="998" exact path={"/admin/convention2024/:id"} >
          <Authenticator loginMechanisms={['email']} components={components}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar views={views} user={user} />
                </div>
                <div style={style.adminBody}>
                  <AdminRegistration profile={profile} form={form} registrationStatus={registrationStatus} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>


        <Route key="999" exact path={"/admin/profile"} >
          <Authenticator loginMechanisms={['email']} components={components}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar views={views} user={user} />
                </div>
                <div style={style.adminBody}>
                  <Home profile={profile} form={form} registrationStatus={registrationStatus} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>
      </ThemeProvider>
    </Switch>
  )
}
