import React, { useEffect, useState } from 'react'
import { PageHeader } from '../Section'

import PanelMembership from './PanelMembership'
import PanelReports from './PanelReports'
import PanelRegistrations from './PanelRegistrations'
import PanelRegistrationStatus from './PanelRegistrationStatus'

export default function Summary(props) {

  //  const [groupAdmin, setGroupAdmin] = useState(false)
  const [admin, setAdmin] = useState(false)


  useEffect(() => {
    if (props.user && props.user.signInUserSession.accessToken.payload["cognito:groups"]) {
      setAdmin(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('admins'))
    }
  }, [props.user])


  return (
    <>
      {admin &&
        <>
          <div style={{ width: '95%' }}>
            <PageHeader title="Dashboard"
              description="" />
          </div>

          <PanelReports {...props} />
          <PanelRegistrationStatus registrationStatus={props.registrationStatus} />
          <PanelRegistrations registrationStatus={props.registrationStatus} registrationCounts={props.registrationCounts} />
          <PanelMembership membershipCounts={props.membershipCounts} />
        </>
      }
    </>
  )
}
