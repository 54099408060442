import React, { useState, useEffect } from 'react'

import Header from '../admin/Header'
import Sidebar from './Sidebar'
import Home from './Home'
import Registration from './Registration'
import Membership from './Membership'
import Member from './Member'
import GroupRegistration from './GroupRegistration'
import Collection from '../admin/Collection'
import useCollection from '../admin/useCollection'
import useList from '../admin/useList'
import useRegistrationStatus from '../admin/useRegistrationStatus'
import useQuery from '../admin/useQuery'

import { Authenticator, View, Image, useTheme, ThemeProvider } from '@aws-amplify/ui-react'

import {
  Route,
  Switch,
  Link,
  useLocation
} from "react-router-dom";

var moment = require('moment')

const style = {
  sidebar: {
    height: '100%',
    width: 210,
    position: 'absolute',
    left: 0,
    top: 80,
    paddingTop: 20,
    borderRightStyle: 'solid',
    borderRightColor: '#dcdee0',
    borderRightWidth: 1,
    paddingRight: 10,
    paddingLeft: 10
  },
  adminBody: {
    position: 'absolute',
    left: 200,
    top: 120,
    marginLeft: 20,
    marginRight: 20,
    display: 'inline-block'
  },
  header: {
    borderBottomStyle: 'solid',
    borderBottomColor: '#dcdee0',
    borderBottomWidth: 'thin',
  }
}

export default function Account() {

  const query = useQuery()
  const formProps = { stopAfterPage: 999, pageSize: 1000, source: "/list/form", items: [] }
  const [guestAllowed, setGuestAllowed] = useState(false)
  const [memberId, setMemberId] = useState('')
  //  const [memberFirstName, setMemberFirstName] = useState(query.get('firstName') ?? '')
  //const [memberLastName, setMemberLastName] = useState(query.get('lastName') ?? '')
  const [offerMembership, setOfferMembership] = useState(false)
  const [showForMember, setShowForMember] = useState(false)
  const [membershipButtonTitle, setMembershipButtonTitle] = useState('Renew Your Membership')
  const [offerRegistration, setOfferRegistration] = useState(false)
  const [membershipText, setMembershipText] = useState(false)
  const [parentRegistration, setParentRegistration] = useState('')
  const [registrationId, setRegistrationId] = useState(false)
  const [prefilledEmail, setPrefilledEmail] = useState("")
  const [checkoutSession] = useState(query.get('cs'))
  const [updatingMembershipStatus, setUpdatingMembershipStatus] = useState(false)
  const [initialState] = useState(query.has('signUp') ? 'signUp' : null)
  const [showGuestTable, setShowGuestTable] = useState(false)

  const registrationStatus = useRegistrationStatus({ source: '/registrationStatus' })

  const entity = {}
  const profile = useCollection({ itemType: 'profile', id: 'me' })
  const form = useList(formProps)

  let forms = []
  //  let forms = ["f.1e7dc6b2-9ae6-4cac-9e81-7234e6f9789f", "f.e71ca87f-ee36-48d1-b927-74a71e725d09", "f.b1b5ca23-81e0-4a83-9f80-65e259f735c8"]
  let accountForm = []
  if (form.items.length > 0) {
    accountForm = form.items.filter(item => forms.includes(item.pk))
  }

  let className = 'usa-button usa-button--disabled'
  let classNameDisabled = 'usa-button usa-button--disabled'
  let classNameActive = 'usa-button'

  useEffect(() => {
    if (!checkoutSession || profile.status !== 'LOADED') { return }

    if (!updatingMembershipStatus) {
      setUpdatingMembershipStatus(true)
      profile.refreshMembershipStatus()
    }
    console.log('force membership status update', updatingMembershipStatus)
  }, [checkoutSession, profile])

  // useEffect(() => {
  //   if (useAuthenticator && query.has("signUp")) {
  //     useAuthenticator.toSignUp()
  //   }
  // }, [useAuthenticator])

  useEffect(() => {
    if (profile.status !== 'LOADED') { return }
    let allowed = false
    let hasRegistration = false
    //    let hasGuest = false
    for (let segment of profile.collection.segments) {
      for (let item of segment.items) {
        if (item.gsi1_pk === 'registration:primary:complete') {
          setOfferRegistration(false)
          setRegistrationId(item.pk)
          hasRegistration = true
        }
        if (item.gsi1_pk === 'registration:primary:complete' && item.registrationType.guestsAllowed) {
          allowed = true
          setParentRegistration(item.pk)
        }
        // if (item.gsi2_pk && item.gsi2_pk.slice(0, 1) === 'r' && item.gsi1_pk === 'registration:primary:complete') { // could be a member so check it is a parent registration
        //   hasGuest = true
        // }
      }
      if (!hasRegistration) {
        setOfferRegistration(true)
        setRegistrationId(false)
      }

      // This is the memberId (or fake one if there is no member). Better
      // to look for this based on the itemType. Too much chance of this failing.
      // Warning: This is used in the client_reference_id query parameter to link the
      // checkout session to the current member. Stripe does not allow dots (.) in the
      // value so must be stripped out before sending.
      setMemberId(profile.collection.segments[5].items[0].pk)
    }

    setGuestAllowed(allowed)
    // Changed to allow multiple guests for all registrations that allow a guest.
    // setGuestAllowed(allowed && !hasGuest)

    // Decide if we should offer membership
    if (profile.collection.segments[5].items[0].mostRecentMembershipType === 'Life Membership') {
      setOfferMembership(false)
      setShowForMember(true)
    } else {
      if (profile.collection.segments && profile.collection.segments[0].items[0]) {
        setPrefilledEmail(profile.collection.segments[0].items[0].pk)
      }

      if (profile.collection.segments[5].items[0].hasGraceMembership ||
        profile.collection.segments[5].items[0].hasExpiredMembership) {
        setMembershipButtonTitle('Renew Your Membership')
        setOfferMembership(true)
      } else if (profile.collection.segments[5].items[0].hasNeverBeenMember) {
        setMembershipButtonTitle('Become a Member')
        setOfferMembership(true)
      } else if (profile.collection.segments[5].items[0].hasActiveMembership) {
        if (moment(profile.collection.segments[5].items[0].mostRecentMembershipEndDate).isValid()) {
          if (moment(registrationStatus.data.validConventionMembershipDate).isBefore(moment(profile.collection.segments[5].items[0].mostRecentMembershipEndDate))) {
            setShowForMember(true)
          }
          if (moment().add('3', 'months').isAfter(moment(profile.collection.segments[5].items[0].mostRecentMembershipEndDate))) {
            setMembershipButtonTitle('Renew Your Membership')
            setOfferMembership(true)
          } else if (moment().add('6', 'months').isAfter(moment(profile.collection.segments[5].items[0].mostRecentMembershipEndDate))) {
            setMembershipButtonTitle('Renew Your Membership Early')
            setOfferMembership(true)
          } else {
            setMembershipButtonTitle('Renew Your Membership Early')
            setOfferMembership(false)
          }
        } else {
          setMembershipButtonTitle('Buy a Membership')
          setOfferMembership(true)
        }
      } else {
        setMembershipButtonTitle('Buy a Membership')
        setOfferMembership(true)
      }
    }

    setMembershipText((profile.collection.segments[5].items[0].mostRecentMembershipType ?? 'No membership') +
      ' ' +
      (profile.collection.segments[5].items[0].mostRecentMembershipEndDate ?
        'ends ' + profile.collection.segments[5].items[0].mostRecentMembershipEndDate.substring(0, 10) :
        '') + ' ' +
      (profile.collection.segments[5].items[0].overlappingIpMembership ?
        ' plus an active Individual Corporate Membership with ' +
        profile.collection.segments[5].items[0].company :
        ''))


  }, [profile])

  if (profile.status === 'LOADED') {
    if (guestAllowed) { className = 'usa-button' }
  }

  const formFields = {
    signUp: {
      email: {
        label: 'Email',
        placeholder: 'Enter Your Email',
        isRequired: true,
        value: query.get('emailAddress'),
      },
      given_name: {
        label: 'First Name',
        placeholder: 'Enter Your First Name',
        isRequired: true,
        value: query.get('firstName')
      },
      family_name: {
        label: 'Last Name',
        placeholder: 'Enter Your Last Name',
        isRequired: true,
        value: query.get('lastName')
      }

    }
  }

  const { tokens } = useTheme();

  const theme = {
    name: 'A/TA Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: tokens.colors.neutral['10'].value,
          },
          secondary: {
            value: '#eeeeee',
          },
        },
        font: {
          interactive: {
            value: '#b40808'
          },
        },
        brand: {
          primary: {
            '10': 'white',
            '80': '#b40808',
            '90': '#b40808',
            '100': '#b40808',
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: '#333333',
              },
            },
            _hover: {
              color: {
                value: tokens.colors.red['80'].value,
              },
            },
            _active: {
              color: {
                value: '#333333',
              },
            },
          },
        },
      },
    },
  };

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="A/TA Logo"
            src="https://atalink.org/images/ata-logo.png"
            width="25%"
          />
        </View>
      );
    },
    SignUp: {
      Footer() {
        return (
          <View textAlign="center" style={{ paddingBottom: 10, marginTop: -10 }}>
            {query.get('emailAddress') && <span> Do not change your email address.</span>}
          </View>
        );

      }
    }
  }

  let location = useLocation()

  return (
    <Switch>
      <ThemeProvider theme={theme}>
        <Route key={101} exact path="/account" >
          <Authenticator
            loginMechanisms={['email']}
            signUpAttributes={['given_name', 'family_name']}
            formFields={formFields}
            components={components}
            initialState={initialState}
          >
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar user={user} />
                </div>


                <div style={style.adminBody}>
                  {checkoutSession &&
                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-10" >
                        <div className="usa-alert usa-alert--warning" style={{ marginTop: 20 }}>
                          <div className="usa-alert__body">
                            <h4 className="usa-alert__heading">Thank you for being a member</h4>
                            <p className="usa-alert__text">
                              Thank you for buying a membership to the ATA. An email receipt is on the way.
                            </p>
                            <p className="usa-alert__text">
                              If your circumstances have changes - for example rank, base or contact information - please <Link to="/account/profile">update your information</Link>.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {offerMembership && registrationStatus && offerRegistration && registrationStatus.data && registrationStatus.data.registrationActive &&
                    <div className="grid-row margin-y-1">
                      <div className="tablet:grid-col-10" >
                        <div className="usa-alert usa-alert--warning" style={{ marginTop: 20 }}>
                          <div className="usa-alert__body">
                            <h4 className="usa-alert__heading">Convention Registration is Cheaper for Members!</h4>
                            <p className="usa-alert__text">
                              Becoming a member of A/TA has some great benefits. But right now, becoming a member or renewing your membership will
                              ensure you get the lowest price for your registration. Every registration is $50 cheaper for members and memberships start
                              at only $40 for a one year membership.
                            </p><p className="usa-alert__text" style={{ marginTop: 20 }}>
                              Become a member or renew your membership <strong>before</strong> registering.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-6" >
                      <h2>Your Membership</h2>
                      <p className="usa-prose" style={{ marginTop: 0 }}>You can support the Association by becoming or extending your membership. Read more about the <a href="/become-a-member">full benefits</a> available. </p>
                      {membershipText &&
                        <>
                          <p className="usa-prose"><strong>Membership</strong>: {membershipText}</p>
                        </>
                      }
                      {registrationStatus.data.stripeMembershipLinks &&
                        registrationStatus.data.stripeMembershipLinks.map(item => {
                          return (
                            <div key={item.name} style={{ paddingBottom: 20 }}>
                              <a className={offerMembership ? classNameActive : classNameDisabled}
                                href={"https://buy.stripe.com/" + item.paymentLink + "?prefilled_email=" + prefilledEmail + " &client_reference_id=" + memberId.substring(2)}>{membershipButtonTitle} - {item.name} </a>
                            </div>
                          )
                        })
                      }
                    </div>
                    <div className="tablet:grid-col-6">
                      {!offerRegistration &&
                        <>
                          <h2>Your Registration</h2>

                          <p className="usa-prose" style={{ marginTop: 0 }}>Manage your registration, including updating your banquet preferences, customizing your badge, and canceling your registration. </p>
                          <Link to={"/individual/registration/" + registrationId} className={(offerRegistration || !registrationId) ? classNameDisabled : classNameActive}>Manage Your Registration</Link>

                          <p className="usa-prose" style={{ marginTop: 30 }}>Add a guest to your registration. Use <Link to="/account/profile">My Profile</Link> to review your current guest registrations.
                            If you need assistance with your guest registrations, please contact ata@atalink.org.</p>

                          <div className={className} onClick={() => setShowGuestTable(!showGuestTable)}>
                            {showGuestTable ? <>Hide Guest Registration Options</> : <>Register a Guest</>}
                          </div>
                        </>
                      }

                    </div>
                  </div>

                  {showGuestTable && registrationStatus.data && registrationStatus.data.stripePricingTables && registrationStatus.data.stripePricingTables[4] &&
                    <>
                      <hr style={{
                        marginTop: 20
                      }} />
                      <h2>Guest Registrations Options</h2>
                      <stripe-pricing-table pricing-table-id={registrationStatus.data.stripePricingTables[4]}
                        publishable-key={registrationStatus.data.stripePublicKey}
                        client-reference-id={parentRegistration ? parentRegistration.split('.')[1] : ''}
                        customer-email={user.attributes.email}
                      >
                      </stripe-pricing-table>
                    </>
                  }

                  {registrationStatus.data && registrationStatus.data.registrationActive &&
                    <>
                      {registrationStatus.data.stripePricingTables[2] && showForMember && offerRegistration &&
                        <>
                          <hr />
                          <h2>Convention {registrationStatus.data.registrationYear} Registration</h2>
                          {registrationStatus.data.stripeLink2 &&
                            <div className="grid-row margin-y-1">
                              <div className="tablet:grid-col-4">
                                <p>Foreign Military? <a href={registrationStatus.data.stripeLink2}>Register here</a>. </p>
                              </div>
                            </div>
                          }
                          <stripe-pricing-table pricing-table-id={registrationStatus.data.stripePricingTables[2]}
                            publishable-key={registrationStatus.data.stripePublicKey}
                            customer-email={user.attributes.email}
                          >
                          </stripe-pricing-table>
                        </>
                      }
                      {registrationStatus.data.stripePricingTables[3] && !showForMember && offerRegistration &&
                        <>
                          <hr />

                          <h2>Convention {registrationStatus.data.registrationYear} Registration</h2>
                          {registrationStatus.data.stripeLink3 &&
                            <div className="grid-row margin-y-0">
                              <div className="tablet:grid-col-4">
                                <p style={{ marginTop: 0 }}>Foreign Military? <a href={registrationStatus.data.stripeLink3}>Register here</a>. </p  >
                              </div>
                            </div>
                          }
                          <stripe-pricing-table pricing-table-id={registrationStatus.data.stripePricingTables[3]}
                            publishable-key={registrationStatus.data.stripePublicKey}
                            customer-email={user.attributes.email}
                          >
                          </stripe-pricing-table>
                        </>
                      }

                      <div className="grid-row margin-y-3">
                        <div className="tablet:grid-col-3" ></div>
                        <div className="tablet:grid-col-4">
                          <img src="/images/logo2024.png" />
                        </div>
                      </div>


                    </>
                  }

                </div>
              </div>
            )}
          </Authenticator>
        </Route>
        <Route key={100} exact path="/account/groups" >
          <Authenticator loginMechanisms={['email']}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar user={user} />
                </div>
                <div style={style.adminBody}>
                  <GroupRegistration profile={profile} form={form} registrationStatus={registrationStatus} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>
        <Route key={102} exact path="/account/profile" >
          <Authenticator loginMechanisms={['email']}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar user={user} />
                </div>
                <div style={style.adminBody}>
                  <Home profile={profile} form={form} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>
        <Route key={103} exact path="/account/:itemType" >
          {location.pathname != "/account/profile" &&
            <Authenticator loginMechanisms={['email']}>
              {({ signOut, user }) => (
                <div>
                  <Header signOut={signOut} user={user} />
                  <div style={style.sidebar}>
                    <Sidebar user={user} />
                  </div>
                  <div style={style.adminBody}>
                    <Membership />
                    <Registration guestAllowed={guestAllowed} user={user} />
                  </div>
                </div>
              )}
            </Authenticator>
          }
        </Route>
        <Route key={104} exact path="/account/:itemType/:id" >
          <Authenticator loginMechanisms={['email']}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar user={user} />
                </div>
                <div style={style.adminBody}>
                  <Member />
                  <Membership />
                  <Registration user={user} guestAllowed={guestAllowed} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>
        <Route key={101} exact path="/account/:itemType/:id/:subId" >
          <Authenticator loginMechanisms={['email']}>
            {({ signOut, user }) => (
              <div>
                <Header signOut={signOut} user={user} />
                <div style={style.sidebar}>
                  <Sidebar user={user} />
                </div>
                <div style={style.adminBody}>
                  <Collection collection={entity.collection} status={entity.status} previousStatus={entity.previousStatus} setStatus={entity.setStatus} form={form} user={user} />
                </div>
              </div>
            )}
          </Authenticator>
        </Route>
        {accountForm.map((view, i) =>
          <Route key={100 + i} exact path={"/account" + view.route} >
            <Authenticator loginMechanisms={['email']}>
              {({ signOut, user }) => (
                <div>
                  <Header signOut={signOut} user={user} />
                  <div style={style.sidebar}>
                    <Sidebar user={user} />
                  </div>
                  <div style={style.adminBody}>
                    <Collection collection={entity.collection} status={entity.status} previousStatus={entity.previousStatus} setStatus={entity.setStatus} form={form} user={user} />
                  </div>
                </div>
              )}
            </Authenticator>
          </Route>
        )}
      </ThemeProvider>
    </Switch >
  )
}
