import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { useLocation } from "react-router-dom"
import { isValidEmail } from '../common'
import useQuery from '../useQuery'

export default function FieldEmail(props) {

  let [value, setValue] = useState(props.initialValue)
  let [isValid, setValid] = useState(false)
  let [isValidConventionMembership, setIsValidConventionMembership] = useState(false)
  let [validationMessage, setValidationMessage] = useState('Your email address has not been matched to a membership.')
  let [isSearching, setIsSearching] = useState(false)

  const { item, column,
    status,
    isColumn,
    updateField,
    user,
    editStyle, viewStyle, helpStyle } = props

  const query = useQuery()
  let location = useLocation()
  let isAdmin = location.pathname.split('/')[1] === 'admin'

  function isView() {
    if (!query.get('parentRegistration') && !isAdmin && user) {
      return true
    }
    return status === 'LOADED' || !column.userEditable || isColumn
  }
  useEffect(() => {
    if (!value && user && user.attributes.email && !isAdmin && column.key === 'primaryEmail') {
      value = user.attributes.email
      // If there is already a related registration, then don't try and add membership information
      if (!item.gsi2_pk || item.gsi2_pk.slice(0, 2) !== 'r.') {
        searchForMembership()
      }
    }
    item[column.key] = value

    const isValidValue = value ?? ''

    if (column.fieldType === 'email' && column.maxLength) {
      if (isValidEmail(isValidValue) || isValidValue.length === 0) {
        setValid(!(column.required && isValidValue.length === 0 && isValidValue.length <= column.maxLength))
      } else {
        setValid(false)
      }
    } else {
      setValid(!(column.required && isValidValue.length === 0))
    }
  }, [value])

  // Passes the valid value to the parent
  useEffect(() => {
    if (updateField) { updateField(column.key, isValid) }
  }, [isValid])

  useEffect(() => {
    if (props.column.fieldType !== 'email') { return }

    if (isView()) { return }

    const isValidValue = value ?? ''
    if (isValidValue.length === 0) {
      setValidationMessage(false)
    }

    setIsValidConventionMembership(false)
    //    setValidationMessage('Your email address has not been matched to a membership. If you believe you have an active membership, pleasae contact ata@atalink.org')

  }, [value])

  function searchForMembership() {
    if (isSearching) { return }
    setIsSearching(true)

    API.get('membership', '/membership/' + value)
      .then((data) => {
        setIsValidConventionMembership(data.isValidConventionMembership)

        item.isValidConventionMembership = data.isValidConventionMembership
        item.gsi2_pk = data.pk

        if (data.isValidConventionMembership) {
          setValidationMessage('Your registration will be associated with your active membership. You will be charged the lower member rate.')
          setIsSearching(false)
        } else {
          if (data.isExpiredMember) {
            setValidationMessage('We found your membership but it has expired. You will be charged the non-member rate. If you believe you have an active membership, pleasae contact ata@atalink.org')
          } else {
            setValidationMessage('Your email is not associated with a membership. You will be charged the non-member rate. If you believe you have an active membership, pleasae contact ata@atalink.org')
          }
          setIsSearching(false)
        }

      })
      .catch(error => {
        console.log(error)
        setValid(false)
        setValidationMessage('Could not match email address to an active member. If you believe you have an active membership, pleasae contact ata@atalink.org')
        setIsSearching(false)
      })
  }

  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.value.toLowerCase())
  }

  if (isView()) {
    return <td style={viewStyle}>{item[column.key]}</td>
  }

  let className = isValid ? 'usa-input' : 'usa-input usa-input--error'

  return (
    <div style={{ marginTop: 5 }}>
      <input
        className={className}
        id={column.key}
        name="input-type-text"
        type="email"
        maxLength={column.maxLength}
        required={column.required}
        style={editStyle}
        value={value}
        onChange={onChange}
      />
      {!column.removeMembershipButton && (!query.get('parentRegistration') && isAdmin && user) &&
        <>
          <button className="usa-button" style={{ textDecoration: 'none', fontSize: 'small', marginTop: 4 }} disabled={isSearching || !isValid || isValidConventionMembership} onClick={searchForMembership}>Find Membership</button>  {isSearching ? <i className="fas fa-circle-notch fa-spin"></i> : <span></span>}
          {column.helpText &&
            <div style={helpStyle}>
              {column.helpText} <br />
              {validationMessage}
            </div>
          }
        </>
      }
    </div>
  )
}
