import React, { useState, useEffect } from 'react'

export default function Cancel(props) {

  let { item } = props
  const [cancellationStatus, setCancellationStatus] = useState(false)
  const [requestDate, setRequestDate] = useState(false)

  const style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }

  function handleRequestCancellation() {
    item.registrationAction(item.ACTION.REQUEST_CANCELLATION)
  }

  useEffect(() => {
    let cancellationStatus = 'Active'
    let itemZero = false
    try {
      itemZero = item.collection.segments[0].items[0]
    }
    catch {
      itemZero = false
    }

    if (itemZero) {
      if (item.collection.segments[0].items[0].gsi1_pk === 'registration:primary:cancellationRequested') {
        cancellationStatus = 'Requested'
      }
      if (item.collection.segments[0].items[0].gsi1_pk === 'registration:primary:cancelled') {
        cancellationStatus = 'Canceled'
      }
      setCancellationStatus(cancellationStatus)
      setRequestDate(itemZero.cancellationRequestedDate)
    }
  }, [item])

  if (!item) { return null }

  return (
    <>
      {cancellationStatus === 'Active' &&
        <>
          <div className="usa-prose margin-y-1">
            Cancelations incur a fee of up to $70. Requesting a cancelation of your registration does not affect your hotel or other travel arrangements. You are responsible for canceling those.
          </div>
          <div className="grid-row margin-y-1">
            <div className="tablet:grid-col-2"> </div>
            <div className="tablet:grid-col-8">
              <button className="usa-button" style={{ paddingLeft: 20, textDecoration: 'none' }} onClick={handleRequestCancellation}>Request Cancelation</button>
            </div>
          </div>
          <div className="usa-prose margin-y-1">
            Requesting a cancelation will affect your registration and any guests that you have invited.
          </div>

        </>
      }
      {cancellationStatus !== 'Active' &&
        <>
          <div className="usa-prose margin-y-1">
            Cancelations incur a fee of up to $70. We will let you know when your cancelation request is processed.
          </div>
          <div className="grid-row margin-y-1">
            <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Cancelation Status</div></div>
            <div className="tablet:grid-col-8"> {cancellationStatus}</div>
          </div>
          <div className="grid-row margin-y-1">
            <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Cancelation Requested</div></div>
            <div className="tablet:grid-col-8"> {requestDate}</div>
          </div>
        </>
      }

    </>

  )
}
