
import React, { useState } from 'react';
import { useLocation, Redirect, Link } from "react-router-dom";

export default function EntityControl(props) {

  const { item, status,
    saveSuccessful,
    setStatus,
    refreshMembershipStatus,
    alignMembershipEndDates,
    registrationAction,
    ACTION,
    section } = props

  let saveButtonLabel = (section === '/individual') ? 'Save' : 'Save'
  let showCancelButton = (section !== '/individual')
  let [redirectCancel, setRedirectCancel] = useState(false)

  const pathname = location.pathname.split('/')
  let memberAddButton = null
  let registrationStatusCancelled = null
  let registrationStatusComplete = null
  let registrationStatusRequestCancel = null

  const search = useLocation().search;
  const cancelUrl = new URLSearchParams(search).get('cancel');

  function handleCancel() {
    if (cancelUrl) {
      setRedirectCancel(true)
    }
    setStatus('LOADED')
  }

  if (pathname[1] === 'admin' && pathname[2] === 'member') {

    let pathname3 = pathname[3]
    try {
      pathname3 = item.pk
    } catch (error) {
      pathname3 = pathname[3]
    }

    memberAddButton =
      <a
        className="usa-tooltip usa-button"
        title="Add Membership"
        href={"/admin/membership/new?member=" + pathname3}
      >
        Add Membership <i className="far fa-user-plus"></i>
      </a>
  }

  let memberUpdateEndDateButton = null
  if (pathname[1] === 'admin' && pathname[2] === 'member') {
    memberUpdateEndDateButton =
      <button
        className="usa-tooltip usa-button"
        title="Refresh Membership Status"
        onClick={() => refreshMembershipStatus()}
      >
        Refresh Membership Status <i className="far fa-calendar"></i>
      </button>
  }

  let registerMember = null
  if (pathname[1] === 'admin' && pathname[2] === 'member') {
    registerMember =
      <Link
        className="usa-tooltip usa-button"
        title="Register Member for Convention"
        to={"/admin/convention2024/" + item.pk + "?primaryEmail=" + encodeURIComponent(encodeURIComponent(item.primaryEmail)) + "&fullName=" + encodeURIComponent(item.fullName)}
      >
        Register for Convention <i className="far fa-podium"></i>
      </Link>
  }

  if (pathname[1] === 'admin' && pathname[2] === 'company') {
    memberUpdateEndDateButton =
      <button
        className="usa-tooltip usa-button"
        title="Align Corporate Individual Membership End Dates"
        onClick={() => alignMembershipEndDates()}
      >
        Align Corp Membership End Dates <i className="far fa-calendar"></i>
      </button>
  }

  const disableOnNew = (pathname[3] === 'new') ? '  usa-button--disabled' : ''

  if (pathname[1] === 'admin' && pathname[2] === 'registration') {
    registrationStatusCancelled =
      <button
        className={"usa-tooltip usa-button " + disableOnNew}
        title="Mark as Cancelled"
        onClick={() => registrationAction(ACTION.REGISTRATION_STATUS, { status: 'registration:primary:cancelled', statusName: 'Cancelled' })}
      >
        Mark as Cancelled <i className="far fa-calendar-times"></i>
      </button>
  }
  if (pathname[1] === 'admin' && pathname[2] === 'registration') {
    registrationStatusComplete =
      <button
        className={"usa-tooltip usa-button " + disableOnNew}
        title="Mark as Complete"
        onClick={() => registrationAction(ACTION.REGISTRATION_STATUS, { status: 'registration:primary:complete', statusName: 'Complete' })}
      >
        Mark as Complete <i className="far fa-calendar-check"></i>
      </button>
  }
  if (pathname[1] === 'admin' && pathname[2] === 'registration') {
    registrationStatusRequestCancel =
      <button
        className={"usa-tooltip usa-button " + disableOnNew}
        title="Mark as Cancellation Requested"
        onClick={() => registrationAction(ACTION.REGISTRATION_STATUS, { status: 'registration:primary:cancellationRequested', statusName: 'Cancellation Requested' })}
      >
        Mark as Cancellation Requested <i className="far fa-calendar-exclamation"></i>
      </button>
  }

  if (redirectCancel) {
    return <Redirect to={cancelUrl} />
  }

  return (
    <div>
      <div style={{ paddingBottom: 26 }}>
        <div style={{ float: 'left' }}>
          {status === 'LOADED' && pathname[1] !== 'admin' && pathname[2] !== 'profile' &&
            <div style={{ paddingBottom: 12 }}>
              <div style={{ float: 'left' }}>
                <span style={{ paddingRight: 10 }}>
                  <button
                    className={"usa-tooltip usa-button" + disableOnNew}
                    title="Edit"
                    onClick={() => setStatus('EDITING')}
                  >
                    Edit <i className="far fa-pencil"></i>
                  </button>
                </span>
              </div>
            </div>
          }

          {status === 'LOADED' && pathname[1] === 'admin' && pathname[2] !== 'user' &&
            <div style={{ paddingBottom: 12 }}>
              <div style={{ float: 'left' }}>
                <span style={{ paddingRight: 10 }}>
                  <button
                    className="usa-tooltip usa-button"
                    title="Edit"
                    onClick={() => setStatus('EDITING')}
                  >
                    Edit <i className="far fa-pencil"></i>
                  </button>
                  <button
                    className={"usa-tooltip usa-button" + disableOnNew}
                    title="Delete"
                    onClick={() => setStatus('delete')}
                  >
                    Delete <i className="far fa-trash"></i>
                  </button>
                  {memberAddButton}
                  {memberUpdateEndDateButton}
                  {registrationStatusComplete}
                  {registrationStatusRequestCancel}
                  {registrationStatusCancelled}
                  {registerMember}
                </span>
              </div>
            </div>
          }
          {(status === 'SAVING') &&
            <div style={{ paddingBottom: 12 }}>
              <div style={{ float: 'left' }}>
                <span style={{ paddingRight: 10 }}>
                  <button
                    className="usa-tooltip usa-button"
                    title="Save"
                    disabled
                    onClick={() => setStatus('SAVING')}
                  >
                    {saveButtonLabel} <i className="far fa-check"></i>
                  </button>
                </span>
                <span>
                  {showCancelButton &&
                    <button
                      className="usa-tooltip usa-button"
                      title="Edit"
                      disabled
                      onClick={() => setStatus('LOADED')}
                    >
                      Cancel <i className="far fa-times"></i>
                    </button>
                  }
                </span>
                <span> Saving...</span>
              </div>
            </div>
          }
          {(status === 'EDITING') &&
            <div style={{ paddingBottom: 12 }}>
              <div style={{ float: 'left' }}>
                <span style={{ paddingRight: 10 }}>
                  <button
                    className="usa-tooltip usa-button"
                    title="Save"
                    disabled={!props.isValid}
                    onClick={() => setStatus('SAVING')}
                  >
                    {saveButtonLabel} <i className="far fa-check"></i>
                  </button>
                </span>
                <span>
                  {showCancelButton &&
                    <button
                      className="usa-tooltip usa-button"
                      title="Edit"
                      onClick={handleCancel}
                    >
                      Cancel <i className="far fa-times"></i>
                    </button>
                  }
                </span>
                {props.isValid}
                {saveSuccessful === false &&
                  <span> The server request failed. Try again. </span>
                }
              </div>
            </div>
          }

        </div>
        <div style={{ float: 'right' }}>

        </div>
      </div>
    </div>
  )
}
