import React, { useEffect, useState } from 'react'

export default function FieldRegistrationPrice(props) {

  // When the registrationType is updated in the item, copy the price from
  // there.
  // To Do: update after a coupon selection
  let [value, setValue] = useState(props.registrationType.price)

  useEffect(() => {
    if (props.item.registrationType.pk) {
      props.item[props.column.key] = props.registrationType.price
      props.item[props.column.relatedPriceField] = props.registrationType.price
      if (props.item.coupon.pk) {props.item.price = 0}
      setValue(props.registrationType.price)
    }
  }, [props.updatedAt])

  // Never editable, so only a display view is required
  return <td style={props.viewStyle}>${value}</td>
}

// props.item[props.column.key]
