
import { React, useState, useEffect } from 'react'
import { API, Hub } from 'aws-amplify'
import { Link } from "react-router-dom"

import Loading from './Loading'

export default function IndustryPartnerList() {

  Hub.listen('auth', listener)
//  const [authState, setAuthState] = useState()
  const [service, setService] = useState('listUnauth')
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState([])

  function listener(data) {
    if (data.payload.event === 'signIn') {
      setService('list')
    } else {
      setService('listUnauth')
    }
  }

  useEffect(() => {
    if (!service) {return}
    API.get(service, '/list/company:industryPartner')
    .then(response => {
      setIsLoaded(true)
      const itemsSorted = response.items.sort((a, b) => {
        if (a.name < b.name) {return -1}
        if (a.name > b.name) {return 1}
        return 0
      })
      setItems(itemsSorted)
    })
    .catch((error) => {
      console.log(error)
      setIsLoaded(false)

    })
  }, [service])


  if (!isLoaded) {
    return <Loading title=""  subtitle=""/>;
  }

  const regex = / /g
  return (
    <section className="usa-section" style={{paddingTop: 10}}>
      <div className="grid-container margin-y-0">
        {items.map(partner =>
          <h3 key={partner.pk} >
            <Link
              to={"/industry-partners/partners/" + partner.name.toLowerCase().replace(regex, '-')}
              title="Industry Partners"
              aria-label="industry-partner"
              style={{textDecoration: 'none'}}
            >
              {partner.name}
            </Link>
          </h3>
        )}
      </div>
    </section>
  )


}
