import React, { useState, useEffect } from 'react';
import { PageHeader } from './Section'
import EntityControl from './EntityControl'
import Panel from "./Panel"
import Field from "./fields/Field"
import {
  useLocation,
  Redirect,
} from "react-router-dom";
import useQuery from '../admin/useQuery'

var moment = require('moment')

export default function Item(props) {

  let [fieldIsValid, setFieldIsValid] = useState({})
  let [itemIsValid, setItemIsValid] = useState(true)
  let [updatedAt, setUpdatedAt] = useState(false)
  const query = useQuery()

  useEffect(() => {
    let isValid = true
    for (const [key] of Object.entries(fieldIsValid)) {
      //     console.log('key', key, fieldIsValid[key])
      if (!fieldIsValid[key]) {
        isValid = false
      }
    }
    //  console.log("item valid", isValid)
    setItemIsValid(isValid)

  }, [fieldIsValid, updatedAt])

  function updateField(key, isValid) {

    fieldIsValid[key] = isValid
    setFieldIsValid(fieldIsValid)
    setUpdatedAt(moment().format('x'))
  }

  let { item,
    form,
    index,
    status,
    previousStatus,
    setStatus,
    noRedirect,
    saveSuccessful,
    ACTION,
    registrationAction,
    user,
    alignMembershipEndDates, refreshMembershipStatus, proposedMembershipStatus, saveProposedMembershipStatus } = props

  let formTitle = index === 1 ? form.formTitle : "General"

  let visibleFields = form.fields.filter(field => isVisible(index, field.visibleWhenChild, field.isHidden))

  const location = useLocation()
  const section = '/' + location.pathname.split('/')[1]

  if (status === 'LOADED' && previousStatus === 'SAVING' && location.search) {
    // This means that we just saved a child registration and we should
    // redirect back to the parent

    const parentRegistration = (query.get('parentRegistration') ?? false)
    const registrations = (query.get('registrations') ?? false)
    if (parentRegistration && !noRedirect) {
      return <Redirect push={true} to={section + "/registration/" + parentRegistration} />
    }
    if (registrations && !noRedirect) {
      return <Redirect push={true} to={"/individual/receipt/" + item.pk} />
    }
  }


  return (
    // This is used (I think) when segment zero has more than one entry
    // This happens when there are multiple 'tables' with the same
    // primary key and different sort keys. The only place that that appears
    // to be happening now is associate/membership
    <>
      {index === 0 &&
        <>
          {proposedMembershipStatus &&
            <div className="usa-alert usa-alert--success" style={{ marginTop: 20 }}>
              <div className="usa-alert__body">
                <h4 className="usa-alert__heading">The recalculated membership status will be as below. Click Update to confirm the changes.</h4>
                <p className="usa-alert__text">
                  Membership: {proposedMembershipStatus.proposed.mostRecentMembershipType}
                </p>
                <p className="usa-alert__text">
                  End Date: {moment(proposedMembershipStatus.proposed.mostRecentMembershipEndDate).format('YYYY-MM-DD')}
                </p>
                <p className="usa-alert__text">
                  Status: {proposedMembershipStatus.proposed.gsi1_pk}
                </p>
                <p className="usa-alert__text">
                  Company: {proposedMembershipStatus.proposed.company.name ?? 'None'}
                </p>
                <p className="usa-alert__text">
                  Overlapping Membership: {proposedMembershipStatus.proposed.overlappingIpMembership ? 'Yes' : 'No'}
                </p>
                <button
                  style={{ marginTop: 10 }}
                  className="usa-tooltip usa-button"
                  title="Refresh Membership Status"
                  onClick={() => saveProposedMembershipStatus()}
                >
                  <i className="far fa-calendar"></i> Confirm Update
                </button>
              </div>
            </div>
          }
          <PageHeader title={form.formTitle}
            description={form.formDescription} />
          <EntityControl
            item={item}
            form={form}
            saveSuccessful={saveSuccessful}
            alignMembershipEndDates={alignMembershipEndDates}
            refreshMembershipStatus={refreshMembershipStatus}
            status={status}
            setStatus={setStatus}
            isValid={itemIsValid}
            section={section}
            ACTION={ACTION}
            registrationAction={registrationAction}
          />
          {props.introductionText &&
            <div style={{ paddingTop: 10, marginTop: 10 }}>
              <Panel>
                {props.introductionText}
              </Panel>
            </div>
          }
        </>
      }

      <Panel title={formTitle}>
        {visibleFields.map((field, i) =>
          <div key={i} className="grid-row margin-y-1">
            {field.fieldType === 'section' ?
              <div className="tablet:grid-col-12">
                {!isHidden(field, item) &&
                  <div className="usa-prose" style={{ fontWeight: 'bold', borderBottom: 'solid', borderWidth: 'thin' }}> {field.label}</div>
                }
              </div> :
              <>
                <div className="tablet:grid-col-2">
                  {!isHidden(field, item) &&
                    <div className="usa-prose" style={{ color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: 2 }}> {field.label}<IsRequired status={status} column={field} /></div>
                  }
                </div>
                <div className="tablet:grid-col-8">
                  <Field
                    status={status}
                    item={item}
                    column={field}
                    form={form}
                    user={user}
                    updateField={updateField}
                    updatedAt={updatedAt}
                  />
                </div>
              </>
            }
          </div>
        )}
      </Panel>
      <div style={{ paddingTop: 20, paddingBottom: 40 }}>
        <EntityControl
          item={item}
          form={form}
          saveSuccessful={saveSuccessful}
          alignMembershipEndDates={alignMembershipEndDates}
          refreshMembershipStatus={refreshMembershipStatus}
          status={status}
          setStatus={setStatus}
          isValid={itemIsValid}
          section={section}
          ACTION={ACTION}
          registrationAction={registrationAction}
        />
      </div >
    </>
  )
}

function isVisible(index, visibleWhenChild, isHidden) {
  // Index represents the position on the screen of this item. If the item is
  // the primary, then index will be zero and we should display all the fields.
  // To Do: This implementation is fine when there is only one parent for a
  // sub item, but if there are two such as chapter and member for an official
  // sometimes you want to display just the member name, and sometimes you
  // want to display just the chapter name. This implementation will not support
  // that choice.

  // Later note, this supports the case where a child object has the same pk
  // as the parent. All of those have been removed, so this functionality can
  // probably be removed.
  if (isHidden) { return false }
  if (index === 0) { return true }
  return !(index > 0 && visibleWhenChild === false)
}


function isHidden(column, item) {
  if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
  // visibleForRegistrationTypes is included in the field definition
  // so always keep it hidden until the registration type is selected.
  if (!item.registrationType.pk) { return true }

  if (column.key === 'organization') {
    // organization is visible if the Service Status is not None
    if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
      //
      return true
    }
    // If the service is Air Force and the MAJCOM is AMC don't show
    if (item.militaryServiceRank.name &&
      (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force'))) {
      if (item.majcom && item.majcom.pk === "z.3cccab1b-a844-4ce2-a5e3-801129abc531") {
        return true
      }
    }
  }

  if (column.lookupParameters) {
    if (column.lookupParameters.itemType === 'militaryServiceRank' ||
      column.lookupParameters.itemType === 'base') {
      // Military Service Rank is only visible if the Service Status is not NONE
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
    }
    if (column.lookupParameters.itemType === 'afOrgs') {
      // afOrgs is only visible if the Service is Air Force and the majcom is AMC
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
      // If there is no rank selected, then don't display
      if (!item.militaryServiceRank.name) {
        return true
      }
      // If there is a rank and service and it is not Air Force hide
      if (item.militaryServiceRank.name && !item.militaryServiceRank.name.includes('Air Force')) {
        return true
      }
      // If there is a rank and it is airforce AND majcom is not AMC hide
      if (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force')) {
        if (item.majcom && item.majcom.pk !== "z.3cccab1b-a844-4ce2-a5e3-801129abc531") {
          return true
        }
      }
    }

    if (column.lookupParameters.itemType === 'majcom') {
      // afOrgs is only visible if the Service is Air Force
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
      if (!item.militaryServiceRank.name ||
        (item.militaryServiceRank.name && !item.militaryServiceRank.name.includes('Air Force'))) {
        return true
      }
    }
  }


  if (column.key === 'role') {
    // Military Service Rank is only visible if the Service Status is not NONE
    if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
      return true
    }
  }
  // if (column.key === 'organization') {
  //   // afOrgs is only visible if the Service is not Air Force
  //   if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
  //     return true
  //   }
  // }
  if (column.key === 'organization') {
    // organization is visible if the Service Status is not None
    if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
      //
      return true
    }
    // If the service is Air Force and the MAJCOM is AMC don't show
    if (item.militaryServiceRank.name &&
      (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force'))) {
      if (item.majcom && item.majcom.pk === "z.3cccab1b-a844-4ce2-a5e3-801129abc531") {
        return true
      }
    }
  }
  if (column.hiddenForRegistrationTypes) {
    return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
  }
  return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
}

function IsRequired(props) {
  let { column, status } = props
  return status === 'EDITING' && column.userEditable && column.required ? <span>*</span> : null
}
