import React from 'react'

import Badge from './Badge'
import BadgePage from './BadgePage'
import { Authenticator } from '@aws-amplify/ui-react'

import {
  Route,
  Switch
} from "react-router-dom";


export default function BadgeRoutes() {

  return (
    <Switch>
      <Route key="998" exact path={"/badge/:id" } >
        <Authenticator loginMechanisms={['email']}>
          {({ signOut, user }) => (
            <div>
              <Badge user={user} signOut={signOut}/>
            </div>
          )}
        </Authenticator>
      </Route>
      <Route key="997" exact path={"/badge-page"} >
        <Authenticator loginMechanisms={['email']}>
          {({ signOut, user }) => (
            <div>
              <BadgePage user={user} signOut={signOut}/>
            </div>
          )}
        </Authenticator>
      </Route>


    </Switch>
  )
}
