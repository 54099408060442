import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import { navigation } from './menus'
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';


class TopMenu extends Component {

  render() {

    return (
      <nav
        aria-label="Primary navigation"
        className="usa-nav">
        <div className="usa-nav__inner">
          <button className="usa-nav__close">
            <img src="/images/close.svg" alt="close" />
          </button>
          <ul className="usa-nav__primary usa-accordion">
            <Menus />

          </ul>

          <SecondaryMenu
            signOut={this.props.signOut}
            authState={this.props.authState}
            env={this.props.env}
          />
        </div>
      </nav>
    )
  }
}

function Menus() {

  // const location = useLocation()

  return (
    navigation.map((menu) => {
      if (!menu.topNavigation) { return "" }

      return (
        <SlDropdown hoist key={menu.sequence}>
          <SlButton slot="trigger" caret className="usa-nav__primary-item menu-nav" >
            {menu.parentLabel}
          </SlButton>
          <SlMenu>
            {menu.items.map((item) => {
              return (
                <SlMenuItem key={item.order}>
                  {item.auth &&
                    <a href={item.link} className="menu-nav-link">
                      {item.label} {item.auth && <i className="fad fa-lock-alt"></i>}
                    </a>
                  }
                  {!item.auth &&
                    <Link to={item.link} className="menu-nav-link">
                      {item.label} {item.auth && <i className="fad fa-lock-alt"></i>}
                    </Link>
                  }
                </SlMenuItem>
              )
            })}

          </SlMenu>
        </SlDropdown>
      )
    }
    ))
}
function SecondaryMenu(props) {
  return (
    <div className="usa-nav__secondary">
      <ul className="usa-nav__secondary-links">

        <div className="usa-nav__secondary-item">
          <Link
            to="/contact"
            title="Contact"
            aria-label="Contact"
            style={{ textDecoration: 'none' }}
          >
            Contact
          </Link>
        </div>
        <div className="usa-nav__secondary-item">
          <Link
            to="/donation"
            title="Donate"
            aria-label="Donate"
            style={{ textDecoration: 'none' }}
          >
            Donate
          </Link>
        </div>
        <div className="usa-nav__secondary-item">
          <a
            href="https://teamlocker.squadlocker.com/#/lockers/ata-merchandise"
            aria-label="shop"
            style={{ textDecoration: 'none' }}
          >
            Shop
          </a>
        </div>

        <div className="usa-nav__secondary-item">
          <a
            href="/account"
            title="Account"
            aria-label="Account"
            style={{ textDecoration: 'none' }}
          >
            Account
          </a>
        </div>

        {
          props.authState === 'signedin' &&
          <div className="usa-nav__secondary-item">
            <Button className="usa-button usa-button--unstyled" onClick={() => props.signOut()}><i className="fal fa-sign-out-alt"></i></Button>
          </div>
        }
      </ul >

      <form className="usa-search usa-search--small ">
        <div role="search">
          <label className="usa-sr-only" htmlFor="extended-search-field-small">
            Search small
          </label>
          <input
            className="usa-input"
            id="extended-search-field-small"
            type="search"
            name="search"
          />
          <button className="usa-button" type="submit">
            <span className="usa-sr-only">Search</span>
          </button>
        </div>
      </form>
    </div >
  )
}


export default TopMenu;
