/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://2r2my25dmk.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "activity",
            "endpoint": "https://ss4j4uei4f.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "badgePreview",
            "endpoint": "https://u90ada189i.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "coupon",
            "endpoint": "https://t7o5q7y5l9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "couponUnauth",
            "endpoint": "https://41dyxtf1e4.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "general",
            "endpoint": "https://o8noa1br06.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "hotel",
            "endpoint": "https://6w6k6wlvt1.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "item",
            "endpoint": "https://mzwwemjphc.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "itemUnauth",
            "endpoint": "https://40dv4l1sjj.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "list",
            "endpoint": "https://iux27e4zg0.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "listNew",
            "endpoint": "https://wrk6z7vpaa.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "listUnauth",
            "endpoint": "https://zqthuh92ei.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "lookup",
            "endpoint": "https://rcxn9661eh.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "membership",
            "endpoint": "https://ttx6jq4sa9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "passkey",
            "endpoint": "https://n0eao0oji1.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "stripe",
            "endpoint": "https://p5ui7sdvl9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "stripeEvents",
            "endpoint": "https://1tjuz4m0s9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "stripeProcessing",
            "endpoint": "https://pei88epyi9.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "summary",
            "endpoint": "https://gnppkbnyz2.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        },
        {
            "name": "view",
            "endpoint": "https://vwjbglkv6k.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:7679962f-4eb3-4e5b-adf0-b9ce849b066b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XrKkDI41u",
    "aws_user_pools_web_client_id": "3c10767jccolcasd5uoh2jriue",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "activity-master",
            "region": "us-east-1"
        },
        {
            "tableName": "general-master",
            "region": "us-east-1"
        },
        {
            "tableName": "global-master",
            "region": "us-east-1"
        },
        {
            "tableName": "summary-master",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "web259bdb729ea547d9a0b3396595e03232-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
