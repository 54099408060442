import * as contentful from 'contentful'
import React from 'react'

var contentfulClient = contentful.createClient(
  {
    space: 'bg11zawujrgr',
    accessToken: 'c86395435846a2c448defbf0c59f24677fa2a58d522e6b2fe3e6c4b57c083caf'
  })

// export const PersonContext = React.createContext(null);
// export const CompanyContext = React.createContext(null);
// export const ChapterContext = React.createContext(null);
// export const BadgeContext = React.createContext(null);
export const ContentfulContext = React.createContext({ contentfulClient: contentfulClient })
