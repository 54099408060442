import { API, Auth, Hub } from 'aws-amplify'
import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

var moment = require('moment')

// Must match the list in Collection
const requestTypeMapping = {
  a: 'membership',
  b: 'company:official',
  c: 'chapter',
  d: 'chapter:official',
  e: 'awardType',
  f: 'form',
  g: 'receipt',
  h: 'unassigned',
  i: 'company',
  j: 'awardType',
  k: 'receiptLineItem',
  l: 'unassigned',
  m: 'member',
  n: 'membershipType',
  o: 'unassigned',
  p: 'award',
  q: 'eeg',
  r: 'registration',
  s: 'coupon',
  t: 'coupon:registration',
  u: 'user',
  v: 'registrationType',
  w: 'unassigned',
  x: 'unassigned',
  y: 'convention',
  z: 'picklist'
}


const STATUS_EMPTY = 'EMPTY'
const STATUS_UNINITIALIZED = 'UNINITIALIZED'
const STATUS_LOADING = 'LOADING'
const STATUS_NOT_LOADED = 'NOT_LOADED'
const STATUS_LOADED = 'LOADED'
const STATUS_EDITING = 'EDITING'
const STATUS_SAVING = 'SAVING'
const STATUS_RELOAD = 'RELOAD'
// const STATUS_NEW = 'NEW'

const ACTION = {
  HOTEL_ALREADY_HAVE_ROOM: 'hotelAlreadyHaveRoom',
  HOTEL_DONT_NEED_ROOM: 'hotelDontNeedRoom',
  HOTEL_STARTED_BOOKING: 'hotelStartedBooking',
  HOTEL_BOOKING_CONFIRMED: 'hotelBookingConfirmed',
  BANQUET_CHOICES: 'banquetChoices',
  REQUEST_CANCELLATION: 'cancellationRequested',
  BADGE_CHOICES: 'badgeChoices',
  REGISTRATION_STATUS: 'registrationStatus',
  ADA_ACCOMODATION: 'adaAccomodation'
}

const actionHotelString = {
  [ACTION.HOTEL_ALREADY_HAVE_ROOM]: "I've already booked a room",
  [ACTION.HOTEL_DONT_NEED_ROOM]: "I don't need a hotel room",
  [ACTION.HOTEL_STARTED_BOOKING]: "I've started my hotel booking",
  [ACTION.HOTEL_BOOKING_CONFIRMED]: "I already have a hotel booking"
}

const COLLECTION_EMPTY = { segmentCount: 0, segments: [] }


export default function useCollection(props) {

  const [authState, setAuthState] = useState(false)
  const [collection, setCollection] = useState(COLLECTION_EMPTY)
  const [originalCollection, setOriginalCollection] = useState(COLLECTION_EMPTY)
  const [previousStatus, setPreviousStatus] = useState(STATUS_EMPTY)
  const [proposedMembershipStatus, setProposedMembershipStatus] = useState(null)
  const [status, setStatus] = useState(STATUS_UNINITIALIZED)
  const [isValid, setIsValid] = useState(true)
  const [subItemType, setSubItemType] = useState(null)
  const [service, setService] = useState(false)
  const [saveSuccessful, setSaveSuccessful] = useState(null)

  let location = useLocation()

  Hub.listen('auth', listener);

  function listener(data) {
    if (data.payload.event === 'signIn') {
      setAuthState(true)
      setService('item')
      setStatus(STATUS_UNINITIALIZED)
    } else {
      setAuthState(false)
      setService('itemUnauth')
      setStatus(STATUS_UNINITIALIZED)
    }
  }

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        setAuthState(true)
        setService('item')
      })
      .catch(() => {
        setAuthState(false)
        setService('itemUnauth')
      })
  }, [props.itemType])

  useEffect(() => {
    if (!authState && location.pathname.split('/')[1] !== 'individual') { return }
    switch (status) {
      case STATUS_EMPTY:
        break
      case STATUS_UNINITIALIZED:
        loadCollection()
        break
      case STATUS_LOADED:
        if (previousStatus === STATUS_EDITING) {
          // Resets the collection to its status before editing
          setCollection(JSON.parse(JSON.stringify(originalCollection)))
        }
        setProposedMembershipStatus(null)
        break;
      case STATUS_RELOAD:
        loadCollection()
        break;
      case STATUS_LOADING:
      case STATUS_NOT_LOADED:
        // no action required
        break
      case STATUS_EDITING:
        setOriginalCollection(JSON.parse(JSON.stringify(collection)))
        break
      case STATUS_SAVING:
        if (previousStatus === STATUS_EDITING || proposedMembershipStatus) {
          // Resets the collection to its status before editing
          setOriginalCollection(COLLECTION_EMPTY)
        }
        saveCollection()
        break;
      default:
    }

    setPreviousStatus(status)
  }, [status, service, authState])


  useEffect(() => {
    if (!service) { return }
    if (props.id && props.itemType) { setStatus(STATUS_UNINITIALIZED) }

    return () => {
      setCollection(COLLECTION_EMPTY)
      setOriginalCollection(COLLECTION_EMPTY)
      setStatus(STATUS_EMPTY)
      setPreviousStatus(STATUS_EMPTY)
      setIsValid(true)
      setSubItemType(null)
    }
  }, [props.subId, props.id, props.itemType, service])


  async function loadCollection() {

    if (!service) { return }
    if (!props.id) { return }
    if (status === STATUS_LOADING) { return }

    let path
    if (props.subId) {
      path = [determineRequestType(), props.id, props.subId].join('/')
    } else {
      path = [determineRequestType(), props.id, 'none'].join('/')
    }
    setSubItemType(determineRequestType())

    if (location.search) {
      path += location.search
    }

    let response
    if (status !== STATUS_LOADING) {
      setStatus(STATUS_LOADING)
      try {
        response = await API.get(service, "/" + path)
        setProposedMembershipStatus(null)
        setCollection(response)
        if (props.id === 'new') {
          setStatus(STATUS_EDITING)
          setIsValid(true)
        } else {
          setStatus(STATUS_LOADED)
          setIsValid(true)
        }
      }
      catch (error) {
        console.log('error', error)
        setStatus(STATUS_NOT_LOADED)
      }
    }

  }

  function registrationAction(action, options) {
    let noAction = false
    switch (action) {
      case ACTION.HOTEL_ALREADY_HAVE_ROOM:
      case ACTION.HOTEL_DONT_NEED_ROOM:
      case ACTION.HOTEL_STARTED_BOOKING:
      case ACTION.HOTEL_BOOKING_CONFIRMED:
        collection.segments[0].items[0].hotelRoomStatus = action
        collection.segments[0].items[0].hotelRoomStatusName = actionHotelString[action]
        break
      case ACTION.BANQUET_CHOICES:
        collection.segments[0].items[0].banquetDietaryRestrictions = options.banquetDietaryRestrictions
        collection.segments[0].items[0].banquetSeatingRequest = options.banquetSeatingRequest
        collection.segments[0].items[0].banquetWillAttend = options.banquetWillAttend
        break
      case ACTION.REQUEST_CANCELLATION:
        collection.segments[0].items[0].gsi1_pk = 'registration:primary:cancellationRequested'
        collection.segments[0].items[0].statusName = 'Cancelation Requested'
        collection.segments[0].items[0].cancellationRequestedDate = moment().format('YYYY-MM-DD')
        break
      case ACTION.BADGE_CHOICES:
        collection.segments[0].items[0].badgeNickname = options.badgeNickname
        collection.segments[0].items[0].organization = options.organization
        break
      case ACTION.REGISTRATION_STATUS:
        collection.segments[0].items[0].gsi1_pk = options.status
        collection.segments[0].items[0].statusName = options.statusName
        break
      case ACTION.ADA_ACCOMODATION:
        collection.segments[0].items[0].adaAccomodation = options.adaAccomodation
        break
      default:
        noAction = true
    }

    if (!noAction) {
      saveCollection()
      setProposedMembershipStatus(null)
    }
  }

  function saveProposedMembershipStatus() {
    setStatus(STATUS_EDITING)
    collection.segments[0].items[0].mostRecentMembershipType = proposedMembershipStatus.proposed.mostRecentMembershipType
    collection.segments[0].items[0].mostRecentMembershipEndDate = proposedMembershipStatus.proposed.mostRecentMembershipEndDate
    collection.segments[0].items[0].hasGraceMembership = proposedMembershipStatus.proposed.hasGraceMembership
    collection.segments[0].items[0].hasActiveMembership = proposedMembershipStatus.proposed.hasActiveMembership
    collection.segments[0].items[0].hasExpiredMembership = proposedMembershipStatus.proposed.hasExpiredMembership
    collection.segments[0].items[0].company = proposedMembershipStatus.proposed.company
    collection.segments[0].items[0].gsi1_pk = proposedMembershipStatus.proposed.gsi1_pk
    collection.segments[0].items[0].overlappingIpMembership = proposedMembershipStatus.proposed.overlappingIpMembership
    setStatus(STATUS_SAVING)
  }

  async function refreshMembershipStatus() {
    let id
    if (collection.segments[0].items[0].gsi1_pk === "user") {
      id = collection.segments[5].items[0].pk
      // Post will request an update.
      let response = await API.post(service, "/membershipEndDate/" + id)
      setProposedMembershipStatus(response)
      setStatus(STATUS_UNINITIALIZED)
    } else {
      id = collection.segments[0].items[0].pk
      let response = await API.put(service, "/membershipEndDate/" + id)
      setProposedMembershipStatus(response)
    }
  }

  async function alignMembershipEndDates() {
    setStatus(STATUS_EDITING)
    let membershipIndex = 2 // hardcoded but can be found by looking for gsi3_pk === 'membership'
    for (let item of collection.segments[membershipIndex].items) {
      if (item.membershipType.pk === 'n.c6ab147b-62d2-4e6b-8a17-ab6be36815fe') {
        item.endDate = collection.segments[0].items[0].alignEndDatesTo
      }
    }
    setStatus(STATUS_SAVING)
  }

  async function saveCollection() {
    let path
    if (props.subId) {
      path = [determineRequestType(), props.id, props.subId].join('/')
    } else {
      path = [determineRequestType(), props.id, 'none'].join('/')
    }
    setSubItemType(determineRequestType())

    if (determineRequestType() === 'registration' && setBadgeUpdated()) {
      collection.segments[0].items[0].badgeUpdated = true
    }

    if (location.search) {
      path += location.search
    }

    let response
    setSaveSuccessful(null)
    try {
      response = await API.put(service, "/" + path, { body: collection })
      setCollection(response)
      setStatus(STATUS_LOADED)
      setSaveSuccessful(true)
    }
    catch {
      setSaveSuccessful(false)
      setStatus(STATUS_EDITING)
    }
  }



  function determineRequestType() {
    if (props.itemType === 'profile') { return props.itemType }
    if (props.subId && props.subId.indexOf('@') !== -1) { return 'user' }
    return props.subId ? requestTypeMapping[props.subId.slice(0, 1)] : props.itemType
  }

  function setBadgeUpdated() {
    // Used immediately before a save to determine if any of the fields changed are related
    // to the badge. If they are, badgeAttributeChanged field is set to true, otherwise false
    // The attribute is only available for registrations.
    // This is a hint provided from the UI to allow the backend to set the badge updated date correctly
    // If any of the following fields have changed
    //

    if (originalCollection.segments.length === 0) { return false }

    const original = originalCollection.segments[0].items[0]
    const edited = collection.segments[0].items[0]
    const fields = [
      'firstName',
      'lastName',
      'badgeNickname',
      'base',
      'exhibitor',
      'majcom',
      'militaryServiceRank',
      'militaryServiceStatus',
      'base',
      'organization'
    ]
    if (props.itemType === 'registration') {
      for (let field of fields) {
        if (typeof original[field] === 'object') {
          if (JSON.stringify(original[field]) !== JSON.stringify(edited[field])) {
            return true
          }
        } else {
          if (original[field] !== edited[field]) {
            return true
          }
        }
      }
    } return false
  }

  return {
    collection, subItemType,
    setStatus,
    previousStatus,
    status,
    isValid,
    registrationAction,
    refreshMembershipStatus,
    alignMembershipEndDates,
    proposedMembershipStatus,
    saveProposedMembershipStatus,
    saveSuccessful,
    ACTION
  }
}
