/* jshint camelcase: false */

import { API } from 'aws-amplify'

class Event {

  static async add(eventType, data) {

    let event = {
      eventType: eventType,
      data: data,
    }

    return new Promise((resolve, reject) => {
      API.post('activity', '/event', { body: event })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}

export default Event;
