import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'

export default function useSearch(props) {

  const { target } = props
  const [items, setItems] = useState([])
  const [continuationToken, setContinuationToken] = useState(null)
  const [pagesLoadedCount, setPagesLoadedCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isErrored, setIsErrored] = useState(false)
  const [moreData, setMoreData] = useState(true)

  async function loadData() {

    if (isLoading) { return }
    let response
    if ((continuationToken || items.length === 0)) {
      //    const parameters = { queryStringParameters: props }

      setIsLoading(true)
      setIsErrored(false)
      try {
        response = await API.get('general', '/search/' + target)
        const sortedItems = response.items.sort((a, b) => {
          if (a[props.sortBy] < b[props.sortBy]) { return 1 }
          if (a[props.sortBy] > b[props.sortBy]) { return -1 }
          return 0
        })

        setMoreData((Boolean(response.nextContinuationToken))) //  || response.items.length !== 0
        setItems(items.concat(sortedItems))
        setPagesLoadedCount(pagesLoadedCount + 1)
        setContinuationToken(response.nextContinuationToken)
        setIsLoading(false)
      }
      catch {
        setIsLoading(false)
        setIsErrored(true)
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [continuationToken])

  return { isErrored, isLoading, moreData, items }
}
