import React, { Component } from 'react';
import { Link } from "react-router-dom";

var moment = require('moment');

class WelcomeMessage extends Component {

  render() {

    if (!this.props.content) {return null}

    const { text, headline, unpublishDate } = this.props.content

    // If we are past the unpublish date, then don't display anything
    if (moment().isAfter(moment(unpublishDate))) {return null}

    const format = this.props.dark ? "usa-section usa-section--dark" : "usa-section"
    const regex = / |\//g

    return (

    <section id="test-section-id margin-y-0" style={{paddingTop: 8, paddingBottom: 8, backgroundColor: '#b50909'}} className={format}>
      <div className="grid-container">
      <Link
        to={headline.toLowerCase().replace(regex, '-')}
        title={headline}
        aria-label={headline}
        className="usa-button--unstyled"
        style={{textDecoration: 'none', color: 'white'}}
      >
        {text}
      </Link>
      </div>
    </section>
    )
  }
}

export default WelcomeMessage;
