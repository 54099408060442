import React from 'react'
import {
  useParams, Link
} from "react-router-dom"

import useQuery from '../admin/useQuery'
// import PersonalInformation from './PersonalInformation'
import useRegistrationStatus from '../admin/useRegistrationStatus'
import 'react-phone-number-input/style.css'
import Panel from '../admin/Panel'
import { PageHeader } from '../admin/Section'

export default function Registration() {

  const query = useQuery()
  let primaryEmail = null
  if (query.get('primaryEmail')) {
    primaryEmail = decodeURIComponent(query.get('primaryEmail'))
  }
  let fullName = null
  if (query.get('fullName')) {
    fullName = query.get('fullName')
  }
  const registrationStatus = useRegistrationStatus({ source: '/registrationStatus' })

  const { id } = useParams()

  return (
    <div >
      <PageHeader title="Register a Member for the Convention"
        description="This feature does not check that the individual has a convention-valid membership." />

      {
        registrationStatus.data && registrationStatus.data.stripePricingTables &&
        <>
          <Panel title="Member Information">
            <div className="grid-container" style={{ paddingLeft: 0, width: 1400 }}>
              <div className="grid-row">
                <div className="tablet:grid-col-12">
                  <p>Member Name: {fullName}</p>
                  <p>Member Primary Email: {primaryEmail}</p>
                  <p><Link className="usa-button" to={"/admin/member/" + id}>Go To Member</Link></p>
                </div>
              </div>
              <div className="grid-row">
                <div className="tablet:grid-col-12">

                </div>
              </div>
            </div>
          </Panel>
          <stripe-pricing-table pricing-table-id={registrationStatus.data.stripePricingTables[2]}
            publishable-key={registrationStatus.data.stripePublicKey}
            client-reference-id={id.split('.')[1]}
            customer-email={primaryEmail}
          >
          </stripe-pricing-table>
        </>
      }
    </div >
  )
}
