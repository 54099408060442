import React, { useEffect, useState } from 'react'
import {
  useParams,
  useLocation,
  Redirect
} from "react-router-dom"

import Collection from '../admin/Collection'
import useQuery from '../admin/useQuery'

export default function RegistrationReceipt(props) {
  const hostname = window.location.hostname
  const { itemType } = useParams()
  const location = useLocation()
  const section = location.pathname.split('/')[1]
  const query = useQuery()
  const [redirectUrl, setRedirectUrl] = useState()
  const [redirectRequested, setRedirectRequested] = useState(false)

  let { item, registrationForm } = props

  useEffect(() => {
    if (item.status === 'EDITING' && itemType === 'receipt') {
      item.setStatus('SAVING')
    }
  })

  useEffect(() => {
    if (redirectUrl) { return }

    let groupParam = ''
    let panelParam = ''
    if (query.get('group')) {
      groupParam = "&group=true"
    }

    if (itemType === 'receipt' && item.status === 'LOADED' && groupParam === '') {
      let itemZero = item.collection.segments[0].items[0]
      if (itemZero.gsi1_pk === 'receipt') {

        if (query.get('panel') || itemZero.includesFallIndustryPanelsAttendee) {
          panelParam = "&panel=true"
        }

        let url = ''
        if (hostname === 'www.atalink.org' ||
          hostname === 'atalink.org') {
          url = 'https://p5ui7sdvl9.execute-api.us-east-1.amazonaws.com/master'
        } else if (hostname === 'v3.atalink.org') {
          url = 'https://qbnorup128.execute-api.us-east-1.amazonaws.com/maina'
        } else {
          url = 'https://4htbvql798.execute-api.us-east-1.amazonaws.com/dev'
        }

        url += "/checkout?receipt=" + itemZero.pk + "&registration=" + itemZero.registration.pk + '&section=' + section + groupParam + panelParam
        setRedirectUrl(url)
      }
    }
  }, [item.status])



  if (redirectUrl && !redirectRequested) {
    setRedirectRequested(true)
    window.location.replace(redirectUrl)
  }

  let groupParam = ''
  let panelParam = ''
  if (query.get('panel') || item.collection.segments[0].items[0].includesFallIndustryPanelsAttendee) {
    panelParam = "&panel=true"
  }

  return (
    <>
      {itemType === 'registration' && item.status === 'LOADED' && item.previousStatus === 'SAVING' && groupParam === '' &&
        <div>
          <i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
          <Redirect to={"/" + section + "/receipt/new?registrations=" + JSON.stringify([item.collection.segments[0].items[0].pk]) + groupParam + panelParam} />
        </div>
      }

      {itemType === 'registration' && item.status === 'LOADED' && item.previousStatus === 'SAVING' && groupParam !== '' &&
        <div>
          <i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
          <Redirect push to={"/" + section + "/groups?newRegistration=" + item.collection.segments[0].items[0].pk} />
        </div>
      }

      <div className="grid-container margin-y-0">
        {itemType === 'receipt' && registrationForm &&
          <div><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none', color: 'green' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
            <div style={{ display: 'none' }}>
              <Collection
                collection={item.collection}
                status={item.status}
                previousStatus={item.previousStatus}
                setStatus={item.setStatus}
                form={{ items: [registrationForm] }}
              />
            </div>
          </div>
        }
      </div>
    </>
  )
}
