import React from 'react'
import {
  useParams
} from "react-router-dom"

import Collection from '../admin/Collection'

// <li>Keynotes and Seminars Only: Free </li>
// <p className="usa-prose">Free options do not include food nor access to the Exhibit Hall.</p>

// const introductionText = <div>
//   <h3 style={{ marginTop: 0, borderBottomStyle: 'solid' }}>Success</h3>
//   <div className="grid-row margin-y-1">
//     <div className="tablet:grid-col-12">
//       <h4 style={{ marginTop: 0, marginBottom: -10 }}> Attendees</h4>
//       <p className="usa-prose">You have paid, now provide us some additional information.</p>
//     </div>
//   </div>
// </div>


export default function RegistrationCreate(props) {

  const { itemType } = useParams()
  let { item, registrationForm, user } = props

  let itemZero
  try {
    itemZero = item.collection.segments[0].items[0]
  }
  catch {
    itemZero = false
  }

  return (
    <>
      {itemType === 'registration' && itemZero.neverSaved && !registrationForm &&
        <div className="grid-container margin-y-0">
          <i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i>
        </div>
      }

      {itemType === 'registration' && itemZero.neverSaved && registrationForm &&
        <div className="grid-container margin-y-0">

          <Collection
            collection={item.collection}
            saveSuccessful={item.saveSuccessful}
            status={item.status}
            previousStatus={item.previousStatus}
            setStatus={item.setStatus}
            form={{ items: [registrationForm] }}
            user={user}
          />
        </div>
      }
    </>
  )
}
