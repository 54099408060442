/* eslint no-unused-vars: 0 */

import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import useList from './useList'
import GridControl from './GridControl'
import GridConfig from './GridConfig'
import GridData from './GridData'
import { PageHeader } from './Section'
import { CSVDownload } from 'react-csv'

var moment = require('moment')

const fixedColumns = [
  { key: 'favorite', value: 'favorite', label: '', mayFilter: true },
  { key: 'recent', value: 'recent', label: '', mayFilter: true },
]

export default function Grid(props) {

  const { gridDef } = props
  const [cookies, setCookie, removeCookie] = useCookies(['tableFilter']);
  const [columns, setColumns] = useState(
    cookies[gridDef.pk + '.columns'] ?
      cookies[gridDef.pk + '.columns'] :
      (fixedColumns.concat(gridDef.availableColumns.filter(col => col.isVisible))))

  const [stopAfterPage, setStopAfterPage] = useState(gridDef.initialStopAfterPage)
  const [csvRequestState, setCsvRequestState] = useState('unavailable')
  const [csvColumns, setCsvColumns] = useState(gridDef.availableColumns.filter(col => col.includeInCsv).concat(fixedColumns))
  const [availableFilterColumns, setAvailableFilterColumns] = useState(gridDef.availableColumns.filter(col => col.mayFilter && col.isVisible))

  const [tableFilter, setTableFilter] = useState(cookies[gridDef.pk + '.tableFilter'] ?? (gridDef.filterColumnDefaultValue ?? ''))
  const [tableFilterColumn, setTableFilterColumn] = useState(cookies[gridDef.pk + '.tableFilterColumn'] ?? gridDef.filterColumn)
  const [filteredList, setFilteredList] = useState({ items: [] })

  const [viewFavorites, setViewFavorites] = useState(false)
  const [viewRecents, setViewRecents] = useState(false)
  const [sortBy, setSortBy] = useState(gridDef.sortBy)
  const [sortByAscending, setSortByAscending] = useState(1)
  const [configMode, setConfigMode] = useState(false)
  const [lastRecentChange, setLastRecentChange] = useState()
  const [banquetTableSize, setBanquetTableSize] = useState(cookies[gridDef.pk + '.banquetTableSize'] ?? 10)

  let listProps = {
    stopAfterPage: stopAfterPage,
    items: gridDef.items,
    lastEvaluatedKey: gridDef.lastEvaluatedKey,
    pagesLoadedCount: gridDef.pagesLoadedCount,
    pageSize: gridDef.pageSize,
    source: gridDef.source,
    type: gridDef.type,
    continuationToken: gridDef.continuationToken
  }

  const list = useList(listProps)

  useEffect(() => {
    setCookie(gridDef.pk + '.tableFilter', tableFilter, { sameSite: true })
  }, [tableFilter])

  useEffect(() => {
    setCookie(gridDef.pk + '.tableFilterColumn', tableFilterColumn, { sameSite: true })
  }, [tableFilterColumn])

  useEffect(() => {
    setCookie(gridDef.pk + '.columns', columns, { sameSite: true })
  }, [columns])

  useEffect(() => {
    setCookie(gridDef.pk + '.banquetTableSize', banquetTableSize, { sameSite: true })
  }, [banquetTableSize])

  useEffect(() => {
    let workingList = Object.assign(list)

    if (workingList.totalItems > 0 && sortBy.length > 0) {
      const nullValue = sortByAscending === 1 ? 'zz' : '00'
      workingList.items.sort((a, b) => {

        let itemA = sortBy.map(item => {
          const path = item.split('.')
          if (path.length === 1) {
            return (a[item] ? a[item].toString().trim() : nullValue).toString()
          }
          if (!a[path[0]]) { return (nullValue) }
          return (a[path[0]][path[1]] ? a[path[0]][path[1]].toString().trim() : nullValue).toString()

        })
        let itemB = sortBy.map(item => {
          const path = item.split('.')
          if (path.length === 1) {
            return (b[item] ? b[item].toString().trim() : nullValue).toString()
          }
          if (!b[path[0]]) { return (nullValue) }
          return (b[path[0]][path[1]] ? b[path[0]][path[1]].toString().trim() : nullValue).toString()

        })

        if (itemA[0].toLowerCase() < itemB[0].toLowerCase()) { return -1 * sortByAscending }
        if (itemA[0].toLowerCase() > itemB[0].toLowerCase()) { return sortByAscending }
        return 0
      })
    }

    if (workingList.items) {
      workingList.items.forEach(item => {
        let index = gridDef.recents.findIndex(recent => item.pk === recent.pk)
        item.recent = index === -1 ? false : gridDef.recents[index].timestamp
        index = gridDef.favorites.findIndex(favorite => item.pk === favorite.pk)
        item.favorite = (index !== -1)
      })
    } else {
      workingList.items = []
    }

    if (viewFavorites && viewRecents) {
      workingList.items = list.items.filter(item => item.favorite && item.recent)
    } else if (viewFavorites) {
      workingList.items = list.items.filter(item => item.favorite)
    } else if (viewRecents) {
      workingList.items = list.items.filter(item => item.recent)
    } else {
      if (tableFilterColumn && tableFilter.length > 1 && list.items.length > 0) {
        workingList.items = workingList.items.filter(item => {
          const filterColumnElements = tableFilterColumn.split('.')
          if (filterColumnElements.length === 1) {
            return String(item[tableFilterColumn]).toLowerCase().includes(tableFilter.toLowerCase())
          }
          if (!item[filterColumnElements[0]]) { return false }
          return String(item[filterColumnElements[0]][filterColumnElements[1]]).toLowerCase().includes(tableFilter.toLowerCase())
        })
      }
    }

    setFilteredList(Object.assign(workingList))

  }, [list.totalItems, list.isLoading, sortBy, sortByAscending, tableFilter, tableFilterColumn, lastRecentChange, viewFavorites, viewRecents])

  // Controls the CSV download request. It loads all the remaining pages
  // and then generates the request
  useEffect(() => {
    if (csvRequestState === 'requested' && !list.moreData) {
      setCsvRequestState('readyToPrint')
    }
  }, [list.moreData, csvRequestState])

  useEffect(() => {
    if (csvRequestState === 'readyToPrint') {
      setCsvRequestState('printed')
    }
  }, [csvRequestState])

  function selectAll(selection) {
    gridDef.favorites = []
    if (selection) {
      gridDef.favorites = filteredList.items.map(i => { return { pk: i.pk, sk: i.sk, timestamp: moment().format() } })
    }
    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  function requestDelete() {
    list.deleteItems(gridDef.favorites)
    gridDef.favorites = []
    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  function requestCsv() {
    if (list.moreData) {
      setCsvRequestState('requested')
      setStopAfterPage(997)
    } else {
      setCsvRequestState('readyToPrint')
    }
  }

  function handleHeaderClick(item) {
    if (item === sortBy[0] ||
      (item === props.sortByDefaultColumn && sortBy === props.sortBy)) {
      // clicking twice changed from asc to desc
      setSortByAscending(-1 * sortByAscending)
    } else {
      if (item === props.sortByDefaultColumn) {
        setSortByAscending(1)
        setSortBy(props.sortBy)
      } else {
        setSortByAscending(1)
        setSortBy([item].concat(sortBy.slice(1)))
      }
    }
  }

  function toggleFavorite(key) {

    let index = gridDef.favorites.findIndex(favorite => favorite.pk === key.pk && favorite.sk === key.sk)
    if (index === -1) {
      gridDef.favorites.push({ pk: key.pk, sk: key.sk, timestamp: moment().format() })
    } else {
      gridDef.favorites = gridDef.favorites.filter(favorite => favorite.pk !== key.pk)
    }

    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  function toggleFavoriteVisible() {

    if (gridDef.favorites.length === filteredList.items.length) {
      gridDef.favorites = []
    } else {
      gridDef.favorites = filteredList.items.map(item => { return { pk: item.pk, sk: item.sk, timestamp: moment().format() } })
    }

    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  function toggleFavoritePosition(start, end) {
    gridDef.favorites = []

    if (start && end && end > start && start > 0) {
      gridDef.favorites = filteredList.items.slice(start - 1, end).map(item => { return { pk: item.pk, sk: item.sk, timestamp: moment().format() } })
    }

    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  function trackRecent(pk, event) {
    if (event) { event.stopPropagation() }
    // remove any current entry for this item, limit the list size and
    // then push it to the end of the list.
    gridDef.recents = [{ pk: pk, timestamp: moment().format() }].concat(
      gridDef.recents.filter(item => item.pk !== pk).slice(0, 2)
    )
    setLastRecentChange(moment().format()) // really just a trigger to force a render
  }

  function reloadToPage(page) {
    //    console.log('reloadToPage', page)
    list.resetData()
    setStopAfterPage(page)
  }

  // To Do: make this a function rather than GridControl calling set directly
  gridDef.initialStopAfterPage = Math.max(stopAfterPage, gridDef.initialStopAfterPage)
  gridDef.items = list.items ?? []
  //  console.log('grid set continuationToken old, new', gridDef.continuationToken, list.continuationToken)
  gridDef.continuationToken = list.continuationToken
  //  console.log('grid set pagesLoadedCount old, new', gridDef.pagesLoadedCount, list.pagesLoadedCount)
  gridDef.pagesLoadedCount = list.pagesLoadedCount


  let newLink = '/admin' +
    (gridDef.routeNew ? gridDef.routeNew : gridDef.route) + '/new?cancel=/admin' + gridDef.route


  let favoriteList = list.items.filter(item => item.favorite).map(item => { return item.pk })
  let newPayment = '/admin/receipt/new?registrations=' + JSON.stringify(favoriteList)
  let newGuestLink = '/admin/registration/new?parentRegistration=' + favoriteList[0] + '&cancel=/admin' + gridDef.route
  let printBadges = '/badge-page?ids=' + JSON.stringify(favoriteList) + '&cancel=/admin' + gridDef.route

  return (
    <>
      {csvRequestState === 'printed' &&
        <CSVDownload data={list.items} headers={csvColumns} target="CSVDownload" />
      }

      <div style={{ width: '95%' }}>
        <PageHeader title={gridDef.pageHeader}
          description="" />

        {list.displayTableSummary &&
          <div className="usa-alert usa-alert--success" style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="usa-alert__body">
              <h4 className="usa-alert__heading">Assignment Complete</h4>
              <p className="usa-alert__text">
                There are {list.tableCount + list.tableCountVip} tables of {banquetTableSize} seats including {list.tableCountVip} VIP tables and {list.tableCount} standard tables.
              </p>
              <p>There were {list.tableWarnings.length} warnings.</p>
              <ul>
                {list.tableWarnings.map((item, i) => {
                  return (
                    <li key={i}>{item}</li>
                  )
                })}
              </ul>

              <button style={{ marginTop: 20 }} className="usa-button" onClick={() => list.setDisplayTableSummary(false)}>Close</button>
            </div>
          </div>

        }
        <GridControl
          list={filteredList}
          viewType={listProps.type}
          availableFilterColumns={availableFilterColumns}
          setTableFilterColumn={setTableFilterColumn}
          tableFilterColumn={tableFilterColumn}
          setStopAfterPage={setStopAfterPage}
          setTableFilter={setTableFilter}
          tableFilter={tableFilter}
          viewFavorites={viewFavorites}
          setViewFavorites={setViewFavorites}
          toggleFavoriteVisible={toggleFavoriteVisible}
          viewRecents={viewRecents}
          setViewRecents={setViewRecents}
          selectAll={selectAll}
          requestCsv={requestCsv}
          reloadToPage={reloadToPage}
          newLink={newLink}
          newGuestLink={newGuestLink}
          newPayment={newPayment}
          printBadges={printBadges}
          requestDelete={requestDelete}
          configMode={configMode}
          setConfigMode={setConfigMode}
          banquetTableSize={banquetTableSize}
          setBanquetTableSize={setBanquetTableSize}
          setDisplayTableSummary={list.setDisplayTableSummary}
          toggleFavoritePosition={toggleFavoritePosition}
          initialStopAfterPage={gridDef.initialStopAfterPage}
          registrationStatus={props.registrationStatus}

        />

        {configMode ?
          <GridConfig
            gridDef={gridDef}
            fixedColumns={fixedColumns}
            setColumns={setColumns}
            setCsvColumns={setCsvColumns}
            setAvailableFilterColumns={setAvailableFilterColumns}
          /> :
          <GridData
            columns={columns}
            list={filteredList}
            sortBy={sortBy}
            sortByDefaultColumn={props.sortByDefaultColumn}
            sortByAscending={sortByAscending}
            handleHeaderClick={handleHeaderClick}
            toggleFavorite={toggleFavorite}
            trackRecent={trackRecent}
            setIsAssigningTables={list.isAssigningTables}
            toggleAdminVip={list.toggleAdminVip}
            setAdminRequestedTable={list.setAdminRequestedTable}
            setAdminValue={list.setAdminValue}
          />
        }
      </div>
    </>
  )
}
