
import React from 'react'
import { Link, useLocation, matchPath } from "react-router-dom"
import Button from 'react-bootstrap/Button'

function Footer() {

  let location = useLocation();
  const match = matchPath(location.pathname, {
    path: "/preview/:batchId",
    exact: true,
    strict: false
  });
  if (match) { return null }

  return (
    <footer className="usa-footer">
      <div className="grid-container usa-footer__return-to-top">
        <Button onClick={scrollToTop} className="usa-button--unstyled" style={{ textDecoration: 'none' }}>Return to top</Button>
      </div>
      <div className="usa-footer__primary-section">
        <nav className="usa-footer__nav">
          <ul className="grid-row grid-gap">
            <li className="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
              <Link
                to="/news"
                title="News"
                aria-label="news"
                className="usa-footer__primary-link"
              >
                News
              </Link>
            </li>
            <li className="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
              <Link
                to="/privacy-statement"
                title="Privacy and Terms of Use"
                aria-label="privacy-statement"
                className="usa-footer__primary-link"
              >
                Privacy and Terms of Use
              </Link>
            </li>
            <li className="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
              <a className="usa-footer__primary-link" href="https://airlift-tanker-association.s3.amazonaws.com/By-Laws/By-Laws_ATA_Current.pdf">Association Bylaws</a>
            </li>
            <li className="mobile-lg:grid-col-4 desktop:grid-col-auto usa-footer__primary-content">
              <Link
                to="/contact"
                title="Contact Us"
                aria-label="contact-us"
                className="usa-footer__primary-link"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="usa-footer__secondary-section">
        <div className="grid-container">
          <div className="grid-row grid-gap">
            <div className="usa-footer__logo grid-row mobile-lg:grid-col-6 mobile-lg:grid-gap-2">
              <div className="mobile-lg:grid-col-auto">
                <img className="usa-footer__logo-img" src="/images/ata-logo-transparent.png" alt="" />
              </div>
              <div className="mobile-lg:grid-col-auto">
                <h3 className="usa-footer__logo-heading">Airlift/Tanker Association</h3>
              </div>
            </div>
            <div className="usa-footer__contact-links mobile-lg:grid-col-6">
              <div className="usa-footer__social-links grid-row grid-gap-1">
                <div className="grid-col-auto">
                  <a className="usa-social-link usa-social-link--facebook" href="https://www.facebook.com/AirliftTankerAssociation/">
                    <span>Facebook</span>
                  </a>
                </div>
                <div className="grid-col-auto">
                  <a className="usa-social-link usa-social-link--twitter" href="https://twitter.com/AirliftTanker">
                    <span>Twitter</span>
                  </a>
                </div>
                <div className="grid-col-auto">
                  <a className="usa-social-link usa-social-link--youtube" href="https://www.youtube.com/channel/UCt9APNv9HFoMzPa6FQE-jVw">
                    <span>YouTube</span>
                  </a>
                </div>
              </div>
              <h3 className="usa-footer__contact-heading">Get Support from A/TA</h3>
              <address className="usa-footer__address">
                <div className="usa-footer__contact-info grid-row grid-gap">
                  <div className="grid-col-auto">
                    <a href="tel:1-423-902-2297">(423) 902-2297</a>
                  </div>
                  <div className="grid-col-auto">
                    <a href="mailto:ata@atalink.org">ata@atalink.org</a>
                  </div>
                  <div className="grid-col-auto">
                    <i className="fal fa-copyright"></i> 2024
                  </div>
                </div>
              </address>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )

}

export default Footer

function scrollToTop() {
  var rootElement = document.documentElement
  rootElement.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}
