import React from 'react'

export default function OtherChanges(props) {

  let { item, authState } = props

  if (!item) { return null }

  let signUp = "firstName=" + encodeURIComponent(item.collection.segments[0].items[0].firstName) +
    "&lastName=" + encodeURIComponent(item.collection.segments[0].items[0].lastName) +
    "&emailAddress=" + encodeURIComponent(item.collection.segments[0].items[0].primaryEmail)
  let signIn = "emailAddress=" + encodeURIComponent(item.collection.segments[0].items[0].primaryEmail)
  return (
    <>
      <div className="usa-prose margin-y-1">
        If you want to add a guest to your registration, become a member of the A/TA or update your name or service information, please
        create an account using the same email address you used for registration.
        <div className="grid-row margin-y-1">
          <div className="tablet:grid-col-2"> </div>
          <div className="tablet:grid-col-8">
            {authState === "signedIn" &&
              <a
                href={"/account?" + signIn}
                title="Sign In"
                aria-label="news"
                className="usa-button"
                style={{ textDecoration: 'none', marginBottom: 20, width: "23%" }}
              >
                Go to Account
              </a>
            }
            {authState !== "signedIn" &&
              <>
                <a
                  href={"/account?" + signIn}
                  title="Sign In"
                  aria-label="news"
                  className="usa-button"
                  style={{ textDecoration: 'none', marginBottom: 20, width: "23%" }}
                >
                  Sign In
                </a>
                <a
                  href={"/account?signUp&" + signUp}
                  title="Sign Up"
                  aria-label="news"
                  className="usa-button"
                  style={{ textDecoration: 'none', marginBottom: 20, width: "23%" }}
                >
                  Sign Up
                </a>
              </>
            }

          </div>
        </div>
      </div >
    </>

  )
}
