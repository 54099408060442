import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"

export default function FieldRelatedRegistration(props) {

  let [value, setValue] = useState(props.initialValue)
  let [itemType, setItemType] = useState(false)
  let [text, setText] = useState('')
  let [isValid, setValid] = useState(false)

  const { column, status, updateField, updatedAt, isColumn, item, editStyle, helpStyle, viewStyle } = props

  useEffect(() => {

    let prefix = value ? value.substr(0, 1) : false
    switch (prefix) {
      case 'r':
        setItemType('registration')
        setText('Primary Registration')
        break
      case 'm':
        setItemType('member')
        setText('Member')
        break
      default:
        setItemType(false)
        setText('')
    }
  }, [value])

  function isView() {
    return status === 'LOADED' || !column.userEditable || isColumn
  }

  function isHidden() {
    if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
    // visibleForRegistrationTypes is included in the field definition
    // so always keep it hidden until the registration type is selected.
    if (!item.registrationType.pk) { return true }

    if (column.hiddenForRegistrationTypes) {
      return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
    }

    return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
  }


  // Stores the value in the item and determines if the value is valid
  useEffect(() => {
    if (!isView()) {
      let itemPath = column.key.split('.')
      if (itemPath.length === 1) {
        item[column.key] = value
      } else {
        item[itemPath[0]][itemPath[1]] = value
      }
    }
    const isValidValue = value ?? ''
    if (column.fieldType === 'string' && column.maxLength) {
      setValid(!(column.required && isValidValue.length === 0 && isValidValue.length <= column.maxLength) || isHidden() || !column.userEditable)
    } else {
      setValid(!(column.required && isValidValue.length === 0) || isHidden() || !column.userEditable)
    }
  }, [value, updatedAt])


  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.value)
  }

  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  if (isColumn) {
    if (value && value !== 'registration') {
      return <td style={viewStyle}><Link to={"/admin/" + itemType + "/" + value}>{text}</Link></td>
    }
    return <td style={viewStyle}></td>
  }

  if (isView()) {
    if (value) {
      return <Link to={"/admin/" + itemType + "/" + value}>{text}</Link>
    }
    // You can only add registrations viwa this method

    return <Link to={"/admin/registration/new?parentRegistration=" + item.pk}>Add Secondary Item</Link>
  }


  // if (value) {
  //   return <div style={{marginTop: 3}}><Link to={"/admin/registration/" + item.pk}>Parent Registration</Link>    </div>
  // }

  // Really what we want to do here is allow a member relationship to be added.

  return (
    <div style={{ marginTop: 5 }}>
      <input
        className="usa-input"
        id={column.key}
        name="input-type-text"
        type="text"
        maxLength={column.maxLength}
        required={column.required}
        style={editStyle}
        value={value}
        onChange={onChange}
      />
      <span style={helpStyle}>
        Provide a member id (m.xxxx). There is no validation done on this value. Be careful.
      </span>
    </div>
  )

}
