import React, { Component } from 'react'

var md = require('markdown-it')({html:true})

class Bio extends Component {

  render() {
    const {bio} = this.props

    const bioText =bio.fields.bio ?? ''
    console.log('bioText', bioText)

    return (
      <section className="usa-section margin-y-0" style={{paddingTop:0, paddingBottom: 25, float: 'clear'}}>
        <div className="grid-container margin-y-0">
          <h3 className="margin-y-0">{bio.fields.name}</h3>
          <strong>{bio.fields.title}</strong>

          <div className="usa-prose">
            <img
              alt={bio.fields.name}
              style={{float: 'left', marginBottom: '20px', marginRight: '20px'}}
              src={bio.fields.headshot.fields.file.url}
              width="200"
            />
            <div style={{minHeight: 300}}>
              <span dangerouslySetInnerHTML={{ __html: md.renderInline(bioText) }}></span>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Bio
