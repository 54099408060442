import React, { useState, useEffect } from 'react'

export default function AdaChoices(props) {

  let { item } = props

  if (!item) { return null }

  const [adaAccomodation, setAdaAccomodation] = useState()
  const [hasChanged, setHasChanged] = useState(false)
  const [waitingForSave, setWaitingForSave] = useState(false)

  const style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }

  function handleAdaAccomodation(event) {
    setAdaAccomodation(event.target.value)
    setHasChanged(true)
  }

  function handleSave() {
    item.registrationAction(item.ACTION.ADA_ACCOMODATION, { adaAccomodation })
    setWaitingForSave()
    setHasChanged(false)
  }

  useEffect(() => {
    let itemZero = false
    try {
      itemZero = item.collection.segments[0].items[0]
    }
    catch {
      itemZero = false
    }

    if (itemZero) {
      if (waitingForSave) { setWaitingForSave(item.saveSuccessful) }
      setAdaAccomodation(itemZero.adaAccomodation)
    }
  }, [item])


  return (
    <>
      <p className="usa-prose">
        Please let us know your ADA related request.
      </p>

      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Accomodation</div></div>
        <div className="tablet:grid-col-8">
          <input
            id="dietaryRestrictions"
            name="input-type-text"
            type="text"
            maxLength="50"
            value={adaAccomodation}
            onChange={handleAdaAccomodation}
            style={{ marginRight: 20, width: '75%' }}
          />
        </div>
      </div>
      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> </div></div>
        <div className="tablet:grid-col-8">
          <button className="usa-button" disabled={!hasChanged} style={{ paddingLeft: 20, textDecoration: 'none' }} onClick={handleSave}>Save Request</button>
          {waitingForSave && <> Saving...</>}
        </div>
      </div>
    </>

  )
}
