import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'

export default function useRegistrationStatus(props) {

  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  async function loadData() {
    setIsErrored(false)
    setIsLoading(true)
    let response
    try {
      response = await API.get('general', '/registration-status', props.parameters)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      setIsErrored(true)
    }

    setData(response)
  }

  useEffect(() => {
    loadData()
  }, [props.parameters])

  return { isErrored, isLoading, data }
}
