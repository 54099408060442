import React, { useState, useEffect } from 'react'


export default function FieldBoolean(props) {

  let [value, setValue] = useState(props.initialValue)
  let [isValid, setValid] = useState(false)

  const { item, column,
    status,
    isColumn,
    updateField,
    toggleAdminVip,
    viewStyle, helpStyle } = props

  // Stores the value in the item and determines if the value is valid
  useEffect(() => {
    item[column.key] = value
    setValid(true)
  }, [value])

  // Passes the valid value to the parent
  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.checked)
  }

  function onToggle(event) {
    event.stopPropagation()
    setValue(!value)
    if (column.key == 'banquetVIP') {
      toggleAdminVip(item.pk)
    }
  }

  function isHidden() {
    if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
    // visibleForRegistrationTypes is included in the field definition
    // so always keep it hidden until the registration type is selected.
    if (!item.registrationType.pk) { return true }

    if (column.hiddenForRegistrationTypes) {
      return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
    }

    return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
  }

  if (column.fieldType !== 'boolean') { return null }

  // let fullStyle = Object.assign(viewStyle)
  //fullStyle.cursor = 'pointer'

  if (status === 'LOADED' || isColumn || !column.userEditable) {
    if (column.key == 'banquetVIP') {
      return <td style={{ cursor: "pointer" }} onClick={onToggle} >{value ? 'Yes' : 'No'}</td>
    }
    return <td style={viewStyle}>{value ? 'Yes' : 'No'}</td>
  }

  if (isHidden()) { return null }

  return (
    <td style={viewStyle}>
      <div className="usa-radio">
        <input
          className="usa-checkbox__input"
          id={column.key}
          type="checkbox"
          name={column.key}
          value={value}
          checked={value}
          onChange={onChange}
        />
        <label className="usa-checkbox__label" htmlFor={column.key} style={{ marginBottom: 0 }}
        ></label
        >
        {column.helpText &&
          <span style={helpStyle}>
            {column.helpText}
          </span>
        }
      </div>
    </td>
  )
}
