import React, {useEffect, useState} from 'react'
import { NavLink, useLocation } from "react-router-dom"

export default function Sidebar(props) {

  const {views} = props
  const [groupAdmin, setGroupAdmin] = useState(false)
  const [admin, setAdmin] = useState(false)

  let location = useLocation()

  useEffect(() => {
    if (props.user && props.user.signInUserSession.accessToken.payload["cognito:groups"] ) {
      setGroupAdmin(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('groupAdmin'))
      setAdmin(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('admins'))
    }
  }, [props.user])

  function isCurrent(route) {
    return  ("/admin" + route === location.pathname) ? 'usa-current' : ''
  }

  // Get a unique list of groups
  let groups = views.items.map(view => view.group)
    .filter((value, index, self) => {
        return self.indexOf(value) === index
      })

  return (
    <div style={{marginTop: 20}}>
      <nav>
        <ul className="usa-sidenav">
          <li key="999" className="usa-sidenav__item">
            General
            <div key="998" className="usa-sidenav__sublist">
            {admin &&
              <>
                <NavLink
                  className={isCurrent("")}
                  to={"/admin"}
                >
                  Dashboard
                </NavLink>
                <NavLink
                  className={isCurrent("/profile")}
                  to={"/admin/profile"}
                >
                  My Profile
                </NavLink>
                </>
            }
              {groupAdmin &&
                <NavLink
                  className={isCurrent("/account/groups")}
                  to={"/account/groups"}
                >
                  Group Admin
                </NavLink>
              }

                <NavLink
                  className={isCurrent("/account")}
                  to={"/account"}
                >
                  Personal Account
                </NavLink>
            </div>

          </li>
          {admin && groups.map((group, i) => {
            return (
              <li key={i * 10} className="usa-sidenav__item">
                {group}
                {views.items.filter(view => view.group === group)
                  .sort((a, b) => {
                    if (a.pageHeader > b.pageHeader) {return 1}
                    if (a.pageHeader < b.pageHeader) {return -1}
                    return 0
                  }).map((view, j) => {
                  return (
                    <div key={(i * 10) + j} className="usa-sidenav__sublist">
                      <NavLink
                        className={isCurrent(view.route)}
                        to={"/admin" + view.route}
                      >
                        {view.pageHeader}
                      </NavLink>
                    </div>
                  )
                })}

              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}
