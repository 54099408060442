import { useLocation } from "react-router-dom"
import Event from '../classes/Event'

export function PageLoad() {
  const {pathname} = useLocation();

  if (document.visibilityState === 'visible') {
    Event.add('page-view', {url: pathname})
  }
  return null;
}

export function SponsorView(props) {

  const {sponsorName} = props
  const {pathname} = useLocation();

  const data = {
    sponsorName: sponsorName,
    url: pathname
  }
  if (document.visibilityState === 'visible') {
    Event.add('sponsor-view', data)
  }
  return null;
}

export function sponsorClick(props) {

  const {sponsorName} = props

  const data = {
    sponsorName: sponsorName,
  }
  if (document.visibilityState === 'visible') {
    Event.add('sponsor-click', data)
  }
  return null;
}

export function externalLinkClick(props) {

  const {url} = props

  const data = {
    url: url,
  }
  if (document.visibilityState === 'visible') {
    Event.add('external-link-click', data)
  }
  return null;
}

export function sessionClick(props) {

  const {session, live} = props

  const data = {
    url: session,
    live: live
  }
  if (document.visibilityState === 'visible') {
    Event.add('session-link-click', data)
  }
  return null;
}
