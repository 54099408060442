import React, { useState } from 'react'
import useCollection from '../admin/useCollection'
import {
  //  Link,
  useParams
} from "react-router-dom";
import useList from '../admin/useList'
import MembershipCreate from '../convention2024/MembershipCreate'
import MembershipReceipt from '../convention2024/MembershipReceipt'
import Panel from '../admin/Panel'

import useQuery from '../admin/useQuery'

export default function Membership(props) {

  const { itemType, id } = useParams()
  const [membershipForm, setMembershipForm] = useState(false)

  const query = useQuery()

  if (itemType !== 'membership') {
    if (!(itemType === 'receipt' && query.get("memberships"))) {
      return null
    }
  }

  const formProps = { stopAfterPage: 999, pageSize: 1000, source: "/list/quickform", items: [] }
  const form = useList(formProps)
  const item = useCollection({ itemType: itemType, id: id ?? 'new' })

  if (form.items.length > 0 && (!membershipForm || (membershipForm && membershipForm.pkType !== itemType))) {
    setMembershipForm(form.items.filter(i => i.pkType === itemType)[0])
  }

  if (item.collection.segmentCount === 0) {
    return null
  }

  let itemZero
  try {
    itemZero = item.collection.segments[0].items[0]
  }
  catch {
    itemZero = false
  }

  let style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }

  return (
    <>
      <MembershipCreate item={item} membershipForm={membershipForm} user={props.user} />
      <MembershipReceipt item={item} membershipForm={membershipForm} />

      {itemType === 'membership' && membershipForm && item.status !== 'EDITING' &&
        <div className="grid-container margin-y-0" >
          <>
            {form.items.length === 0 &&
              <tr ><td style={{ borderWidth: 0 }}><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i></td></tr>
            }


            {query.get("success") === "true" &&
              <div className="usa-alert usa-alert--success" style={{ marginTop: 20 }}>
                <div className="usa-alert__body">
                  <h4 className="usa-alert__heading">Your membership has been created</h4>
                  <p className="usa-alert__text">
                    An email is on its way with your receipt.
                  </p>
                  <p className="usa-alert__text">
                    Now that you are a member, please <a href={"/account/member/" + itemZero.gsi3_pk + "?edit=true"}> update your contact and profile information</a>.
                  </p>
                </div>
              </div>
            }


            {item && item.status === 'LOADED' &&
              <div className="grid-container margin-y-0" style={{ width: 1000 }}>
                <Panel title="Membership Information">
                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Start Date</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.startDate}</div>
                  </div>

                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> End Date</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.endDate}</div>
                  </div>

                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Price</div></div>
                    <div className="tablet:grid-col-8"> ${itemZero && itemZero.membershipType && itemZero.membershipType.price}</div>
                  </div>

                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Membership Type</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.membershipType && itemZero.membershipType.name}</div>
                  </div>

                  <div className="grid-row margin-y-1">
                    <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Status</div></div>
                    <div className="tablet:grid-col-8"> {itemZero && itemZero.membershipType && itemZero.statusName}</div>
                  </div>

                </Panel>
              </div>
            }
          </>
        </div>
      }
    </>
  )

}
