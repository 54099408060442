import React from 'react'
import Panel from "../Panel"

export default function SearchTerm(props) {

  // let style = active ?
  //   {
  //     textDecoration: 'none', cursor: 'wait', disabled: true,
  //     paddingRight: 20
  //   } :
  //   {
  //     textDecoration: 'none', cursor: 'pointer',
  //     paddingRight: 20
  //   }

  const { isLoading, isErrored, items } = props.results

  return (
    <Panel title="Results" style={{ width: '95%' }}>
      {isLoading && <i className="fas fa-circle-notch fa-spin"></i>}
      {isErrored && <div>There was an error</div>}
      {!isLoading && !isErrored &&
        <>
          <p>There are {items.length} matches.</p>
          {items.map(item => {
            return (
              <div key={item.id}>
                <span style={{ fontSize: 'x-large' }}> <a href={item.url}>{item.fullName}</a></span> <span style={{ paddingLeft: 10 }}> <i className="far fa-envelope"></i> {item.primaryEmail} [{item.score ? item.score : <></>}]</span>
                <p style={{ marginTop: 0, marginBottom: 0, fontStyle: 'italic' }}>  <a href={item.group}><i className="fad fa-bars"></i> {item.description}</a></p>
                <p style={{ marginTop: 0, marginBottom: 0 }}>{item.additionalInformation}</p>
                <p style={{ marginTop: 0, fontSize: 'smaller' }}>Created: {item.createdAt ?? 'not available'} Updated: {item.updatedAt ?? 'not available'}</p>
              </div>
            )
          })}
        </>
      }
    </Panel>
  )
}
