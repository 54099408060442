import React, { useState, useEffect } from 'react'
// import { PieChart, Pie, Legend } from 'recharts'
import NumberFormat from 'react-number-format'
import { Link } from "react-router-dom"

import Panel from "../Panel"
import Table from '../Table'
import TableHeadings from '../TableHeadings'
import TableBody from '../TableBody'


export default function Summary(props) {

  let [membershipCounts, setMembershipCounts] = useState(props.membershipCounts)

  useEffect(() => {
    setMembershipCounts(props.membershipCounts)
  }, [props.membershipCounts])

  return (
    <Panel title="Memberships">
      <div>
        <Link className="usa-button" to="/admin/membership/new">
          Add Membership
        </Link>
        <Link
          className="usa-tooltip usa-button"
          disabled={membershipCounts.isLoading && membershipCounts.items.length === 0}
          title="Reload Counts"
          onClick={() => membershipCounts.resetData()}
        >
          <i className="fas fa-redo-alt"></i>
        </Link>
        <span style={{ paddingRight: 10 }} />
        {membershipCounts.isLoading &&
          <i className="fas fa-circle-notch fa-spin"></i>
        }
        {membershipCounts.items.length > 0 &&
          <span>Last Refreshed {membershipCounts.loadedTime.format('YYYY-MM-DD h:mm a')}</span>
        }
        <span style={{ paddingRight: 10 }} />
        {membershipCounts.isErrored &&
          <span style={{ color: '#b50908' }}><i className="fas fa-exclamation"></i> An error occurred loading the data</span>
        }
      </div>

      {membershipCounts.items.length > 0 && !membershipCounts.isErrored &&
        <>
          <div style={{ width: '50%', display: 'inline-block' }}>
            <Table>
              <TableHeadings>
                <tr>
                  <th style={{ width: '75%' }}>Status</th>
                  <th>Count</th>
                </tr>
              </TableHeadings>
              <TableBody>
                {membershipCounts.items[0].table.map((item, i) =>
                  <tr key={i}>
                    <td>{item.label}</td>
                    <td style={{ textAlign: 'right', paddingRight: '10%' }}>
                      <NumberFormat value={item.count} displayType="text" thousandSeparator={true} />
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
            <div>There are <NumberFormat value={membershipCounts.items[0].lifeMembers} displayType={'text'} thousandSeparator={true} /> Life Members</div>
          </div>
        </>
      }
    </Panel>

  )
}
//
// <PieChart width={800} height={300}>
//  <Legend />
//    <Pie
//      data={membershipCounts.items[0].pieChart}
//      dataKey="value"
//      cx={200}
//      cy={100}
//      startAngle={180}
//      endAngle={-180}
//      outerRadius={100}
//      label
//    >
//    </Pie>
//  </PieChart>
