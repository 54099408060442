import React, { useState, useEffect } from 'react'

export default function FieldLongitude(props) {

  let [value, setValue] = useState(props.initialValue)
  let [isValid, setValid] = useState(false)

  const {item, column,
         status,
         updateField,
         editStyle, viewStyle, helpStyle} = props

  // Stores the value in the item and determines if the value is valid
  useEffect(() => {
    item[column.key] = value

    if (Number.isNaN()) {
      setValid(false)
    } else {
      // It is a number, decide if it is too big or small
      setValid(value <= 180 && value >= -180)
    }
  }, [value])

  // Passes the valid value to the parent
  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.value)
  }

  if (status === 'LOADED' || !column.userEditable) {
    return <td style={viewStyle}>{item[column.key]}</td>
  }

  let className = isValid ? 'usa-input' : 'usa-input usa-input--error'

  return (
    <div style={{marginTop: 5}}>
      <input
        className={className}
        id={column.key}
        name="input-type-text"
        type="text"
        maxLength={column.maxLength}
        required={column.required}
        style={editStyle}
        value={value}
        onChange={onChange}
      />
        {column.helpText &&
          <span style={helpStyle}>
            {column.helpText}
          </span>
        }
    </div>
  )
}
