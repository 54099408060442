import { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
var moment = require('moment')

export default function useSummary(props) {

  const [items, setItems] = useState(props.items)
  const [continuationToken, setContinuationToken] = useState(props.lastEvaluatedKey ?? null)
  const [pagesLoadedCount, setPagesLoadedCount] = useState(props.pagesLoadedCount ?? 0)
  const [isLoading, setIsLoading] = useState(false)
  const [isErrored, setIsErrored] = useState(false)
  const [moreData, setMoreData] = useState(true)
  const [loadedTime, setLoadedTime] = useState(moment())

  function resetData() {
    setItems([])
    setContinuationToken(null)
    setPagesLoadedCount(0)
  }

  async function loadData() {

    let response
    if ((continuationToken || items.length === 0)) {
      const parameters = {queryStringParameters: {}}
      if (continuationToken) {parameters.queryStringParameters.c = continuationToken}
      if (props.pageSize) {parameters.queryStringParameters.p = Number(props.pageSize)}

      setIsErrored(false)
      setIsLoading(true)
      try {
        response = await API.get('summary', props.source, parameters)
        setMoreData((response.nextContinuationToken !== null || response.items.length === 0))
        setItems(items.concat(response.items))
        setPagesLoadedCount(pagesLoadedCount + 1)
        setLoadedTime(moment())
        setContinuationToken(response.nextContinuationToken)
        setIsLoading(false)
      }
      catch {
        setIsLoading(false)
        setIsErrored(true)
      }
    }
  }

  useEffect(() => {
    loadData()
}, [continuationToken, props.stopAfterPage])

  return {isErrored, isLoading, moreData, items, pagesLoadedCount, resetData, continuationToken, loadedTime}
}
