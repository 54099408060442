import React, { useEffect, useState } from 'react'
import { PageHeader } from '../Section'
import useQuery from "../useQuery"
import useSearch from "../useSearch"

import PanelSearchTerm from './PanelSearchTerm'
import PanelSearchResults from './PanelSearchResults'

export default function Search(props) {

  const query = useQuery()
  const target = query.get('target')
  const [admin, setAdmin] = useState(false)
  const results = useSearch({ target: target })

  useEffect(() => {
    if (props.user && props.user.signInUserSession.accessToken.payload["cognito:groups"]) {
      setAdmin(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes('admins'))
    }
  }, [props.user])

  return (
    <>
      {admin &&
        <>
          <div style={{ width: '95%' }}>
            <PageHeader title="Search"
              description="" />
          </div>

          <PanelSearchTerm term={target} results={results} />
          <PanelSearchResults results={results} />

        </>
      }
    </>
  )
}
