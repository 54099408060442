import React from 'react'
import {useParams} from "react-router-dom"

import useList from '../admin/useList'
import useCollection from '../admin/useCollection'
import Collection from '../admin/Collection'

export default function Member() {

  const {itemType, id, subId} = useParams()

  const formProps = {stopAfterPage: 999, pageSize: 1000, source: "/list/form", items: []}
  const entity = useCollection({itemType, id, subId})
  const form = useList(formProps)

  if (itemType !== 'member')  { return null}

  return (
    <Collection collection={entity.collection} status={entity.status} previousStatus={entity.previousStatus} setStatus={entity.setStatus} form={form} />
  )
  
}
