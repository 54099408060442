import React, { Component } from 'react';

import {ContentfulContext} from '../classes/context'
import ErrorContainer from './ErrorContainer';
import Loading from './Loading';

class Atq extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: null,
    };
  }

  componentDidMount() {
    const contentfulClient = this.context.contentfulClient

    contentfulClient.getEntries({'content_type': 'atq', 'order': '-fields.volume'})
    .then(entries => {

      let items = []
      let index
      entries.items.map((item) => {
        index = items.findIndex(volume => volume.volume === item.fields.volume)
        if (index === -1) {
          let volume = {volume: item.fields.volume, Winter: null, Spring: null, Summer: null, Fall: null}
          volume[item.fields.edition.split(' ')[0]] = item.fields
          items.push(volume)
        } else {
          items[index][item.fields.edition.split(' ')[0]] = item.fields
        }
        return null
      })
      this.setState({
        isLoaded: true,
        items: items
      })
    })
    .catch(error => {
      this.setState({
        error: error
      })
    })
  }

    render() {

      const { error, isLoaded, items} = this.state;

      if (error) {
        return <ErrorContainer title="Error" error={error} />
      } else if (!isLoaded) {
        return <Loading title=""
                        subtitle=""/>
      }

      return (
        <main id="main-content">
          <section className="usa-section" style={{paddingTop: 10}}>
            <div className="grid-container margin-y-0">
            <h2 className="margin-y-0">A/TQ Magazine</h2>
            {items.map(volume =>
              <>
              <h3>{volume.volume}</h3>
              <span>
              {volume.Winter &&
                <div style={{display: 'inline-block', marginRight: '20px'}}>
                  <a href={volume.Winter.documentUrl}> <div style={{maxWidth:'200px'}}><img alt={volume.Winter.edition} src={volume.Winter.coverImage} /></div>
                  <div>{volume.Winter.edition}</div>
                  </a>
                </div>
              }
              {volume.Spring &&
                <div style={{display: 'inline-block', marginRight: '20px'}}>
                  <a href={volume.Spring.documentUrl}> <div style={{maxWidth:'200px'}}><img alt={volume.Spring.edition} src={volume.Spring.coverImage} /></div>
                  <div>{volume.Spring.edition}</div>
                  </a>
                </div>
              }
              {volume.Summer &&
                <div style={{display: 'inline-block', marginRight: '20px'}}>
                  <a href={volume.Summer.documentUrl}> <div style={{maxWidth:'200px'}}><img alt={volume.Summer.edition} src={volume.Summer.coverImage} /></div>
                  <div>{volume.Summer.edition}</div>
                  </a>
                </div>
              }
              {volume.Fall &&
                <div style={{display: 'inline-block', marginRight: '20px'}}>
                  <a href={volume.Fall.documentUrl}> <div style={{maxWidth:'200px'}}><img alt={volume.Fall.edition} src={volume.Fall.coverImage} /></div>
                  <div>{volume.Fall.edition}</div>
                  </a>
                </div>
              }
              </span>
              </>
            )}
          </div>
        </section>
        </main>
      )
    }

}

Atq.contextType = ContentfulContext
export default Atq
