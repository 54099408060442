import React from 'react'
import { API } from 'aws-amplify'
import useRegistrationStatus from '../admin/useRegistrationStatus'


export default function Hotel(props) {

  let { item } = props
  const registrationStatus = useRegistrationStatus({ source: '/registrationStatus' })

  async function handleClick(roomCode) {
    console.log('roomCode', roomCode)
    let body = {
      pk: item.collection.segments[0].items[0].pk
    }

    let response = await API.put('hotel', "/hotel", { body })

    let toHotel = 'https://api.passkey.com/httpapi/RegLink?' +
      'PartnerID=10567978&' +
      'OP=GetForRedirectResWeb&' +
      'Version=4.00.02&' +
      'Mode=S&' +
      'Destination=02&' +
      'LastName=' + response.lastName + '&' +
      'BridgeID=' + response.bridgeId + '&' +
      'Locale=EN_US'

    item.registrationAction(item.ACTION.HOTEL_STARTED_BOOKING)
    window.open(toHotel)
  }

  return (
    <div>
      {registrationStatus.data.directSinglesToOverflow &&
        <a className="usa-button"
          target="_default"
          style={{ paddingLeft: 20, textDecoration: 'none', color: 'white', marginBottom: 10 }}
          href={registrationStatus.data.overflowHotelLink}
        >
          <span style={{ color: 'white' }}>Begin Room Booking</span>
        </a>
      }

      {!registrationStatus.data.directSinglesToOverflow &&
        <div className="usa-button"
          style={{ paddingLeft: 20, textDecoration: 'none', color: 'white', marginBottom: 10 }}
          target="_default"
          onClick={() => handleClick(item.collection.segments[0].items[0].pk)}
        >
          <span style={{ color: 'white' }}>Begin Room Booking</span>
        </div>
      }
    </div>
  )
}
