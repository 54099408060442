import React, { useState, useEffect } from 'react'
// import { PieChart, Pie, Legend } from 'recharts'

import Panel from "../Panel"

export default function Summary(props) {

  let [registrationStatus, setRegistrationStatus] = useState({})

  useEffect(() => {
    if (props.registrationStatus && props.registrationStatus.data) {
      setRegistrationStatus(props.registrationStatus.data)
    }
  }, [props.registrationStatus])

  return (
    <Panel title="Registration Status">
      <div>
        <p>Convention Status: {registrationStatus.registrationActive ? 'Registration Active' : 'Registration Inactive'} </p>
        <p>Convention Year: {registrationStatus.registrationYear} </p>
      </div>
    </Panel>
  )
}
