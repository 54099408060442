
import React, { useState, useEffect, useRef } from 'react'

export default function FieldSComment(props) {

  let [value, setValue] = useState(props.initialValue)
  let [isValid, setValid] = useState(false)

  const displayRef = useRef(null);

  const { item, column,
    status,
    updateField, updatedAt,
    isColumn,
    editStyle, viewStyle, helpStyle } = props

  function isView() {
    return status === 'LOADED' || !column.userEditable || isColumn
  }

  useEffect(() => {
    if (!displayRef.current || isColumn) { return }

    if (value) {
      displayRef.current.innerHTML = `<span>${value.replace(/\r?\n/g, '<br />')}</span>`
    } else {
      displayRef.current.innerHTML = `<span></span>`
    }
  }, [displayRef, value, status])

  function isHidden() {
    if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
    // visibleForRegistrationTypes is included in the field definition
    // so always keep it hidden until the registration type is selected.
    if (!item.registrationType.pk) { return true }

    if (column.key === 'role') {
      // Military Service Rank is only visible if the Service Status is not NONE
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
    }

    if (column.key === 'organization') {
      // organization is visible if the Service Status is not None
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        //
        return true
      }
      // If the service is Air Force and the MAJCOM is AMC don't show
      if (item.militaryServiceRank.name &&
        (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force'))) {
        if (item.majcom && item.majcom.pk === "z.3cccab1b-a844-4ce2-a5e3-801129abc531") {
          return true
        }
      }
    }

    if (column.hiddenForRegistrationTypes) {
      return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
    }

    return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
  }

  // Stores the value in the item and determines if the value is valid
  useEffect(() => {
    if (!isView()) {
      let itemPath = column.key.split('.')
      if (itemPath.length === 1) {
        item[column.key] = value
      } else {
        item[itemPath[0]][itemPath[1]] = value
      }
    }
    const isValidValue = value ?? ''
    if (column.fieldType === 'string' && column.maxLength) {
      setValid(!(column.required && isValidValue.length === 0 && isValidValue.length <= column.maxLength) || isHidden() || !column.userEditable)
    } else {
      setValid(!(column.required && isValidValue.length === 0) || isHidden() || !column.userEditable)
    }
  }, [value, updatedAt])

  // Passes the valid value to the parent
  useEffect(() => {
    updateField(column.key, isValid)
  }, [isValid])

  // sets the local value of the item
  function onChange(event) {
    setValue(event.target.value)
  }

  // This is specific to table assignments that happen in the context of the list and change values
  // which typically only happens in edit mode and through the controlled component.
  useEffect(() => {
    if (column.key === 'table') {
      if (value !== item[column.key]) {
        setValue(item[column.key])
      }
    }
  }, [item.table])


  if (isView()) {
    if (isColumn) {
      if (value) { return (value.substring(0, 12) + '...') }
    }
    return <td style={viewStyle}><span ref={displayRef} /></td>
  }

  if (isHidden()) { return null }

  let className = isValid ? 'usa-input' : 'usa-input usa-input--error'

  return (
    <div style={{ marginTop: 5 }}>
      <textarea
        className={className}
        id={column.key}
        name="input-type-text"
        type="textarea"
        maxLength={column.maxLength}
        required={column.required}
        style={{ editStyle, height: 100 }}
        value={value}
        onChange={onChange}
      />
      {column.helpText &&
        <span style={helpStyle}>
          {column.helpText}
        </span>
      }
    </div>
  )
}
