import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { Redirect } from "react-router-dom"

function RegistrationIntroduction(props) {

  const { registrationStatus } = props
  const [isValid, setIsValid] = useState(false)
  const [message, setMessage] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')

  function handleChange(event) {

    const target = event.target
    const name = target.name

    switch (name) {
      case "emailAddress":
        setEmailAddress(target.value)
        break
      case "confirmationCode":
        setConfirmationCode(target.value)
        break
      default:
      // do nothing
    }

  }


  async function handleClick() {
    setIsActive(true)
    setMessage(false)
    let response
    try {
      response = await API.put('lookup', '/registrationLookup', { body: { emailAddress: emailAddress.trim().toLowerCase(), confirmationCode: confirmationCode.trim().toLowerCase() } })
      if (response.registrationId) {
        setRedirect("/individual/registration/" + response.registrationId)
      } else {
        setMessage('There is no match for that combination of confirmation code and email address.')
      }
      setIsActive(false)
    }
    catch {
      setIsActive(false)
      setMessage('There was a general error. Check your confirmation code and email address and try again.')
    }
  }

  useEffect(() => {
    setIsValid(emailAddress.length > 0 && confirmationCode.length > 0)
  }, [confirmationCode, emailAddress])

  if (redirect) {
    return (<Redirect push={true} to={redirect} />)
  }

  return (
    <>
      <section style={{ marginLeft: -30 }}>
        <div className="grid-container margin-y-0">
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-6">

              <h3>Members</h3>
              <p>Members save on registrations. <a href="/account">Sign In</a> to your A/TA account to get the member rates.</p>
              {registrationStatus.stripeLink1 &&
                <>
                  <p>For Non-DoD employees - buy a <a href={registrationStatus.stripeLink1}><span style={{ "white-space": "nowrap" }} >Membership + Registration</span> </a> together and save.</p>
                  <p>Using a government payment card to register? You must pay for your membership separately. <a href="/account">Sign In or Sign Up</a> to save.</p>
                </>
              }
            </div>
            <div className="tablet:grid-col-6">
              <h3>Manage An Existing Registration</h3>
              <p className="usa-prose">To add a guest or manage an existing registration, start with your email address and confirmation code. You can find your confirmation code on your receipt or the welcome email we sent you at the time of registration.</p>
              <p>
                <input
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  maxLength={50}
                  required={true}
                  value={emailAddress}
                  onChange={handleChange}
                  onPaste={handleChange}
                  placeholder="Attendee email address"
                />

                <input
                  id="confirmationCode"
                  name="confirmationCode"
                  type="string"
                  maxLength={50}
                  required={true}
                  style={{ marginLeft: 20, maxWidth: 200 }}
                  value={confirmationCode}
                  onChange={handleChange}
                  onPaste={handleChange}
                  placeholder="Confirmation code"
                />
              </p>
              {message &&
                <div className="usa-alert usa-alert--error usa-alert--slim" style={{ marginBottom: 20, color: 'black' }}>
                  <div className="usa-alert__body">
                    <p className="usa-alert__text" style={{ color: 'black' }}>
                      {message}
                    </p>
                  </div>
                </div>
              }
              <button
                href="/individual/registration"
                title="Find My Registration"
                aria-label="find"
                className="usa-button"
                disabled={!isValid || isActive}
                onClick={handleClick}
                style={{ textDecoration: 'none', marginBottom: 20 }}
              >
                Find My Registration {isActive && <i className="fas fa-circle-notch fa-spin"></i>}
              </button>

            </div>
          </div>
        </div>

      </section>
      {registrationStatus && registrationStatus.stripePublicKey && registrationStatus.stripePricingTables[0] &&
        <section style={{ marginLeft: -30 }}>
          <div className="grid-container margin-y-0">
            <h2>Registration Options for Military and Government Attendees</h2>
          </div>
          <div>
            <stripe-pricing-table pricing-table-id={registrationStatus.stripePricingTables[0]}
              publishable-key={registrationStatus.stripePublicKey}>
            </stripe-pricing-table>
          </div>
          <hr style={{ color: "#eeeeee" }} />

        </section>
      }
      {registrationStatus && registrationStatus.stripePublicKey && registrationStatus.stripePricingTables[1] &&

        <section style={{ marginLeft: -30 }}>
          <div className="grid-container margin-y-0">
            <h2>Registration Options for Non-DoD Attendees</h2>
          </div>
          <div style={{ marginLeft: '-20%', marginRight: '-20%' }}>
            <stripe-pricing-table pricing-table-id={registrationStatus.stripePricingTables[1]}
              publishable-key={registrationStatus.stripePublicKey}>
            </stripe-pricing-table>
          </div>

        </section>
      }
    </>
  )
}


export default RegistrationIntroduction;
