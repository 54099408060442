import React, { useState } from 'react'
import { API } from 'aws-amplify'
import FileSaver from 'file-saver'

import Panel from "../Panel"

export default function Summary() {

  const [active, setActive] = useState(false);

  let style = active ?
    {
      textDecoration: 'none', cursor: 'wait', disabled: true,
      paddingRight: 20
    } :
    {
      textDecoration: 'none', cursor: 'pointer',
      paddingRight: 20
    }

  return (
    <Panel title="Reports" >
      <div className="grid-container" style={{ paddingLeft: 0 }}>
        <div className="grid-row">
          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('industryPartnerPoc', active, setActive, 1)}>
              Industry Partner POCs <i className="fal fa-file-csv"></i> {active === 1 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>

          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('conventionAttendees', active, setActive, 4)}>
              Convention Attendees <i className="fal fa-file-csv"></i> {active === 4 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>

          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('industryDayAttendees', active, setActive, 2)}>
              Fall Industry Panels Attendees <i className="fal fa-file-csv"></i> {active === 2 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>
          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('conventionDvs', active, setActive, 3)}>
              Convention DVs <i className="fal fa-file-csv"></i> {active === 3 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>
          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('guestRegistrations', active, setActive, 5)}>
              Guests By Day <i className="fal fa-file-csv"></i> {active === 5 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>
          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('conventionFees', active, setActive, 6)}>
              Convention Fees <i className="fal fa-file-csv"></i> {active === 6 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>
          <div className="tablet:grid-col-4">
            <a className="usa-button--unstyled" style={style} onClick={() => requestCsv('membershipList', active, setActive, 7)}>
              Membership List <i className="fal fa-file-csv"></i> {active === 7 && <i className="fas fa-circle-notch fa-spin"></i>}
            </a>
          </div>
        </div>
      </div>
    </Panel>
  )
}

/* eslint-disable max-params */
async function requestCsv(target, active, setActive, reportNumber) {

  if (active) { return }
  setActive(reportNumber)
  let response = await API.get('summary', "/summary/" + target, {})
  var blob = new Blob([response], { type: "text/csv;charset=utf-8" })
  FileSaver.saveAs(blob, target + '.csv')

  setActive(false)
}
