import React from 'react'

import Field from "./Field"

export default function Column(props) {

  function updateField() {
    // do nothing
  }
  return (
    <Field {...props} updateField={updateField} isColumn={true} />
  )
}
