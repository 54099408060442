import './App.scss'
import './badge.css'
import { Amplify, Auth } from 'aws-amplify'
import React, { Component } from 'react'
import Routes from './public/Routes'
import aws_exports from './aws-exports'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import AdminRoutes from "./admin/AdminRoutes"
import BadgeRoutes from "./badge/BadgeRoutes"
import Account from './account/Account'
import Signup from './signup/Signup'
// App.jsx
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.0/cdn/');

aws_exports.authenticationFlowType = 'USER_PASSWORD_AUTH'

aws_exports.aws_cloud_logic_custom[aws_exports.aws_cloud_logic_custom.findIndex(i => i.name === 'view')].custom_header = async () => {
  return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
}
aws_exports.aws_cloud_logic_custom[aws_exports.aws_cloud_logic_custom.findIndex(i => i.name === 'list')].custom_header = async () => {
  return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
}
aws_exports.aws_cloud_logic_custom[aws_exports.aws_cloud_logic_custom.findIndex(i => i.name === 'summary')].custom_header = async () => {
  return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
}
aws_exports.aws_cloud_logic_custom[aws_exports.aws_cloud_logic_custom.findIndex(i => i.name === 'item')].custom_header = async () => {
  return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
}
aws_exports.aws_cloud_logic_custom[aws_exports.aws_cloud_logic_custom.findIndex(i => i.name === 'stripe')].custom_header = async () => {
  return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
}


Amplify.configure(aws_exports)

class App extends Component {
  render() {

    return (
      <Router>
        <Switch>
          <Route exact path={["/admin/:itemType/:id/:subId", "/admin/:itemType/:id", "/admin/:itemType", "/admin"]}  >
            <AdminRoutes />
          </Route>
          <Route path={["/badge/:id", "/badge-page"]}  >
            <BadgeRoutes />
          </Route>
          <Route exact path={["/signup"]}  >
            <Signup />
          </Route>
          <Route exact path={["/account/membership", "/account/registration", "/account/groups", "/account/:itemType/:id", "/account/:itemType/:id/:subId", "/account/profile", "/account"]}  >
            <Account />
          </Route>
          <Route path="*">
            <Routes
              redirect={false}
              authState={false}
              signOut={() => this.onStateChange("signOut")}
              onStateChange={authState => this.onStateChange(authState)}
            />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default App
