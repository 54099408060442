import React, { useState, useEffect } from 'react'

export default function BanquetChoices(props) {

  let { item } = props

  if (!item) { return null }

  const [includesBanquet, setIncludesBanquet] = useState(false)
  const [banquetWillAttend, setBanquetWillAttend] = useState()
  const [banquetDietaryRestrictions, setBanquetDietaryRestrictions] = useState()
  const [banquetSeatingRequest, setBanquetSeatingRequest] = useState()
  const [hasChanged, setHasChanged] = useState(false)
  const [waitingForSave, setWaitingForSave] = useState(false)

  const style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }


  function handleDietChange(event) {
    setBanquetDietaryRestrictions(event.target.value)
    setHasChanged(true)
  }

  function handleSeatingChange(event) {
    setBanquetSeatingRequest(event.target.value)
    setHasChanged(true)
  }

  function handleBanquetWillAttend(event) {
    setBanquetWillAttend(event.target.checked)
    setHasChanged(true)
  }

  function handleSave() {
    item.registrationAction(item.ACTION.BANQUET_CHOICES, { banquetWillAttend, banquetDietaryRestrictions, banquetSeatingRequest })
    setWaitingForSave()
    setHasChanged(false)
  }

  useEffect(() => {
    let itemZero = false
    try {
      itemZero = item.collection.segments[0].items[0]
    }
    catch {
      itemZero = false
    }

    if (itemZero) {
      if (waitingForSave) { setWaitingForSave(item.saveSuccessful) }
      setBanquetWillAttend(itemZero.banquetWillAttend)
      setBanquetDietaryRestrictions(itemZero.banquetDietaryRestrictions)
      setBanquetSeatingRequest(itemZero.banquetSeatingRequest)
      setIncludesBanquet(itemZero.registrationType.banquetIncluded)
    }
  }, [item])

  if (!includesBanquet) { return null }



  return (
    <>
      <div className="usa-prose">
        The <strong>Hall of Fame Banquet</strong> is the formal closing of the convention.
        This year the banquet will be held on Saturday November, 2nd. We look
        forward to your attendance, but we understand that travel or other activities may prevent you from participating.
        Please help us plan for a great banquet by letting us know whether you will attend this year’s banquet.

      </div>

      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> </div></div>
        <div className="tablet:grid-col-8">
          <input
            className="usa-checkbox__input"
            id="banquetWillAttend"
            type="checkbox"
            name="banquetWillAttend"
            checked={banquetWillAttend}
            onChange={handleBanquetWillAttend}
          />
          <label className="usa-checkbox__label" htmlFor="banquetWillAttend" style={{ marginBottom: 20, marginTop: 10, marginLeft: 20 }}
          >I Will Attend the Banquet</label
          >
        </div>
      </div>

      <div className="usa-prose">
        For those attending, please let us know your seating preferences and dietary restrictions, if any.
      </div>
      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Dietary Restrictions</div></div>
        <div className="tablet:grid-col-8">
          <input
            id="dietaryRestrictions"
            name="input-type-text"
            type="text"
            maxLength="50"
            value={banquetDietaryRestrictions}
            onChange={handleDietChange}
            style={{ marginRight: 20, width: '75%' }}
          />
        </div>
      </div>
      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Seating Preference</div></div>
        <div className="tablet:grid-col-8">
          <input
            id="banquetSeatingRequest"
            name="input-type-text"
            type="text"
            maxLength="50"
            value={banquetSeatingRequest}
            onChange={handleSeatingChange}
            style={{ marginRight: 20, width: '75%' }}
          />
        </div>
      </div>
      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> </div></div>
        <div className="tablet:grid-col-8">
          <button className="usa-button" disabled={!hasChanged} style={{ paddingLeft: 20, textDecoration: 'none' }} onClick={handleSave}>Save Banquet Preferences</button>
          {waitingForSave && <> Saving...</>}
        </div>
      </div>
    </>

  )
}
