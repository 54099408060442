import React from 'react'

export default function Panel(props) {
  return (
    <div style={{ display: "block", paddingLeft: 0, borderStyle: 'solid', borderColor: '#eeeeee', borderRadius: 10, padding: 10, marginTop: 20 }}>
      {props.title &&
        <h3 style={{ marginTop: 5, borderBottomStyle: 'solid' }}>{props.title}</h3>
      }
      {props.children}
    </div>
  )
}
