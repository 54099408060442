import React, { useState, useEffect } from 'react'
import Badge from '../badge/Badge'

export default function BanquetChoices(props) {

  let { item } = props

  if (!item) { return null }

  const [badgeNickname, setBadgeNickname] = useState()
  const [organization, setOrganization] = useState()
  const [hasChanged, setHasChanged] = useState(false)
  const [waitingForSave, setWaitingForSave] = useState(false)
  const [showBadgePreview, setShowBadgePreview] = useState(false)
  const [pk, setPk] = useState(false)

  const style = { color: 'grey', textAlign: 'end', paddingRight: 10, marginTop: -1 }
  let helpStyle = { fontSize: 'smaller', color: 'grey', fontWeight: 'light' }

  function handleBadgeNickname(event) {
    setBadgeNickname(event.target.value)
    setHasChanged(true)
  }
  function handleOrganizationChange(event) {
    setOrganization(event.target.value)
    setHasChanged(true)
  }

  function handleSave() {
    item.registrationAction(item.ACTION.BADGE_CHOICES, { badgeNickname, organization })
    setWaitingForSave()
    setHasChanged(false)
  }

  useEffect(() => {
    let itemZero = false
    try {
      itemZero = item.collection.segments[0].items[0]
    }
    catch {
      itemZero = false
    }

    if (itemZero) {
      if (waitingForSave) { setWaitingForSave(item.saveSuccessful) }
      setBadgeNickname(itemZero.badgeNickname)
      setOrganization(itemZero.organization)
      setPk(itemZero.pk)
    }
  }, [item])


  return (
    <>
      <p className="usa-prose">
        Your Convention Badge will show information that you provided in your registration. If you want to customize your badge, please update below.
      </p>

      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Nickname</div></div>
        <div className="tablet:grid-col-8">
          <input
            id="dietaryRestrictions"
            name="input-type-text"
            type="text"
            maxLength="50"
            value={badgeNickname}
            onChange={handleBadgeNickname}
            style={{ marginRight: 20, width: '75%' }}
          />
          <div style={helpStyle}>
            Nicknames are reviewed by our staff before being included on your badge.
          </div>
        </div>
      </div>
      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> Organization</div></div>
        <div className="tablet:grid-col-8">
          <input
            id="dietaryRestrictions"
            name="input-type-text"
            type="text"
            maxLength="50"
            value={organization}
            onChange={handleOrganizationChange}
            style={{ marginRight: 20, width: '75%' }}
          />
        </div>
      </div>
      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"><div className="usa-prose" style={style}> </div></div>
        <div className="tablet:grid-col-8">
          <button className="usa-button" disabled={!hasChanged} style={{ paddingLeft: 20, textDecoration: 'none' }} onClick={handleSave}>Save Badge Preferences</button>
          {waitingForSave && <> Saving...</>}
        </div>
      </div>

      <div className="grid-row margin-y-1">
        <div className="tablet:grid-col-2"></div>
        <div className="tablet:grid-col-8">
          <a style={{ cursor: "pointer" }} onClick={() => setShowBadgePreview(!showBadgePreview)}>Toggle Badge Preview</a>
          {showBadgePreview && pk &&
            <div><Badge pk={pk} organization={organization} badgeNickname={badgeNickname} preview /></div>
          }
        </div>
      </div>
    </>

  )
}
