import React from 'react'
import ScrollToTop from '../public/ScrollToTop';
import Header from '../public/Header';
import Footer from '../public/Footer';

import { Authenticator } from '@aws-amplify/ui-react'

import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";

const hostname = window && window.location && window.location.hostname

const env = (hostname === 'www.atalink.org' ||
  hostname === 'atalink.org') ?
  'prod' :
  'non-prod';

export default function Signup() {

  const formFields = {
    signUp: {
      given_name: {
        label: 'First Name',
        placeholder: 'Enter Your First Name',
        isRequired: true
      },
      family_name: {
        label: 'Last Name',
        placeholder: 'Enter Your Last Name',
        isRequired: true
      }

    }
  }

  const memberId = "m." + uuidv4()

  return (
    <>
      <ScrollToTop />
      <Header
        authState={null}
        signOut={null}
        env={env}
      />
      <Switch>
        <Route key={200} exact path="/signup" >
          <section className="usa-section" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div className="grid-container margin-y-0">
              <h2 className="margin-y-0" style={{ paddingBottom: 10 }}>Become a Member of A/TA</h2>
              <div className="grid-row">
                <div className="tablet:grid-col-5" >
                  <div className="usa-prose">
                    Please provide your information to create an account.<br />
                    If are or have been a member in the past, go to your <a href="/account">account</a> page.
                  </div>
                </div>

                <div className="tablet:grid-col-1" >
                </div>
                <div className="tablet:grid-col-6" >
                  <Authenticator
                    loginMechanisms={['email']}
                    initialState="signUp"
                    signUpAttributes={['given_name', 'family_name']}
                    formFields={formFields}
                  >
                    {({ user }) => (
                      <Redirect to={"/account/membership?cancel=/account&member=" + memberId + "&firstName=" + user.attributes.given_name + "&lastName=" + user.attributes.family_name + "&quickForm=1&cancel=/account"} />
                    )}
                  </Authenticator>
                </div>
              </div>
            </div>
          </section>
        </Route >
      </Switch >
      <Footer />
    </>
  )
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => // eslint-disable-next-line
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
