import React, { Component } from 'react';
import { Link } from "react-router-dom";

//const ReactMarkdown = require('react-markdown')

// <ReactMarkdown escapeHtml={false}>{body}</ReactMarkdown>
class GeneralAnnouncement extends Component {

  render() {

    if (!this.props.content) { return null }

    const { actionLabel, body, headline, link } = this.props.content
    if (!headline) { return null }

    const format = this.props.dark ? "usa-section usa-section--dark" : "usa-section"

    return (

      <section id="test-section-id margin-y-0" style={{ paddingTop: 8, paddingBottom: 16 }} className={format}>
        <div className="grid-container">
          <h2>{headline}</h2>

          {body}

          {actionLabel &&
            <Link
              to={link.fields.path}
              className="usa-button"
              style={{ textDecoration: 'none' }}>
              {actionLabel}
            </Link>}
        </div>
      </section>
    )
  }
}

export default GeneralAnnouncement
