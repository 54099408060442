import React, { useState } from 'react'
import {
  useParams,
  Link
} from "react-router-dom"


import useList from '../admin/useList'
//import useQuery from '../admin/useQuery'
//import useCollection from '../admin/useCollection'

// var moment = require('moment')
import getUuid from 'uuid-by-string'
//import Collection from '../admin/Collection'


export default function RegistrationConfirmation() {


  //  const registrationPk = fromString(id.slice(9, 25))
  //  const [registrationForm, setRegistrationForm] = useState(false)
  // const registrationStatus = useRegistrationStatus({ source: '/registrationStatus' })

  // const query = useQuery()

  //const formProps = { stopAfterPage: 999, pageSize: 1000, source: "/list/quickform", items: [] }
  // const form = useList(formProps)
  // const item = useCollection({ itemType: itemType, id: id ?? 'new' })

  const [registrationPk] = useState("r." + getUuid(useParams().id + 0))

  const [registrationForm, setRegistrationForm] = useState(false)
  //const registrationStatus = useRegistrationStatus({ source: '/registrationStatus' })

  const formProps = { stopAfterPage: 999, pageSize: 1000, source: "/list/quickform", items: [] }
  const form = useList(formProps)
  //const item = useCollection({ itemType: 'registration', id: registrationPk })
  if (form.items.length > 0 && (!registrationForm || (registrationForm && registrationForm.pkType !== 'registration'))) {
    setRegistrationForm(form.items.filter(i => i.pkType === 'registration')[0])
  }


  return (
    <>
      <div className="grid-container margin-y-0">
        <h2>Congratulations, You Are Going to the Convention</h2>
        <div className="usa-alert usa-alert--warning" style={{ marginTop: 40, marginBottom: 100 }}>
          <div className="usa-alert__body">
            <h4 className="usa-alert__heading">You Are Not Done Yet</h4>

            <p className="usa-alert__text" style={{ paddingBottom: 10 }}>
              You still need to confirm a few things such as your <strong>badge</strong> and <strong>banquet meal preferences</strong>.
            </p>
            <p className="usa-alert__text" style={{ paddingBottom: 10 }}>
              We have discounted <strong>hotel</strong> rooms. You need to book a room!
            </p>
            <p className="usa-alert__text" style={{ paddingBottom: 20 }}>
              If you purchased a membership with your registration we need a little more information.
            </p>

            <Link className="usa-tooltip usa-button" to={"/individual/registration/" + registrationPk}>Continue</Link>

          </div>
        </div>
      </div>



    </>
  )
}

// <div className="grid-container margin-y-0">
//   <Collection
//     collection={item.collection}
//     saveSuccessful={item.saveSuccessful}
//     status={item.status}
//     previousStatus={item.previousStatus}
//     setStatus={item.setStatus}
//     form={{ items: [registrationForm] }}
//     introductionText={introductionText}

//   />
// </div>
