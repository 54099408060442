import React from 'react'

import Column from './fields/Column'
import Table from './Table'
import TableBody from './TableBody'
import TableHeadings from './TableHeadings'

export default function GridData(props) {

  const { columns, list, sortBy, sortByAscending, handleHeaderClick, toggleFavorite, trackRecent } = props

  return (
    <Table>
      <TableHeadings>
        <tr>
          {columns.map((column, j) =>
            <Header
              key={j}
              column={column}
              sortBy={sortBy}
              sortByDefaultColumn={props.sortByDefaultColumn}
              sortByAscending={sortByAscending}
              handleHeaderClick={handleHeaderClick}
            />
          )}
        </tr>
      </TableHeadings>
      <TableBody>
        {list.totalItems === 0 && list.isLoading &&
          <tr ><td style={{ borderWidth: 0 }}><i style={{ marginTop: 40, marginLeft: 40, textDecoration: 'none' }} className="fas fa-2x fa-circle-notch fa-spin" ></i></td></tr>
        }

        {list.items.map(item =>
          <tr key={item.pk + item.sk} onClick={() => toggleFavorite({ pk: item.pk, sk: item.sk })}>
            {columns.map((column, i) =>
              <Column
                key={i}
                item={item}
                column={column}
                toggleFavorite={toggleFavorite}
                trackRecent={trackRecent}
                toggleAdminVip={list.toggleAdminVip}
                setAdminRequestedTable={list.setAdminRequestedTable}
                setAdminValue={list.setAdminValue}
                setAssignedGroup={list.setAssignedGroup}
              />
            )}
          </tr>
        )}
      </TableBody>
    </Table>
  )
}

function Header(props) {
  const { column, sortBy, sortByAscending, handleHeaderClick } = props
  // sortByDefaultColumn
  let label = column.label
  let arrow
  if (column.key === sortBy[0]) {
    if (sortByAscending === 1) {
      arrow = <i className="fas fa-long-arrow-alt-down"></i>
    } else {
      arrow = <i className="fas fa-long-arrow-alt-up"></i>
    }
  }

  let style = { cursor: 'pointer', textOverflow: 'ellipsis' }
  if (column.key === 'pk') { style.width = 250 }
  if (column.key === 'favorite') { style.width = 25 }
  if (column.key === 'recent') { style.width = 25 }

  return (
    <th
      onClick={() => handleHeaderClick(column.key)}
      style={style}
      scope="col"
    >
      {label} {arrow}
    </th>
  )
}
