import React, { useState } from 'react'
import { Link, useLocation } from "react-router-dom"
// import { API } from 'aws-amplify'

export default function FieldStripeReceipt(props) {

  let [value] = useState(props.initialValue)

  const { column, status, item } = props

  const hostname = window.location.hostname

  let redirectUrl

  if (hostname === 'www.atalink.org' ||
    hostname === 'atalink.org') {
    redirectUrl = 'https://p5ui7sdvl9.execute-api.us-east-1.amazonaws.com/master'
  } else if (hostname === 'v3.atalink.org') {
    redirectUrl = 'https://qbnorup128.execute-api.us-east-1.amazonaws.com/maina'
  } else {
    redirectUrl = 'https://4htbvql798.execute-api.us-east-1.amazonaws.com/dev'
  }

  let location = useLocation()
  let section = location.pathname.split('/')[1]

  if (status === 'EDITING') {
    if (value) {
      return <Link to={value}>View Stripe Receipt</Link>
    }
    return <div>Save Receipt to before payment</div>
  }


  if (item[column.key]) {
    if (props.isColumn) {
      return <td><a target="_default" className="usa-button usa-button--unstyled" style={{ textDecoration: 'none' }} href={item[column.key]}>View Stripe Receipt</a></td>
    }
    return <a target="_default" className="usa-button usa-button--unstyled" style={{ textDecoration: 'none' }} href={item[column.key]}>View Stripe Receipt</a>

  }

  let redirect = redirectUrl + "/checkout?receipt=" + item.pk + '&section=' + section + "&registration=true"


  if (section === 'individual') {
    redirect += '&registration=' + item.registration.pk
  }

  if (props.isColumn) {
    return <td><a className="usa-button usa-button--unstyled" style={{ textDecoration: 'none', fontWeight: 'normal' }} href={redirect}>Pay Now</a> </td>
  }

  return <a className="usa-button usa-button--unstyled" style={{ textDecoration: 'none', fontWeight: 'normal' }} href={redirect}>Pay Now</a>

}
