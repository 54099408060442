import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import Select from "react-dropdown-select"
import {
  useLocation
} from "react-router-dom"
import useQuery from '../useQuery'

var moment = require('moment')

export default function FieldLookup(props) {

  let [selected, setSelected] = useState(initialSelection())
  let [isValid, setIsValid] = useState(false)
  let [items, setItems] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  const { column, status, viewStyle, helpStyle, item, isColumn, updateField, updatedAt, editStyle } = props

  const location = useLocation()
  const query = useQuery()

  function isView() {
    if ((status === 'EDITING' || status === 'SAVING') && !column.canChangeOnEdit && !item.neverSaved) {
      return true
    }
    return status === 'LOADED' || !column.userEditable || isColumn
  }

  function isHidden() {
    if (!column.visibleForRegistrationTypes && !column.hiddenForRegistrationTypes) { return false }
    // visibleForRegistrationTypes is included in the field definition
    // so always keep it hidden until the registration type is selected.
    if (!item.registrationType.pk) { return true }

    if (column.lookupParameters.itemType === 'militaryServiceRank' ||
      column.lookupParameters.itemType === 'base') {
      // Military Service Rank is only visible if the Service Status is not NONE
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
    }

    if (column.lookupParameters.itemType === 'afOrgs') {
      // afOrgs is only visible if the Service is Air Force and the majcom is AMC
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
      // If there is no rank selected, then don't display
      if (!item.militaryServiceRank.name) {
        return true
      }
      // If there is a rank and service and it is not Air Force hide
      if (item.militaryServiceRank.name && !item.militaryServiceRank.name.includes('Air Force')) {
        return true
      }
      // If there is a rank and it is airforce AND majcom is not AMC hide
      if (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force')) {
        if (item.majcom && item.majcom.pk !== "z.3cccab1b-a844-4ce2-a5e3-801129abc531") {
          return true
        }
      }
    }

    if (column.lookupParameters.itemType === 'majcom') {
      // MAJCOM is only visible if the Service is Air Force
      if (item.militaryServiceStatus.pk === "z.f05a5285-56b2-437f-9367-daf0e30d1744") {
        return true
      }
      if (!item.militaryServiceRank.name ||
        (item.militaryServiceRank.name && !item.militaryServiceRank.name.includes('Air Force'))) {
        return true
      }
    }

    if (column.hiddenForRegistrationTypes) {
      return column.hiddenForRegistrationTypes.includes(item.registrationType.pk)
    }

    return !column.visibleForRegistrationTypes.includes(item.registrationType.pk)
  }

  useEffect(() => {
    if (column.fieldType !== 'lookup') { return }
    if (isLoading || isView()) { return }

    if (items.length === 0) {
      setItems([])
    }
    if (column.lookupParameters) {
      setIsLoading(true)
      API.get('lookup', '/lookup', { queryStringParameters: column.lookupParameters })
        .then(unsortedItems => {
          let sortBy = column.lookupParameters.sortBy
          const items = unsortedItems.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) { return -1 }
            if (a[sortBy] > b[sortBy]) { return 1 }
            return 0
          })

          // Special handling for registrationTypes
          if (column.lookupParameters.itemType === 'registrationType' && column.quickForm) {
            let tempItems = items
            if (query.get("registrationTypes")) {
              tempItems = items.filter(i => query.get("registrationTypes").includes(i.pk))
            }
            if (query.get('parentRegistration')) {
              tempItems = tempItems.filter(i => i.quickForm && i.requiresRelatedRegistration)
            } else {
              tempItems = tempItems.filter(i => i.quickForm && !i.requiresRelatedRegistration)
            }

            setItems(tempItems)

          } else if (column.lookupParameters.itemType === 'militaryServiceRank') {
            let compItems = items.map(i => {
              let gradeComponents = i.grade.split('-')
              if (gradeComponents.length === 2) {
                i.comp = i.service + gradeComponents[0] + String(Number(gradeComponents[0]) + 10) + i.rank
              } else {
                i.comp = i.service + i.grade + i.rank
              }

              return i
            })
            setItems(compItems)
          } else {
            setItems(items)
          }
          setIsLoading(false)
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }, [column, status])


  function initialSelection() {
    let { column, item } = props
    if (column.fieldType !== 'lookup') { return null }
    let itemPath = column.key.split('.')
    if (itemPath.length === 1) {
      return item
    }

    let t = item[itemPath[0]].pk && item[itemPath[0]].pk.length > 0 ? [item[itemPath[0]]] : []
    return t

  }

  useEffect(() => {

    if (isView()) { return }
    const { form } = props
    if (!selected || isColumn) { return }

    let itemPath = column.key.split('.')
    if (itemPath.length === 1) {
      console.log('not supported')
    } else {

      item[itemPath[0]] = {}
      if (selected.length > 0) {
        let denormalizedData = form.denormalizedData.filter(item => item.dataType === itemPath[0])

        if (denormalizedData.length === 1) {
          for (let key of denormalizedData[0].keys) {
            item[itemPath[0]][key] = selected[0][key]
          }
        }
      }

      // Assumption here is that sk structure is affected by lookup items.
      // If there are other ways that the structure is affected, this code needs
      // to be included in that Fieldxxx component

      if (form.skStructure && selected.length > 0) {
        let sk = ''
        for (let key of form.skStructure) {
          let itemPath = key.split('.')
          if (itemPath.length === 1) {
            sk += '/' + String(item[itemPath[0]])
          } else {
            sk += '/' + String(item[itemPath[0]][itemPath[1]])
          }
        }
        item.sk = sk.slice(1) // remove the leading slash
      }

      // Specific to memberships. Calculate the membership end date.
      if (selected.length > 0 && column.itemType === 'membershipType') {
        if (item.membershipType.termMonths) {
          const startDate = item.startDate ?? moment()
          item.endDate = moment(startDate).add(item.membershipType.termMonths, 'M').format('YYYY-MM-DD')
        } else {
          item.endDate = null
        }
        if (!item.startDate) { item.startDate = moment() }
      }

    }

    updateField(column.key, isValid)
  }, [selected, isValid])

  function onChange(selection) {
    setSelected(selection)
  }

  function onOtherValueChange(event) {
    let newSelected = [Object.assign({}, selected[0])]
    newSelected[0].otherName = event.target.value
    setSelected(newSelected)
  }

  useEffect(() => {
    let standard = true
    // MAJCOM is required, which means selecting something other than N/A
    if (column.lookupParameters.itemType === 'majcom') {
      // If this is None or retired, then we are going to use the standard validation
      if (item.militaryServiceStatus.pk !== "z.f05a5285-56b2-437f-9367-daf0e30d1744" &&
        item.militaryServiceStatus.pk !== 'z.f003b767-8e20-402c-b2b1-e07cdeddc576') {

        // If the person works for Air Force then we need to use the non-standard validation
        if (item.militaryServiceRank.name && item.militaryServiceRank.name.includes('Air Force')) {
          standard = false
        }

      }
    }
    if (standard) {
      setIsValid(selected.length === 1 || isHidden() || !column.userEditable || !column.required)
    } else {
      const IsNa = (selected[0].pk === 'z.e18bc19c-bc6b-4929-a079-35ed3fbcca5e')
      setIsValid((selected.length === 1 && !IsNa) || isHidden() || !column.userEditable || !column.required)
    }

  }, [selected, updatedAt])

  if (isHidden()) { return null }

  if (isView()) {
    let itemPath = column.viewAs.split('.')
    let value = ''

    if (itemPath.length === 1) {
      value = item[column.key]
      return <td style={viewStyle}>{value}</td>
    }

    let displayValueIfBlank = column.displayValueIfBlank ?? 'Link'
    value = item[itemPath[0]][itemPath[1]] ?? displayValueIfBlank

    if (!item[itemPath[0]].pk) {
      return <td style={viewStyle}></td>
    }

    if (column.allowOtherValues && item[itemPath[0]].pk === column.otherValuePk) {
      value = item[itemPath[0]].otherName
    }

    if (column.displayValueIfBlank && value.length === 0) {
      value = column.displayValueIfBlank
    }
    return <td style={viewStyle}>
      <a href={"/" + location.pathname.split('/')[1] + "/" + column.itemType + "/" + item[itemPath[0]].pk}>{value}</a></td>
  }
  //     sortBy={column.lookupParameters.sortBy}
  let style = column.required && !isValid ? { borderColor: '#b50908', borderWidth: 4, borderStyle: 'solid' } : {}

  return (
    <div style={{ marginTop: 5 }}>
      <Select
        options={items}
        values={selected}
        searchable={true}
        clearable={true}
        labelField={column.lookupParameters.searchAttribute}
        valueField="pk"
        loading={isLoading}
        searchBy={column.lookupParameters.searchAttribute}
        onChange={onChange}
        style={style}
      />
      {column.helpText &&
        <span style={helpStyle}>
          {column.helpText}
        </span>
      }
      {column.allowOtherValues && selected.length > 0 && selected[0].pk === column.otherValuePk &&
        <div style={{ marginTop: 20 }}>
          <input
            className="usa-input"
            id={column.key}
            name="input-type-text"
            type="text"
            maxLength={column.maxLength}
            required={column.required}
            style={editStyle}
            value={selected.length > 0 ? selected[0].otherName : ""}
            onChange={onOtherValueChange}
          />
          <span style={helpStyle}>
            Specify your own value
          </span>
        </div>
      }
    </div>
  )
}
