import React from 'react'
import { Link, useLocation } from "react-router-dom"

import FieldBoolean from "./FieldBoolean"
import FieldDateTime from "./FieldDateTime"
import FieldString from "./FieldString"
import FieldComment from "./FieldComment"
import FieldCoupon from "./FieldCoupon"
import FieldEmail from "./FieldEmail"
import FieldLookup from "./FieldLookup"
import FieldLatitude from "./FieldLatitude"
import FieldLongitude from "./FieldLongitude"
import FieldRegistrationPrice from "./FieldRegistrationPrice"
import FieldRelatedRegistration from "./FieldRelatedRegistration"
import FieldStripeReceipt from "./FieldStripeReceipt"

var moment = require('moment')

export default function Field(props) {

  function handleToggleFavorite(key, event) {
    event.stopPropagation()
    props.toggleFavorite({ pk: item.pk, sk: item.sk })
  }

  const { item, column, trackRecent } = props
  let viewStyle = { fontSize: 'normal', paddingTop: 3, textOverflow: 'ellipsis', overflow: 'hidden', backgroundColor: 'inherit', borderWidth: 1, borderColor: 'white' } //column.style ?? {}
  let editStyle = { fontSize: 'normal', marginTop: -7, textOverflow: 'ellipsis', overflow: 'hidden' }
  let helpStyle = { fontSize: 'smaller', color: 'grey', fontWeight: 'light' }

  if (column.fieldType === 'email') {
    viewStyle = { fontSize: 'normal', paddingTop: 3, textOverflow: 'ellipsis', overflow: 'hidden', backgroundColor: 'inherit', borderWidth: 1, borderColor: 'white' }
  }

  let location = useLocation()
  let pathComponent = '/' + location.pathname.split('/')[1] + '/'

  let value, itemPath
  if (column.icon) {
    value = <i className={column.icon}></i>
  } else if (column.viewAs) {
    itemPath = column.viewAs.split('.')
    if (itemPath.length === 1) {
      value = item[column.viewAs]
    } else {
      try {
        value = item[itemPath[0]][itemPath[1]]
      } catch (error) {
        console.log(item, itemPath, error)
      }
    }
  } else if (column.key) {
    itemPath = column.key.split('.')
    if (itemPath.length === 1) {
      value = item[column.key]
    } else {
      // Patches the case where the denormalized value (x.y) does not exist.

      value = (item[itemPath[0]]) ? item[itemPath[0]][itemPath[1]] : null

      if (column.otherValueKey) {
        const itemOtherValuePath = column.otherValueKey.split('.')
        const otherValue = (item[itemOtherValuePath[0]]) ? item[itemOtherValuePath[0]][itemOtherValuePath[1]] : null
        if (otherValue) {
          value = otherValue
        }
      }

    }
  } else {
    value = ''
  }

  if (column.linkTo && column.linkToKey) {
    let components = column.linkToKey.split('/')
    let path = pathComponent + column.linkTo

    let isNull = false
    for (let i in components) {
      let element = components[i].split('.')
      if (element.length === 1) {
        path += "/" + item[element[0]]
        if (item[element[0]] === null) { isNull = true }
      } else {
        path += "/" + item[element[0]][element[1]]
        if (item[element[0]][element[1]] === null) { isNull = true }
      }
    }
    if (column.linkToSubKey) {
      components = column.linkToSubKey.split('/')

      for (let i in components) {
        let element = components[i].split('.')
        if (element.length === 1) {
          path += "/" + item[element[0]]
          if (item[element[0]] === null) { isNull = true }
        } else {
          path += "/" + item[element[0]][element[1]]
          if (item[element[0]][element[1]] === null) { isNull = true }
        }
      }

    }
    if (isNull || value === '' || value === null || typeof value === 'undefined') { value = column.linkTo ?? '' }
    if (column.fieldType === 'boolean') {
      return <td style={viewStyle}><Link to={path} onClick={(event) => trackRecent(item.pk, event)}>{value ? 'Yes' : 'No'}</Link></td>
    }
    if (column.fieldType !== 'relatedRegistration') {
      return <td style={viewStyle}><Link to={path} onClick={(event) => trackRecent(item.pk, event)}>{value}</Link></td>
    }

  }

  if (column.fieldType === 'id') {
    itemPath = column.key.split('.')
    if (itemPath.length === 1) {
      value = item[itemPath[0]]
    } else {
      value = item[itemPath[0]][itemPath[1]]
    }
    if (!value) { return <td style={viewStyle}></td> }

    let path = pathComponent + column.itemType + "/" + value
    return <td style={viewStyle}><Link to={path}>{column.viewAs}</Link></td>
  }


  if (column.fieldType === 'currency') {
    return <td style={viewStyle}>${value}</td>
  }

  if (column.fieldType === 'dateTime' && !column.userEditable) {
    if (value && moment(value).isValid()) {
      return <td style={viewStyle}>{moment(value).format(column.dateTimeFormat)}</td>
    }
    return <td style={viewStyle}>{value}</td>
  }

  if (column.fieldType === 'externalLink') {
    return <td style={viewStyle}><a href={value}>{column.displayValue}</a></td>
  }

  if (column.key === 'favorite') {
    let icon = item.favorite ? <i style={{ marginLeft: -10 }} className="fas fa-star"></i> : <i style={{ marginLeft: -10 }} className="far fa-star"></i>
    return <td style={{ borderColor: 'white' }} onClick={(event) => handleToggleFavorite({ pk: item.pk, sk: item.sk }, event)}>{icon}</td>
  }

  if (column.key === 'recent') {
    let icon = item.recent ? <i style={{ marginLeft: -10 }} className="fas fa-clock"></i> : null
    return <td style={{ borderColor: 'white' }}>{icon}</td>
  }

  return (
    <>
      {props.column.fieldType === 'string' &&
        <FieldString initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'comment' &&
        <FieldComment initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'boolean' &&
        <FieldBoolean initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'email' &&
        <FieldEmail initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'dateTime' &&
        <FieldDateTime initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle}  {...props} />
      }
      {props.column.fieldType === 'coupon' &&
        <FieldCoupon initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'latitude' &&
        <FieldLatitude initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'longitude' &&
        <FieldLongitude initialValue={value} viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'registrationPrice' &&
        <FieldRegistrationPrice viewStyle={viewStyle} registrationType={props.item.registrationType} {...props} />
      }
      {props.column.fieldType === 'stripeReceipt' &&
        <FieldStripeReceipt viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
      {props.column.fieldType === 'relatedRegistration' &&
        <FieldRelatedRegistration initialValue={value} viewStyle={viewStyle} {...props} />
      }
      {props.column.fieldType === 'lookup' &&
        <FieldLookup viewStyle={viewStyle} editStyle={editStyle} helpStyle={helpStyle} {...props} />
      }
    </>
  )

}
