import React from 'react';
import TopMenu from './TopMenu'
import { useLocation, matchPath } from "react-router-dom";

export default function Header(props) {

  let location = useLocation();
  const match = matchPath(location.pathname, {
    path: "/preview/:batchId",
    exact: true,
    strict: false
  })

  if (match) { return null }
  return (
    <header className="usa-header usa-header--extended">
      <div className="usa-navbar">
        <div className="usa-logo" id="extended-logo">
          <a href="/" title="Home" aria-label="Home">
            <img src="/images/ata-logo-top-nav.png" alt="A/TA Logo" width="50" style={{ display: 'inline-block', verticalAlign: 'text-bottom' }} />
            <em style={{ display: 'inline-block', paddingLeft: 10 }} className="usa-logo__text">
              <span className="full-heading" style={{ fontSize: '1.5rem' }}>Airlift/Tanker Association</span>
              <span className="small-heading" style={{ fontSize: '1.6rem' }}>The A/TA</span>
            </em>
          </a>

        </div>
        <button className="usa-menu-btn">Menu</button>
      </div>

      <TopMenu
        authState={props.authState}
        signOut={props.signOut}
        env={props.env}
      />

    </header>

  )
}
